import { Heading1 } from "lucide-react";

export function IBM_patent() {
  return (
    <div className="flex flex-col gap-2">
      <h1 className="font-bold text-4xl my-4">IBM's Patents</h1>
      <p>
        International Business Machine Corporation (using Trademark{" "}
        <strong>IBM</strong>) is an American multinational trademark company
        which is situated across 175 countries and has its headquarters in
        Armonk, New York.
      </p>

      <p>
        IBM is the largest industrial research organization in the world, with
        19 research facilities across a dozen countries, having held the record
        for most annual U.S. Patents generated by a business for 29 consecutive
        years from 1993 to 2021.
      </p>

      <h3 className="text-2xl font-medium">Patent history</h3>
      <p>
        In 2001, IBM became the first company to generate more than 3000 patents
        in a year.
      </p>

      <p>
        IBM broke their own record in 2008, with more than 4000 patents. As of
        2021, IBM holds the record for most U.S. patents annually for 29
        consecutive years. In 2022, IBM held a total of 150,000 patents.
      </p>

      <h3 className="text-2xl font-medium">R&D of IBM</h3>
      <p>
        In terms of investment, R&D of IBM totals approximately $7 Billion. In
        2012, the total expenditure was approximately $6.9 Billion. This
        expenditure on R&Ds results in the registration of a large number of
        patents.
      </p>

      <h3 className="text-2xl font-medium">
        Famous Inventions and Developments of IBM include
      </h3>
      <ul className="pl-8">
        <li className="list-disc">Automatic Teller Machine (ATM)</li>
        <li className="list-disc">Dynamic Random Access Memory (DRAM)</li>
        <li className="list-disc">Electronic Key Punch</li>
        <li className="list-disc">Floppy Disk</li>
        <li className="list-disc">Hard Disk Drive</li>
        <li className="list-disc">SQL</li>
        <li className="list-disc">Universal Product Code (Bar Code)</li>
      </ul>

      <p>
        IBM's patents cover a range of areas, including artificial intelligence
        (AI), cloud, quantum computing, and security.
      </p>

      <p>
        A major part of IBM research is the generation of patents. Since its
        first patent for a traffic signaling device, IBM has been one of the
        world's most prolific patent sources.
      </p>

      <p>
        As of now, IBM has a total of 122,110 patents globally, out of which
        35,080 have been granted.
      </p>

      <p>Out of these 122,110 patents, more than 59% of patents are active.</p>

      <div className="mt-4">
        <p className="text-right italic">Yadnesh. J. Nandgaonkar</p>
        <p className="text-center font-light">(Innovation & IPR, BIEC)</p>
      </div>
    </div>
  );
}

export function Apple_patent() {
  return (
    <div>
      <h1 className="font-bold text-4xl my-4">
        Apple’s Scroll and Bounce Patent
      </h1>
      <div class="section">
        <p>
          Patents are the most crucial tool for securing and protecting the soul
          idea behind the product and to provide justice to its original owner.
          They are important for not only encouraging creativity but also
          helping in driving technological advancements and economic growth.
        </p>
        <p>
          Many companies, small or large, strive to protect their research and
          technology from being copied by their competitors, even if their
          creation is small and minute because such small ideas have the power
          to shape the world.
        </p>
      </div>

      <h3 className="text-2xl font-medium">Patent Details</h3>
      <div class="section">
        <p>
          Such a patent to bring a huge change in the industry is Apple’s Scroll
          and Bounce Patent, also known as Overscroll Bounce or Rubber Branding.
        </p>
        <p>
          <strong>Patent No.:</strong> U.S. Patent No. 7,469,381
        </p>
        <p>Filed in 2007 and granted in January 2009.</p>
        <p>
          <strong>Title-</strong> List scrolling and document translation,
          scaling, and rotation on a touchscreen display
        </p>
        <p>
          This patent briefly describes the innovative methods involved in the
          overscroll bounce feature which added a lot to the Apple’s IOS
          interface.
        </p>
        <p>
          {" "}
          It also adds to the mechanics and the aesthetics of the overscroll
          bounce feature.
        </p>
        <p>
          {" "}
          The feature is an interfacial visual effect which occurs when we
          scroll to end of content area (whether in web page or in a list) and
          the content will appear to be <strong>stretched</strong> before coming
          back to its original place.
        </p>
      </div>

      <h3 className="text-2xl font-medium">Patent Disputes</h3>
      <div class="section">
        <p>
          This patent was in centre in Apple’s long standing legal battle
          against SAMSUNG.
        </p>
        <p>
          In 2011, Apple filed lawsuits in multiple jurisdiction against Samsung
          for infringing their patents, including the overscroll bounce feature.
        </p>
        <p>
          The U.S, court ruled in favour of Apple in 2012 after finding that
          Samsung had indeed infringed the feature. This lead to awarding
          substantial damages to Apple from Samsung worth of $1.049 Billion.
        </p>
        <p>
          This Scroll and Bounce feature has brought a long lasting impact on
          modern user interface design. Many developers and companies have
          incorporated similar effects, but implementing them differently to
          avoid infringement.
        </p>
        <p>
          This case is a great example, signifying small design details can
          indeed lead to big impact.
        </p>
      </div>
      <div className="text-right italic mt-4">Yadnesh. J. Nandgaonkar</div>

      <p className="text-center font-light">(Innovation & IPR, BIEC)</p>
    </div>
  );
}

export function Copyright() {
  return (
    <div>
      <h1 className="font-bold text-4xl my-4">Copyright</h1>
      <p>
        The word in itself is an explanation. Simply put it is the right to
        copy. Remember when you write your answers very well at times and no
        longer want to share it with anyone. But then you want your best friend
        to copy it. That is where you use the right of giving access to someone
        to use or copy your work. In the bigger scenario one can sue someone who
        has used one's work without prior permission from the author.
      </p>

      <h2 className="text-2xl font-medium">What work?</h2>
      <p>
        The intellectual property rights allow you to register and protect work
        under your name. Trademarks, patents and copyrights work in a similar
        way but the latter can protect only content-based work. Copyright is
        intended to protect the original expression of an idea in the form of a
        creative work, but not the idea itself. The law doesn't apply on ideas,
        logos, brand names, inventions etc.
      </p>

      <p>
        In short, any creation which has taken some significant intellectual
        ability can be copyrighted such as:
      </p>
      <ul className="pl-8">
        <li className="list-disc">Paintings</li>
        <li className="list-disc">Art works</li>
        <li className="list-disc">Music compositions</li>
        <li className="list-disc">Computer software</li>
        <li className="list-disc">Website content</li>
        <li className="list-disc">Poetry</li>
        <li className="list-disc">Novels or articles</li>
        <li className="list-disc">Designs</li>
      </ul>

      <p>
        <strong>Base line:</strong> Any of these works need to be in tangible
        form to be copyrighted.
      </p>

      <h2 className="text-2xl font-medium">
        Difference in trademark, patents and copyrights
      </h2>
      <p>
        Trademarks, as the name suggests, protect the brand names, logos, and
        symbols from getting used without permission from the owner. Patents, in
        a similar way, protect ideas, theories, inventions, discoveries, etc.
      </p>

      <p>
        Copyright law protects creators of original material from unauthorized
        duplication, distribution, or other use.
      </p>

      <p>
        All three, although used interchangeably, provide protection in distinct
        domains.
      </p>

      <h2 className="text-2xl font-medium">How do I use the law?</h2>
      <p>
        Should one be running off to file a copyright document as soon as they
        create an artwork? Absolutely not. From the moment of creation, the
        author is provided with the right itself. But in case they need to file
        an infringement case, they would need a legal document stating the work
        belongs to them.
      </p>

      <h2 className="text-2xl font-medium">For how long does it work?</h2>
      <p>
        The copyright law unfortunately does not provide protection forever.
        "Throughout the author's life and then 60 years after death" as it is
        mentioned according to the Copyright Act 1957, India. After the author's
        death, the property will be protected for 60 years if the said author
        hadn't chosen an heir for the same. This also applies to the
        intellectual properties created by companies. Although the protection
        period lasts a little longer for it.
      </p>

      <p>
        Copyright protection varies by country and can stand for 50 to 100 years
        after the individual's death.
      </p>

      <h2 className="text-2xl font-medium">How much does it cost?</h2>
      <p>
        As per the latest information, the cost to copyright creations is as
        follows:
      </p>
      <ol>
        <li>
          <strong>Literary, Dramatic, Musical, or Artistic Work:</strong> INR
          500 per work.
        </li>
        <li>
          <strong>Cinematograph Film:</strong> INR 5,000 per work.
        </li>
        <li>
          <strong>Sound Recording:</strong> INR 2,000 per work.
        </li>
      </ol>

      <p>
        This also changes from country to country, as does the process to file
        it. The process generally proceeds in three to four simple steps:
      </p>
      <ol>
        <li>
          <strong>Application Submission: </strong>The application can be filed
          online or offline in the copyright office. It must be in the
          prescribed form, which varies depending on the type of work.
        </li>
        <li>
          <strong>Documents Required:</strong> Along with the application,
          applicants must submit a copy of the work, a demand draft for the
          applicable fee, and a power of attorney if the application is filed
          through an authorized agent.
        </li>
        <li>
          <strong>Examination:</strong> After submission, the application is
          examined by the Copyright Office. This examination checks for any
          discrepancies or objections. If any objections are raised, the
          applicant is notified and given a chance to respond.
        </li>
        <li>
          <strong>Registration:</strong> Once the examination is complete and
          any objections are resolved, the work is registered, and a
          registration certificate is issued to the applicant. The details of
          the registered work are also entered into the Register of Copyrights.
        </li>
      </ol>

      <p className="text-center font-light mt-4">(Innovation & IPR, BIEC)</p>
    </div>
  );
}

export function National_Youth_fest() {
  return (
    <div>
      <h1 className="font-bold text-4xl my-4">National Youth Festival:</h1>
      <h2 className="font-semibold text-3xl">
        Connected to IPR and Viksit Bharat
      </h2>
      <p className="text-2xl font-medium">Introduction</p>
      <p>
        The National Youth Festival in India is an annual gathering of youth
        with various activities including competitive ones. Celebrated to
        commemorate the birth anniversary of youth icon Swami Vivekananda, it is
        organized by the Ministry of Youth Affairs and Sports, Government of
        India in collaboration with one of the State Governments. Thus it is
        held in a different state each year during National Youth Week, 12 to 16
        January every year. Swami Vivekananda’s birthday on January 12 is always
        celebrated as National Youth Day and the week commencing from that day
        is known as the National Youth Week. As part of National Youth Week
        celebrations, the Government of India holds the National Youth Festival
        every year. This is an event of the Ministry of Youth Affairs and
        Sports, Government of India and is celebrated annually in one of the
        States in a joint venture. The activities held during the festival
        include competitive and non-competitive cultural events, martial arts,
        exhibitions, intellectual discourses, young artists camps, seminars, and
        adventure programmes. The presentation of National Youth Awards also
        takes place.
      </p>

      <p className="text-2xl font-medium">Background</p>
      <p>
        The 26th National Youth Festival was held in Dharwad, Karnataka. Dharwad
        hosted the fest and had 8000 delegates from all over the country and
        union territories of India. The theme of the event was Viksit Yuva –
        Viksit Bharat.
      </p>

      <p className="text-2xl font-medium">The events included in 2023 were:</p>
      <ul className="pl-8">
        <li className="list-disc">
          Classical Instrumental Solos: Flute, Guitar, Sitar, Tabla, Veena
        </li>
        <li className="list-disc">
          Classical Dances: Bharat Natyam, Kathak, Manipuri, Odissi, Ojapali
        </li>
        <li className="list-disc">
          Other Events: Cello, Folk Dance, Folk Song, Hindustan Carnatic,
          Harmonium (Light), Laser show, Painting, Sculpture, Adventure Events,
          Yuva Kriti, Food Festival
        </li>
      </ul>

      <h3 className="text-2xl font-medium">Venues</h3>
      <p>Karnatak College (KCD) primary venue of NYF 2023</p>
      <p>
        Karnatak College (KCD), Karnatak University, and University of
        Agricultural Sciences were the prime venues for the event. Karnatak
        College (KCD) had the most of the events hosted with 50 food stalls and
        mega kitchen for the 8000 delegates participating from all over the
        country.
      </p>

      <p className="text-2xl font-medium">Connection with IPR</p>
      <p>
        The festival included seminars and discussions where young minds could
        interact with successful individuals from various fields, gaining
        insights into the importance of innovation and the protection of
        intellectual property. These sessions aimed to inspire and educate
        participants on the significance of safeguarding their creative works
        and the role of intellectual property rights in fostering innovation.{" "}
      </p>
      <p>
        The festival also featured ‘Suvichar’ sessions, which offered a blend of
        cultural celebration and intellectual discourse, further emphasizing the
        importance of innovation and creativity among the youth.{" "}
      </p>

      <p className="text-2xl font-medium">What is Happening Next</p>
      <p>Collaboration Partners (14) for this year</p>
      <ul className="pl-8">
        <li className="list-disc">Nehru Yuva Kendra Sangathan (NYKS)</li>
        <li className="list-disc">National Service Scheme (NSS)</li>
        <li className="list-disc">
          Youth Empowerment and Entrepreneurship Department (Haryana)
        </li>
        <li className="list-disc">
          Department of Youth Services (Andhra Pradesh)
        </li>
        <li className="list-disc">
          Rajasthan Youth Board Department of Youth Affairs and Sports
        </li>
        <li className="list-disc">
          Directorate of Sports and Youth Affairs (Goa)
        </li>
      </ul>

      <p>
        <strong>Theme 2025 </strong> “Arise, Awake, and Realize the Power You
        Hold”
      </p>

      <p>
        <strong>Activities</strong> Motivational speeches,Cultural programs,
        Educational activities, Meditation
      </p>

      <p>
        <strong>In Summary, </strong>National Youth Day 2025 is a perfect
        occasion to revisit the legacy of Swami Vivekananda and draw inspiration
        from his teachings for personal and national growth. His philosophy
        continues to guide millions of young minds toward achieving excellence
        and contributing to the betterment of society.
      </p>
    </div>
  );
}
