
export default function ComingSoon() {
  return (
    <div className="pt-36 w-screen">
        <p className="text-[3vw] tracking-tighter font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-[#dcdcdc] via-[#7d60bb] to-[#dedede]">
            Cooking Up Something Special !
        </p>
    </div>
  )
}
