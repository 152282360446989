
import React, { useState, useEffect, useCallback } from "react";

const words = [
  "Innovation",
  "Pitch",
  "Funding",
  "Equity",
  "Valuation",
  "Incubator",
  "Accelerator",
  "Mentorship",
  "Networking",
  "Scalability",
  "Prototype",
  "Revenue",
  "Profit",
  "Loss",
  "Market",
  "Branding",
  "Strategy",
  "Disruption",
  "Pivot",
  "Bootstrap",
  "Crowdfunding",
  "Venture",
  "Angel",
  "Investor",
  "Stakeholder",
  "Partnership",
  "Collaboration",
  "Synergy",
  "Ecosystem",
  "Pitchfest",
  "Incubation",
  "Margin",
  "EBITDA",
  "ROI",
  "Liquidity",
  "Franchise",
  "Royalty",
  "Burnout",
  "Sustainability",
  "Patent",
  "Compliance",
  "Benchmark",
  "Burn Rate",
];

const meanings = [
  "The process of creating new ideas, products, or methods.",
  "A presentation of a business idea to potential investors.",
  "Financial support for a business venture.",
  "Ownership interest in a company.",
  "The estimated worth of a business.",
  "A program that supports startups with resources and mentorship.",
  "A program that helps startups grow rapidly.",
  "Guidance provided by experienced professionals.",
  "Building relationships with other professionals.",
  "The ability of a business to grow and manage increased demand.",
  "An early sample or model of a product.",
  "Income generated from business activities.",
  "Financial gain after all expenses are deducted.",
  "When expenses exceed revenue.",
  "The group of consumers interested in a product or service.",
  "Creating a unique image and identity for a product or company.",
  "A plan of action to achieve business goals.",
  "Innovation that significantly alters an industry.",
  "A significant change in business strategy.",
  "A business started with minimal financial resources and funding.",
  "Raising small amounts of money from a large number of people.",
  "A new business enterprise.",
  "An individual who provides capital for startups.",
  "A person or entity that provides financial support for a business.",
  "Anyone with an interest in a business.",
  "A business arrangement between two or more parties.",
  "Working together to achieve a common goal.",
  "The combined effect greater than the sum of individual efforts.",
  "The network of organizations involved in a business environment.",
  "An event where entrepreneurs present their business ideas.",
  "The process of nurturing startups in their early stages.",
  "The difference between the cost of a product and its selling price.",
  "A measure of a companys overall financial performance.",
  "Return on Investment, a measure of the profitability of an investment.",
  "The ability to quickly convert assets into cash.",
  "A business model where a company allows others to operate using its brand.",
  "A payment made to a patent or copyright holder.",
  "Physical or mental collapse caused by overwork or stress.",
  "The ability to be maintained at a certain rate or level.",
  "A government authority or license conferring a right for a set period.",
  "Conforming to a rule or standard.",
  "A standard or point of reference.",
  "The rate at which a company spends its capital.",
];

// We need to keep some custom styles for the grid
const customStyles = {
  grid: {
    display: "grid",
    gap: "4px",
    gridTemplateColumns: `repeat(16, minmax(0, 1fr))`,
  },
  cell: {
    aspectRatio: "1 / 1",
    transition: "background-color 0.2s",
  },
};

export default function WordMatrixPuzzle() {
  const [grid, setGrid] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [wordMeaning, setWordMeaning] = useState("");
  const [showMeaningModal, setShowMeaningModal] = useState(false);
  const [foundWords, setFoundWords] = useState([]);
  const [showHiddenWords, setShowHiddenWords] = useState(false);
  const [points, setPoints] = useState(0);
  const [wordsInGrid, setWordsInGrid] = useState([]);

  const size = 16;

  if (setShowHiddenWords || points) {
    console.clear();
  }

  // ... [Previous hooks and helper functions remain the same] ...

  const placeWordsInGrid = useCallback((grid) => {
    const directions = [
      { row: 0, col: 1 },
      { row: 1, col: 0 },
      { row: 1, col: 1 },
      { row: -1, col: 1 },
    ];

    words.forEach((word) => {
      let placed = false;
      let attempts = 0;

      while (!placed && attempts < 20) {
        const direction =
          directions[Math.floor(Math.random() * directions.length)];
        const startRow = Math.floor(Math.random() * size);
        const startCol = Math.floor(Math.random() * size);

        if (canPlaceWord(startRow, startCol, word, grid, direction)) {
          for (let i = 0; i < word.length; i++) {
            const row = startRow + i * direction.row;
            const col = startCol + i * direction.col;
            grid[row][col] = word[i];
          }
          placed = true;
          setWordsInGrid((prev) => [...prev, word]);
        }
        attempts++;
      }
    });
  }, []);

  const canPlaceWord = (startRow, startCol, word, grid, direction) => {
    for (let i = 0; i < word.length; i++) {
      const row = startRow + i * direction.row;
      const col = startCol + i * direction.col;

      if (row >= size || row < 0 || col >= size || col < 0 || grid[row][col]) {
        return false;
      }
    }
    return true;
  };

  const fillEmptyCells = useCallback((grid) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let row = 0; row < size; row++) {
      for (let col = 0; col < size; col++) {
        if (!grid[row][col]) {
          grid[row][col] = alphabet[Math.floor(Math.random() * 26)];
        }
      }
    }
  }, []);

  const handleMouseDown = (row, col) => {
    setIsDragging(true);
    selectCell(row, col);
  };

  const handleMouseOver = (row, col) => {
    if (isDragging) selectCell(row, col);
  };

  const handleMouseUp = () => {
    if (selectedCells.length >= 2) {
      checkWord();
    }
    setIsDragging(false);
  };

  const selectCell = (row, col) => {
    setSelectedCells((prev) =>
      prev.some((cell) => cell.row === row && cell.col === col)
        ? prev
        : [...prev, { row, col }]
    );
  };

  const checkWord = () => {
    const selectedWord = selectedCells
      .map((cell) => grid[cell.row][cell.col])
      .join("");
    const wordIndex = words.findIndex(
      (word) => word.toUpperCase() === selectedWord.toUpperCase()
    );

    if (wordIndex !== -1) {
      const foundCellCoordinates = selectedCells.map(
        (cell) => `${cell.row}-${cell.col}`
      );
      setFoundWords((prevFoundWords) => [
        ...prevFoundWords,
        ...foundCellCoordinates,
        words[wordIndex],
      ]);
      setWordMeaning(meanings[wordIndex]);
      setShowMeaningModal(true);
      setPoints((prevPoints) => prevPoints + selectedWord.length);
    }

    setSelectedCells([]);
  };

  useEffect(() => {
    const initializeGrid = () =>
      Array.from({ length: size }, () => Array(size).fill(""));
    const gridWithWords = initializeGrid();
    placeWordsInGrid(gridWithWords);
    fillEmptyCells(gridWithWords);
    setGrid(gridWithWords);
  }, [fillEmptyCells, placeWordsInGrid]);

  return (
    <div className="flex flex-col items-center min-h-screen p-2 w-screen box-border mt-12">
      <div className="flex flex-col items-center py-1 overflow-x-auto w-screen px-1">
        <div style={customStyles.grid}>
          {grid.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
              const isFound = foundWords.includes(`${rowIndex}-${colIndex}`);
              const isSelected = selectedCells.some(
                (c) => c.row === rowIndex && c.col === colIndex
              );
              const isHidden =
                showHiddenWords && words.some((word) => word.includes(cell));

              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`
                    flex items-center justify-center  font-alumni p-[1px] font-medium aspect-square text-white cursor-pointer rounded-full select-none text-base uppercase
                    ${
                      isFound
                        ? "ring-violet-600 ring-[1px] bg-[#1f163f]"
                        : isSelected
                        ? "bg-violet-600"
                        : isHidden
                        ? "bg-violet-800"
                        : "bg-[#160e30]"
                    }
                    md:text-lg lg:text-lg
                  `}
                  style={customStyles.cell}
                  onDragOver={(e) => handleMouseOver(rowIndex, colIndex)}
                  onTouchMove={(e) => handleMouseOver(rowIndex, colIndex)}
                  onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                  onMouseOver={(e) => handleMouseOver(rowIndex, colIndex)}
                  onMouseUp={handleMouseUp}
                >
                  {cell}
                </div>
              );
            })
          )}
        </div>

        {showMeaningModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-violet-800 bg-opacity-90 p-6 rounded-lg max-w-xl w-11/12 text-center">
              <h2 className="text-xl md:text-2xl lg:text-2xl text-white mb-4">
                (Guide)
              </h2>
              <p className="text-base md:text-lg lg:text-xl text-white mb-6">
                {wordMeaning}
              </p>
              <button
                className="bg-[#100a23] text-white px-6 py-2 rounded hover:bg-gray-800 transition-colors"
                onClick={() => setShowMeaningModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-wrap justify-center mt-6 w-full md:w-4/5 lg:w-3/5">
        {wordsInGrid.map((word) => (
          <span
            key={word}
            className={`
              m-1 px-3 py-1 bg-[#110b24] ring-psfviolet ring-[1px] ring-opacity-50 text-white rounded
              ${
                foundWords.includes(word)
                  ? "line-through text-gray-700 bg-[#271b50] ring-[#100a23]"
                  : "text-black"
              }
              text-sm md:text-base lg:text-lg
            `}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
}
