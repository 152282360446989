import React, { useEffect, useState } from "react";
import { getFirestore, collection, query, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { MessageSquare, Loader2, ClipboardList } from "lucide-react";

const QueriesList = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState({});
  const db = getFirestore();

  useEffect(() => {
    const fetchQueries = () => {
      const q = query(collection(db, "queries"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const queriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQueries(queriesData);
        setLoading(false);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchQueries();
    const interval = setInterval(fetchQueries, 60000);

    return () => {
      clearInterval(interval);
      unsubscribe();
    };
  }, [db]);

  const updateQueryStatus = async (queryId, newStatus) => {
    setUpdateLoading(prev => ({ ...prev, [queryId]: true }));
    try {
      const queryDoc = doc(db, "queries", queryId);
      await updateDoc(queryDoc, { status: newStatus });
    } catch (error) {
      console.error("Error updating query status: ", error);
    } finally {
      setUpdateLoading(prev => ({ ...prev, [queryId]: false }));
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
          <p className="text-gray-600 font-medium">Loading queries...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center pt-32 px-6 pb-12">
      <div className="flex items-center gap-4 mb-12">
        <ClipboardList className="w-10 h-10 text-cyan-800" />
        <h1 className="text-4xl font-bold text-cyan-800">Manage Queries</h1>
      </div>

      <div className="w-full max-w-4xl">
        {queries.length === 0 ? (
          <div className="bg-white rounded-lg shadow-lg p-12 text-center">
            <MessageSquare className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h2 className="text-xl font-medium text-gray-700 mb-2">No Queries Found</h2>
            <p className="text-gray-500">New queries will appear here when they are submitted.</p>
          </div>
        ) : (
          <div className="space-y-6">
            {queries.map((query) => (
              <div
                key={query.id}
                className="bg-white rounded-lg shadow-lg p-6 transition-all hover:shadow-xl"
              >
                <div className="flex justify-between items-start gap-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-800">
                      Stall #{query.stallNumber}
                    </h3>
                    <p className="text-lg text-gray-600 mt-1">{query.startupName}</p>
                  </div>
                  <span
                    className={`px-4 py-1.5 rounded-full text-sm font-medium ${
                      query.status === "attended"
                        ? "bg-green-100 text-green-800"
                        : "bg-yellow-100 text-yellow-800"
                    }`}
                  >
                    {query.status.charAt(0).toUpperCase() + query.status.slice(1)}
                  </span>
                </div>

                <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                  <p className="text-gray-700 whitespace-pre-wrap">{query.query}</p>
                </div>

                <div className="mt-4 flex flex-wrap items-center gap-4 text-sm text-gray-600">
                  <p>
                    <span className="font-semibold">Contact:</span>{" "}
                    {query.contactNumber}
                  </p>
                  <span>•</span>
                  <p>
                    <span className="font-semibold">Submitted:</span>{" "}
                    {new Date(query.timestamp).toLocaleString()}
                  </p>
                </div>

                <div className="flex items-center gap-4 mt-6">
                  <button
                    onClick={() => updateQueryStatus(query.id, "attended")}
                    disabled={updateLoading[query.id]}
                    className={`flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed ${
                      query.status === "attended" ? "opacity-50" : ""
                    }`}
                  >
                    {updateLoading[query.id] ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : null}
                    Mark as Attended
                  </button>
                  <button
                    onClick={() => updateQueryStatus(query.id, "pending")}
                    disabled={updateLoading[query.id]}
                    className={`flex items-center gap-2 bg-yellow-600 hover:bg-yellow-700 text-white font-medium py-2 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed ${
                      query.status === "pending" ? "opacity-50" : ""
                    }`}
                  >
                    {updateLoading[query.id] ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : null}
                    Mark as Pending
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QueriesList;