import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";

export default function Socials() {
  return (
    <div className="items-center flex flex-col">
      <div className="flex flex-row w-full gap-2 md:gap-4 justify-center">
        <button className="w-16 h-16 bg-gradient-to-t hover:from-[#6d41d0] to-[#1650bd] rounded-xl">
          <a href="https://www.instagram.com/coep_ecell/">
            <InstagramIcon fontSize="large" />
          </a>
        </button>

        <button className="w-16 h-16 bg-gradient-to-t hover:from-[#6d41d0] to-[#1650bd] rounded-xl">
          <a href="https://www.linkedin.com/company/pune-startup-fest/posts/?feedView=all">
            <LinkedInIcon fontSize="large" />
          </a>
        </button>

        <button className="w-16 h-16 bg-gradient-to-t hover:from-[#6d41d0] to-[#1650bd] rounded-xl">
          <a href="https://www.youtube.com/c/BhausInnovationEntrepreneurshipCellCOEP2022">
            <YouTubeIcon fontSize="large" />
          </a>
        </button>

        <button className="w-16 h-16 bg-gradient-to-t hover:from-[#6d41d0] to-[#1650bd] rounded-xl">
          <a href="https://www.facebook.com/biec.coep/">
            <FacebookIcon fontSize="large" />
          </a>
        </button>

        <button className="w-16 h-16 bg-gradient-to-t hover:from-[#6d41d0] to-[#1650bd] rounded-xl">
          <a href="https://x.com/i/flow/login?redirect_after_login=%2FBIEC_COEP">
            <XIcon fontSize="large" />
          </a>
        </button>
      </div>
    </div>
  );
}
