export default function SpeakerCard({ speakerimage, name, profession }) {
  return (
    <div className="w-32 md:w-40 lg:w-60 bg-psfwhite md:px-6 flex flex-col justify-start items-center m-2 lg:m-4 p-2 hover:ring-2 ring-psfviolet round-3xl">
      <span className="p-2 ring-opacity-75 rounded-xl">
        <img src={speakerimage} alt={name} className="rounded-full" />
      </span>
      <div>
        <p className="text-base md:text-lg font-psfserif font-semibold text-psfblack text-center text-wrap">
          {name}
        </p>
        <p className="text-xs lg:text-sm font-psfsans font-light text-psfblack text-center">
          {profession}
        </p>
      </div>
    </div>
  );
}
