export default function TestimonialsCard({ stmt, author, startup }) {
  return (
    <div className="font-psfsans min-w-96 min-h-48 lg:min-h-60 m-2 rounded-3xl p-2 bg-psfviolet bg-opacity-5 text-white ring-[#312064] ring-[1px] ring-opacity-50 hover:ring-opacity-100">
      <div className="flex flex-col gap-2 w-full p-2 justify-between min-h-60">
        <div className="flex flex-col gap-2">
          <p className="font-medium text-left text-xl md:text-2xl lg:text-3xl  text-wrap font-alumni bg-clip-text text-transparent bg-gradient-to-r from-[#c9bbf6]  to-[#b4adca]">
            {startup}
          </p>
          <p className="w-full font-normal text-wrap text-left text-sm md:text-base lg:text-lg text-white text-opacity-75">
            {stmt}
          </p>
        </div>
        <p className="w-full text-right text-sm md:text-base text-normal">
          ~{author}
        </p>
      </div>
    </div>
  );
}
