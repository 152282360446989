import main_sponsors_bg from "../../assets/sponsors_bg/main_sponsors.png.webp";
import sponsors_media_bg from "../../assets/sponsors_bg/media_sponsors.png.webp";
import past_sponsors_bg from "../../assets/sponsors_bg/past_sponsors.png.webp";

import Partners from "./Partners";
import {
  TitleSponsor,
  ExecutiveSponsor,
  MediaSponsors,
  PastSponsor,
} from "./Partners";

import amul from "../../assets/sponsors_2k24/amul.png.webp";
import aquarise from "../../assets/sponsors_2k24/aquarise.png.webp";
import budhanibros from "../../assets/sponsors_2k24/budhanibros.png.webp";
import dominospizza from "../../assets/sponsors_2k24/dominospizza.png.webp";
import easemytrip from "../../assets/sponsors_2k24/easemytrip.png.webp";
import finiq from "../../assets/sponsors_2k24/finiq.png.webp";
import indianoil from "../../assets/sponsors_2k24/indianoil.png.webp";
import lordofgaming from "../../assets/sponsors_2k24/lordofgaming.png.webp";
import navami from "../../assets/sponsors_2k24/navami.png.webp";
import palmonas from "../../assets/sponsors_2k24/palmonas.png.webp";
import pesb from "../../assets/sponsors_2k24/pesb.png.webp";
import phicommerce_main from "../../assets/sponsors_2k24/phicommerce.png.webp";
import redfm from "../../assets/sponsors_2k24/redfm.png.webp";
import s_main from "../../assets/sponsors_2k24/s.png.webp";
import tiepune from "../../assets/sponsors_2k24/tiepune.png.webp";
import tooyum from "../../assets/sponsors_2k24/tooyum.png.webp";
import wetnjoy from "../../assets/sponsors_2k24/wetnjoy.png.webp";

import punyanagari from "../../assets/sponsors_media/punya_nagari.jpg";
import kesari from "../../assets/sponsors_media/kesari.jpg";
import campus_times from "../../assets/sponsors_media/campus_times.jpg";
import maharashtra_times from "../../assets/sponsors_media/maharashtra_times.jpg";
import sakal from "../../assets/sponsors_media/sakal.jpg";
import yourstory from "../../assets/sponsors_media/yourstory.jpg";

import accessmba from "../../assets/sponsors_past/accessmba.png.webp";
import anand from "../../assets/sponsors_past/anand.png.webp";
import awsactivate from "../../assets/sponsors_past/awsactivate.png.webp";
import bigfm from "../../assets/sponsors_past/bigfm.png.webp";
import d2c from "../../assets/sponsors_past/d2c.png.webp";
import finlatics from "../../assets/sponsors_past/finlatics.png.webp";
import funkyturtle from "../../assets/sponsors_past/funkyturtle.png.webp";
import grabon from "../../assets/sponsors_past/grabon.png.webp";
import greythr from "../../assets/sponsors_past/greythr.png.webp";
import growthschool from "../../assets/sponsors_past/growthschool.png.webp";
import hindustantimes from "../../assets/sponsors_past/hindustantimes.png.webp";
import internin from "../../assets/sponsors_past/internin.png.webp";
import iyedo from "../../assets/sponsors_past/iyedo.png.webp";
import kalyanibharatforge from "../../assets/sponsors_past/kalyanibharatforge.png.webp";
import mahastateinnosociety from "../../assets/sponsors_past/mahastateinnosociety.png.webp";
import marshmclennan from "../../assets/sponsors_past/marshmclennan.png.webp";
import oyoworkspaces from "../../assets/sponsors_past/oyoworkspaces.png.webp";
import payphi from "../../assets/sponsors_past/payphi.png.webp";
import phicommerce from "../../assets/sponsors_past/phicommerce.png.webp";
import pma from "../../assets/sponsors_past/pma.png.webp";
import polycab from "../../assets/sponsors_past/polycab.png.webp";
import quickheal from "../../assets/sponsors_past/quickheal.png.webp";
import riidl from "../../assets/sponsors_past/riidl.png.webp";
import s from "../../assets/sponsors_past/s.png.webp";
import sbi from "../../assets/sponsors_past/sbi.png.webp";
import shoptimize from "../../assets/sponsors_past/shoptimize.png.webp";
import startups1000 from "../../assets/sponsors_past/startups1000.png.webp";
import tickertape from "../../assets/sponsors_past/tickertape.png.webp";
import venturewolf from "../../assets/sponsors_past/venturewolf.png.webp";
import wework from "../../assets/sponsors_past/wework.png.webp";
import yesstartups from "../../assets/sponsors_past/yesstartups.png.webp";
import ykt from "../../assets/sponsors_past/ykt.png.webp";

import zomato from "../../assets/sponsors_2k25/zomato.png.webp";
import wtcpune from "../../assets/sponsors_2k25/wtcpune.png.webp";
import tie from "../../assets/sponsors_2k25/tie.png.webp";
import technodry from "../../assets/sponsors_2k25/technodry.png.webp";
import ssengg from "../../assets/sponsors_2k25/ssengg.png";
import pwsos from "../../assets/sponsors_2k25/pwsos.png.webp";
import ongc from "../../assets/sponsors_2k25/ongc.png.webp";
import nibe from "../../assets/sponsors_2k25/nibe.png.webp";
import maxprotein from "../../assets/sponsors_2k25/max_protein.png.webp";
import mass from "../../assets/sponsors_2k25/mass.png.webp";
import inkidee from "../../assets/sponsors_2k25/inkidee.png.webp";
import igus from "../../assets/sponsors_2k25/igus.png.webp";
import idfc from "../../assets/sponsors_2k25/idfc.png.webp";
import foundersnexus from "../../assets/sponsors_2k25/foundersnexus.png.webp";
import fitekya from "../../assets/sponsors_2k25/fitekya.png.webp";
import firstrate from "../../assets/sponsors_2k25/first_rate.png.webp";
import es from "../../assets/sponsors_2k25/es.png.webp";
import ekatmerp from "../../assets/sponsors_2k25/ekatmerp.png.webp";
import dhananjay from "../../assets/sponsors_2k25/dhananjay.png.webp";
import chitale from "../../assets/sponsors_2k25/chitale.png.webp";
import bxi from "../../assets/sponsors_2k25/bxi.png.webp";
import bakelite from "../../assets/sponsors_2k25/bakelite.png.webp";
import amultasteofindia from "../../assets/sponsors_2k25/amul.png.webp";
import aregifted from "../../assets/sponsors_2k25/areugifted.png.webp";
import frameboxx from "../../assets/sponsors_2k25/frameboxx.png.webp";
import mitid from "../../assets/sponsors_2k25/mitid.png.webp";
import climeto from "../../assets/sponsors_2k25/climeto.png.webp";

// import yourstory from "../../assets/sponsors_2k25/yourstory.png.webp"
function Sponsors() {
  return (
    <div>
      <div className="min-h-screen flex flex-col relative items-center justify-center md:gap-12">
        <div className="mt-40"></div>

        <div className="text-4xl md:text-5xl lg:text-7xl uppercase text-center font-alumni font-medium -mt-12 mb-8">
          OUR PROUD SPONSORS
        </div>

        {/* divs for aesthetics purposes */}
        <div
          className="absolute w-full h-full bg-no-repeat bg-center bg-cover -z-20"
          style={{ backgroundImage: `url(${main_sponsors_bg})` }}
        ></div>

        {/* second overlay of gradient to blend in */}
        <div
          className="absolute w-full h-full -z-10 "
          style={{
            background:
              "linear-gradient(180deg, rgba(8,4,17,1) 0%, rgba(8,4,17,0.2) 15%, rgba(8,4,17,0.2) 85%, rgba(8,4,17,1) 100%)",
          }}
        ></div>

        <div className="flex flex-row flex-wrap justify-center lg:w-4/5 gap-12 mb-4 px-4">
          <TitleSponsor image={ongc} partnership={"Title Sponsor"} />
        </div>

        <div className="flex flex-row flex-wrap justify-center lg:w-4/5 gap-12 mb-4 px-4">
          <ExecutiveSponsor
            image={ekatmerp}
            partnership={"Lightning Pitch Sponsor"}
          />
          <ExecutiveSponsor image={inkidee} partnership={"Innovation & IPR Partner"} />
        </div>

        <div className="flex flex-row flex-wrap justify-center w-full lg:w-3/5 gap-16 mb-8 px-4">
          <Partners image={nibe} partnership={"Defence Partner"} />
          <Partners image={technodry} partnership={"Drying & Evaporation Partner"} />
          <Partners image={ssengg} partnership={"Networking Arena Partner"} />
          <Partners image={es} partnership={"Platinum Partner"} />
          <Partners image={mass} partnership={"Healthcare Partner"} />
          <Partners image={igus} partnership={"Sustainability Partner"} />
          <Partners image={chitale} partnership={"Gifting Partner"} />
          <Partners image={fitekya} partnership={"Fitness Partner"} />
          <Partners image={idfc} partnership={"Banking Partner"} />
          <Partners image={maxprotein} partnership={"Energy Partner"} />
          <Partners image={bakelite} partnership={"Refreshment Partner"} />
          <Partners image={finlatics} partnership={"Education Partner"} />
          <Partners image={pwsos} partnership={"Empowerment Partner"} />
          <Partners image={dhananjay} partnership={"QuickBite Partner"} />
          <Partners image={budhanibros} partnership={"Wafer Partner"} />
          <Partners image={amultasteofindia} partnership={"Food Partner"} />
          <Partners image={tooyum} partnership={"Snacking Partner"} />
          <Partners image={wetnjoy} partnership={"Entertainment Partner"} />
          <Partners image={zomato} partnership={"Delivery Partner"} />
          <Partners image={bxi} partnership={"Barter Partner"} />
          <Partners image={tie} partnership={"Investment Partner"} />
          <Partners image={aregifted} partnership={"Renewability Partner"} />
          <Partners image={frameboxx} partnership={" Optify Sponsor"} />
          <Partners image={mitid} partnership={"Spectra Sponsor"} />
          <Partners image={climeto} partnership={"Green Partner"} />
        </div>
      </div>

      {/* media sponsors */}
      <div className="min-h-screen flex flex-col items-center justify-center">
        {/* divs for aesthetics purposes */}
        <div
          className="absolute w-full h-full bg-no-repeat bg-center bg-cover -z-20"
          style={{ backgroundImage: `url(${sponsors_media_bg})` }}
        ></div>

        <div className="text-5xl uppercase text-center font-alumni font-medium mb-8 mt-24">
          MEDIA PARTNERS
        </div>
        <div className="flex flex-row flex-wrap justify-center lg:w-3/5 gap-12 p-8">
          {/* <MediaSponsors image={sakalmediagroup} /> */}
          <MediaSponsors image={sakal} />
          <MediaSponsors image={maharashtra_times} />
          <MediaSponsors image={kesari} />
          <MediaSponsors image={campus_times} />
          <MediaSponsors image={punyanagari} />
          <MediaSponsors image={yourstory} />
        </div>
      </div>

      {/* past sponsors */}
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="text-5xl uppercase text-center font-alumni font-medium mb-8 mt-24">
          PREVIOUS PARTNERS
        </div>

        {/* divs for aesthetics purposes */}
        <div
          className="absolute w-full h-full bg-no-repeat bg-center bg-cover -z-20"
          style={{ backgroundImage: `url(${past_sponsors_bg})` }}
        ></div>

        {/* second overlay of gradient to blend in */}
        <div
          className="absolute w-full h-full -z-10 "
          style={{
            background:
              "linear-gradient(180deg, rgba(8,4,17,1) 0%, rgba(8,4,17,0.2) 20%,rgba(8,4,17,0.2) 80%, rgba(8,4,17,1) 100%)",
          }}
        ></div>

        <div className="flex flex-row flex-wrap justify-center lg:w-7/8 gap-8 p-8">
        <PastSponsor image={pesb}/>
          <PastSponsor image={marshmclennan} />
          <PastSponsor image={kalyanibharatforge} />
          <PastSponsor image={growthschool} />
          <PastSponsor image={quickheal} />
          <PastSponsor image={awsactivate} />
          <PastSponsor image={greythr} />
          <PastSponsor image={grabon} />
          <PastSponsor image={phicommerce} />
          <PastSponsor image={payphi} />
          <PastSponsor image={shoptimize} />
          <PastSponsor image={riidl} />
          <PastSponsor image={iyedo} />
          <PastSponsor image={venturewolf} />
          <PastSponsor image={anand} />
          <PastSponsor image={internin} />

          <PastSponsor image={startups1000} />
          <PastSponsor image={s} />
          <PastSponsor image={yesstartups} />
          <PastSponsor image={tickertape} />
          <PastSponsor image={ykt} />
          <PastSponsor image={sbi} />
          <PastSponsor image={oyoworkspaces} />
          <PastSponsor image={bigfm} />
          <PastSponsor image={polycab} />
          <PastSponsor image={hindustantimes} />
          <PastSponsor image={pma} />
          <PastSponsor image={accessmba} />
          <PastSponsor image={wework} />
          <PastSponsor image={funkyturtle} />
          <PastSponsor image={d2c} />
          <PastSponsor image={mahastateinnosociety} />
        </div>
      </div>
    </div>
  );
}

export default Sponsors;