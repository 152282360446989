import React, { useState, useEffect } from 'react';
import { db } from '../../firebase-config';
import { collection, query, onSnapshot, orderBy, limit } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faShield, faMedal } from '@fortawesome/free-solid-svg-icons';

const Scoreboard = () => {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const usersQuery = query(
      collection(db, 'lpusers'),
      orderBy('score', 'desc'),
      limit(10)
    );

    const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
      const scoreData = snapshot.docs.map((doc, index) => ({
        username: doc.data().username,
        score: doc.data().score || 0,
        rank: index + 1,
        id: doc.id,
        lastUpdated: doc.data().lastUpdated?.toDate() || new Date(),
      }));
      setScores(scoreData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-4xl font-bold text-center mb-10 flex items-center justify-center gap-3 text-gray-800 mt-24">
        <FontAwesomeIcon icon={faTrophy} className="text-yellow-500 w-10 h-10" />
        Top 10 Leaderboard
      </h1>

      {loading ? (
        <div className="text-center py-10 text-lg font-medium text-gray-700">
          Loading scores...
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm">
          {scores.length > 0 ? (
            <div className="divide-y divide-gray-300">
              {scores.map((score) => (
                <div
                  key={score.id}
                  className={`p-6 flex items-center justify-between hover:bg-gray-50 transition ${
                    score.rank === 1
                      ? 'bg-gradient-to-r from-yellow-200 to-yellow-50'
                      : score.rank === 2
                      ? 'bg-gradient-to-r from-gray-300 to-gray-100'
                      : score.rank === 3
                      ? 'bg-gradient-to-r from-orange-300 to-orange-100'
                      : 'bg-white'
                  }`}
                >
                  <div className="flex items-center gap-5">
                    <span
                      className={`text-3xl font-bold ${
                        score.rank === 1
                          ? 'text-yellow-500'
                          : score.rank === 2
                          ? 'text-gray-500'
                          : score.rank === 3
                          ? 'text-orange-500'
                          : 'text-gray-900'
                      }`}
                    >
                      {score.rank === 1 ? (
                        <FontAwesomeIcon icon={faTrophy} />
                      ) : score.rank === 2 ? (
                        <FontAwesomeIcon icon={faShield} />
                      ) : score.rank === 3 ? (
                        <FontAwesomeIcon icon={faMedal} />
                      ) : (
                        `${score.rank}`
                      )}
                    </span>
                    <div>
                      <p className="text-lg font-semibold text-gray-800">{score.username}</p>
                      <p className="text-sm text-gray-500">
                        Last updated: {formatDate(score.lastUpdated)}
                      </p>
                    </div>
                  </div>
                  <span className="text-2xl font-bold text-gray-800">{score.score} pts</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-10 text-lg text-gray-500">
              No scores available yet.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Scoreboard;
