import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faRocket } from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faIdCard } from "@fortawesome/free-regular-svg-icons";

const TopMenu = ({ onSectionChange, activeSection }) => {
  return (
    <div className="w-full bg-gradient-to-r from-white-600 to-white-800 text-white shadow-lg mt-20 flex flex-col items-center">
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 w-11/12 lg:w-3/5 items-center justify-center gap-2 md:gap-8 lg:gap-16 px-4 py-4">
        {[
          { id: "masterclass", icon: faBookOpen, label: "Masterclass" },
          { id: "events", icon: faCalendar, label: "Events" },
          { id: "launchpad", icon: faRocket, label: "Launchpad" },
          { id: "visitors", icon: faIdCard, label: "Visitors" },
        ].map(({ id, icon, label }, index, array) => (
          <React.Fragment key={id}>
            <div className="flex flex-col items-center">
              <button
                onClick={() => onSectionChange(id)}
                className={`flex items-center space-x-2 text-base md:text-lg font-medium hover:text-purple-500 transition-colors duration-200 relative pb-2 ${
                  activeSection === id ? "text-purple-500" : ""
                }`}
              >
                <FontAwesomeIcon
                  icon={icon}
                  className={activeSection === id ? "text-purple-500" : ""}
                />
                <span>{label}</span>
                {activeSection === id && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-purple-500" />
                )}
              </button>
            </div>
            {index !== array.length - 1 && (
              <div className="hidden lg:flex w-full justify-center items-center">
                <div className="h-6 w-px bg-purple-300 self-center hidden lg:block" />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TopMenu;