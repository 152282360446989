export default function StatsCard({ stat, title }) {
  return (
    <div className=" flex flex-col items-center p-2 mt-4 mb-4">
      <h1 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white via-[#9573de] to-white">
        {stat}
      </h1>
      <p className="text-sm md:text-base px-2">{title}</p>
    </div>
  );
}
