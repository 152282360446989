export default function InvestorsEvents({
  eventTitle,
  eventDescription1,
  eventDescription2,
  eventImage,
  color,
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 items-stretch">
        <div className={color}>{eventTitle}</div>
        <div className="eventDescription1 pr-8 font-semibold text-base md:text-lg items-stretch">
          {eventDescription1}
        </div>
        <div className="eventDescription2 pr-8 font-semibold text-base md:text-lg items-stretch ">
          {eventDescription2}
        </div>
      </div>

      <div className="h-52 md:h-64 lg:h-72 w-full md:w-10/11 lg:w-11/12 bg-center bg-cover bg-no-repeat rounded-md relative overflow-hidden ring-white ring-opacity-50 hover:ring-1">
        <div
          className="absolute inset-0 bg-center bg-cover transition-transform duration-300 transform hover:scale-105"
          style={{ backgroundImage: `url(${eventImage})` }}
        >
          <div className="absolute w-full h-full bg-[#070310] opacity-30 transition-opacity duration-500 hover:opacity-0"></div>
        </div>
      </div>
    </div>
  );
}
