import { Button } from '@mui/material';
import React, { useState } from 'react';
import Admin from './admin';

const ProtectedAdmin = () => {
    const [password, setPassword] = useState('');
    const [auth, setAuth] = useState(false);

    const handlePasswordChange = (e) => {
        console.log(e.target.value);
        setPassword(e.target.value);
    }

    const handleSubmit = () => {
        if (password === 'dogshit') {
            setAuth(true);
        }
    }

    return (
        <div>
            {auth ? (
                <Admin />
            ) : (
                <div>
                    <input type='password' value={password} onChange={handlePasswordChange} className='m-56 ml-36 text-black'/>
                    <Button onClick={handleSubmit}>Submit</Button>
                </div>
            )}
        </div>
    );
}

export default ProtectedAdmin;