import React, { useState, useEffect } from 'react';
import { db } from '../../firebase-config';
import { seedStartups } from './startups';
import { 
  collection, 
  getDocs, 
  updateDoc, 
  doc, 
  setDoc, 
  query, 
  where, 
  increment, 
  serverTimestamp, 
  onSnapshot 
} from 'firebase/firestore';
import { Play, Timer, StopCircle } from 'lucide-react';

const Admin = () => {
  const [startups, setStartups] = useState([]);
  const [selectedStartup, setSelectedStartup] = useState('');
  const [actualInvestors, setActualInvestors] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTimer, setActiveTimer] = useState(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const fetchStartups = async () => {
      try {
        const startupsRef = collection(db, 'lpstartups');
        const startupsSnap = await getDocs(startupsRef);
        const startupsData = startupsSnap.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.order - b.order);
        setStartups(startupsData);
      } catch (error) {
        console.error('Error fetching startups:', error);
        setMessage('Error fetching startups');
      }
    };
    fetchStartups();

    // Subscribe to timer updates
    const timerRef = doc(db, 'timer', 'current');
    const unsubscribe = onSnapshot(timerRef, (snapshot) => {
      if (snapshot.exists()) {
        setTimer(snapshot.data().time || 0);
      } else {
        setTimer(0);
      }
    });

    return () => {
      if (activeTimer) clearInterval(activeTimer);
      unsubscribe();
    };
  }, []);

  const startVoting = async () => {
    if (!selectedStartup) {
      setMessage('Please select a startup');
      return;
    }

    setLoading(true);
    try {
      // Reset any previously active startup
      const activeStartups = await getDocs(
        query(collection(db, 'lpstartups'), where('active', '==', true))
      );
      await Promise.all(
        activeStartups.docs.map(doc =>
          updateDoc(doc.ref, { active: false })
        )
      );

      // Set new active startup
      const startupRef = doc(db, 'lpstartups', selectedStartup);
      await updateDoc(startupRef, { 
        active: true,
        votingStarted: serverTimestamp()
      });
      
      // Initialize timer
      const timerRef = doc(db, 'timer', 'current');
      await setDoc(timerRef, { time: 180 });
      
      setMessage(`Voting started for ${startups.find(s => s.id === selectedStartup)?.name}`);

      // Start countdown
      let timeLeft = 180;
      const interval = setInterval(async () => {
        timeLeft -= 1;
        if (timeLeft <= 0) {
          clearInterval(interval);
          await updateDoc(startupRef, { active: false });
          setMessage('Voting time ended. Please enter actual investors count.');
        } else {
          await setDoc(timerRef, { time: timeLeft });
        }
      }, 1000);

      setActiveTimer(interval);
    } catch (error) {
      console.error('Error starting voting:', error);
      setMessage('Error starting voting session');
    }
    setLoading(false);
  };

  const stopVoting = async () => {
    if (!selectedStartup) {
      setMessage('No active voting session to stop');
      return;
    }
  
    setLoading(true);
    try {
      // Stop the active timer
      if (activeTimer) {
        clearInterval(activeTimer);
        setActiveTimer(null);
      }
  
      // Update the database to mark the startup as inactive
      const startupRef = doc(db, 'lpstartups', selectedStartup);
      await updateDoc(startupRef, { active: false });
  
      // Reset timer in the database
      const timerRef = doc(db, 'timer', 'current');
      await setDoc(timerRef, { time: 0 });
  
      setMessage(`Voting session for ${startups.find(s => s.id === selectedStartup)?.name} has been stopped.`);
    } catch (error) {
      console.error('Error stopping voting session:', error);
      setMessage('Error stopping voting session');
    }
    setLoading(false);
  };
  

  const handleCalculateScores = async () => {
    if (!selectedStartup || !actualInvestors) {
      setMessage('Please select a startup and enter actual investors count');
      return;
    }
  
    setLoading(true);
    try {
      // Get all users
      const usersSnap = await getDocs(collection(db, 'lpusers'));
      
      const updates = usersSnap.docs.map(async (userDoc) => {
        const userData = userDoc.data();
        const userGuess = userData.guesses?.find(g => g.startupId === selectedStartup);
        
        if (userGuess && userGuess.guess === parseInt(actualInvestors)) {
          await updateDoc(doc(db, 'lpusers', userDoc.id), {
            score: increment(50)
          });
        }
      });
  
      await Promise.all(updates);
  
      // Mark startup as completed and save the actual investors count
      await updateDoc(doc(db, 'lpstartups', selectedStartup), {
        actualInvestors: parseInt(actualInvestors),
        completed: true, // Ensure the startup is marked as completed
        active: false,   // Ensure it is no longer active
      });
  
      // Reset the timer in the database
      const timerRef = doc(db, 'timer', 'current');
      await setDoc(timerRef, { time: 0 });
  
      setMessage('Scores calculated successfully!');
      setActualInvestors('');
    } catch (error) {
      console.error('Error calculating scores:', error);
      setMessage('Error calculating scores');
    }
    setLoading(false);
  };
  

  return (
    <div className="max-w-4xl mx-auto p-6 bg-transparent text-white rounded-lg shadow-lg ">
      <h1 className="text-3xl font-bold text-center mb-8 text-purple-500 mt-24">Admin Control Panel</h1>
      
      <div className="bg-gray-800 p-6 rounded-lg space-y-6">
        <button
          onClick={seedStartups}
          className="w-full flex items-center justify-center gap-2 bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700"
        >
          Seed Startups Data
        </button>

        <div>
          <label className="block text-sm font-medium mb-2 text-white">Select Startup</label>
          <select
            value={selectedStartup}
            onChange={(e) => setSelectedStartup(e.target.value)}
            className="w-full px-4 py-2 border rounded-md bg-gray-700 text-white"
          >
            <option value="">Select a startup</option>
            {startups.map(startup => (
              <option 
                key={startup.id} 
                value={startup.id}
                disabled={startup.completed}
              >
                {startup.name} {startup.completed ? '(Completed)' : ''}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={startVoting}
          disabled={loading || !selectedStartup}
          className="w-full flex items-center justify-center gap-2 bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 disabled:opacity-50"
        >
          <Play className="w-4 h-4" />
          Start 3-Minute Voting Session
        </button>

        <button
          onClick={stopVoting}
          disabled={loading || !selectedStartup || !activeTimer}
          className="w-full flex items-center justify-center gap-2 bg-red-600 text-white py-2 rounded-md hover:bg-red-700 disabled:opacity-50"
        >
          <StopCircle className="w-4 h-4" />
          Stop Voting Session
        </button>

        <div className="text-center text-xl font-bold">
          {timer > 0 ? `Time Left: ${timer}s` : 'No Active Timer'}
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Actual Number of Investors</label>
          <input
            type="number"
            value={actualInvestors}
            onChange={(e) => setActualInvestors(e.target.value)}
            className="w-full px-4 py-2 border rounded-md bg-gray-700 text-white"
            placeholder="Enter actual number"
            min="0"
          />
        </div>

        <button
          onClick={handleCalculateScores}
          disabled={loading || !actualInvestors || !selectedStartup}
          className="w-full flex items-center justify-center gap-2 bg-green-600 text-white py-2 rounded-md hover:bg-green-700 disabled:opacity-50"
        >
          <Timer className="w-4 h-4" />
          Calculate Scores
        </button>

        {message && (
          <div className="p-4 bg-blue-50 text-blue-700 rounded-md">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
