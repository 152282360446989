import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ecell_logo from "./assets/logo/E-Cell-Logo-white.png";

import CustomChips from "./Components/Mainpage/CustomChips";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  //managing explore section ... mouse clicks and everything

  const [isExploreOpen, setIsExploreOpen] = useState(false);
  const exploreRef = useRef(null); // Ref to track the Explore section

  function openExploreSection() {
    setIsExploreOpen((prev) => !prev); // Toggle the state
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (exploreRef.current && !exploreRef.current.contains(event.target)) {
        setIsExploreOpen(false); // Close the section if clicked outside
      }
    }

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //managing explore sections over

  const links = [
    {name: "LiveEvents", section: "LiveEvents"},
    {name: "StartupExpo", section: "StartupExpo"},
    { name: "Gallery", section: "gallery" },
    { name: "Team", section: "team" },
    { name: "Timeline", section: "timeline" },
    { name: "About", section: "about" },
    // { name: "Brandscan", section: "brandscan" },
    // { name: "Wordsearch", section: "wordsearch" },
    { name: "IIPR Blog", section: "iiprblog" },
    // { name: "Contact", section: "contact" },
    // { name: "Events", section: "events" },
    {name: "Internships", section: "Internships"},
  ];

  return (
    <nav
      ref={exploreRef}
      className="fixed w-screen z-50 bg-psfdark bg-opacity-60 backdrop-blur-2xl shadow-sm border-b-[1px] border-psfviolet border-opacity-25 "
    >
      <div className="w-full lg:w-4/5 container px-6 py-2 md:py-4 mx-auto">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <a href="/">
                <img src={ecell_logo} alt="E-cell logo" className="h-12" />
              </a>
            </div>

            {/* Mobile menu button */}
            <div
              className="flex lg:hidden gap-4"
              onClick={() => setIsOpen(!isOpen)}
            >
              <a
                className="my-2 text-sm leading-5 transition-colors duration-300 transform bg-psfviolet p-2 px-4 text-center  rounded-xl text-gray-200"
                href="/register/visitors"
              >
                Register
              </a>

              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                aria-label="toggle menu"
              >
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="non text-lge"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <div
            className={`${
              isOpen
                ? "translate-x-0 opacity-100"
                : "opacity-50 -translate-x-full"
            } absolute inset-x-0 z-20 w-full px-6 py-2 transition-all duration-300 ease-in-out top-12 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center`}
          >
            <div className="flex flex-col bg-[#070310] lg:bg-none ring-[1px] lg:ring-0  backdrop-blur-3xl backdrop-brightness-50 ring-psfviolet ring-opacity-80 lg:flex-row lg:mx-1 lg:backdrop-blur-none  lg:backdrop-brightness-100 py-2 lg:py-0 text-lg font-semibold gap-2 mt-6 md:mt-8 lg:mt-0 lg:bg-transparent px-4 rounded-xl">
              <a
                className="leading-5  transition-colors duration-300 transform text-gray-200  hover:bg-psfviolet p-2 px-4 rounded-xl min-w-24 text-center lg:mx-1"
                href="/Sponsors"
              >
                Sponsors
              </a>

              <a
                className="leading-5  transition-colors duration-300 transform text-gray-200  hover:bg-psfviolet p-2 px-4 rounded-xl min-w-24 text-center lg:mx-1"
                href="/Investors"
              >
                Investors
              </a>

              <a
                className="leading-5  transition-colors duration-300 transform text-gray-200  hover:bg-psfviolet p-2 px-4 rounded-xl min-w-24 text-center lg:mx-1"
                href="/Alumni"
              >
                Alumni
              </a>

              <button
                className="hidden lg:block leading-5  transition-colors duration-300 transform text-gray-200  hover:bg-psfviolet p-2 px-4 rounded-xl min-w-24 text-center lg:mx-1 lg:ring-[1px] ring-psfviolet"
                onClick={openExploreSection}
              >
                Explore
              </button>

              <a
                className="my-2 text-base leading-5 transition-colors duration-300 transform   lg:bg-psfviolet ring-1 ring-psfviolet hover:bg-transparent p-2 px-4 text-center  rounded-xl text-gray-200  lg:mx-4 lg:my-0"
                href="/register/visitors"
              >
                Register
              </a>

              {isOpen && (
                <div className="flex flex-col h-[50%] lg:min-96 justify-center items-center mt-8 mb-8">
                  <div
                    className="flex flex-row gap-4 flex-wrap w-full md:w-4/5 justify-center items-center"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {links.map((link) => (
                      <Link
                        key={link.name}
                        to={`/explore/${link.section}`} // Dynamic route
                        className=""
                      >
                        <CustomChips text={link.name} textColor="#c2bcdf" />
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {isExploreOpen && (
          <div className="hidden lg:visible lg:flex flex-row lg:items-center lg:justify-center py-3 gap-2 -mx-3 overflow-y-auto whitespace-nowrap scroll-hidden">
            {links.map((link) => (
              <Link
                key={link.name}
                to={`/explore/${link.section}`} // Dynamic route
              >
                <CustomChips text={link.name} textColor="#c2bcdf" />
              </Link>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
