    import React, { useState } from 'react';
    import { MapPin } from 'lucide-react';

    const Live = () => {
    const [events] = useState([
        { // done
        id: 1,
        name: "Bizwatch",
        location: "LP Arena",
        coordinates: "18.5296749932698, 73.8564591692453",
        startDate: new Date('2025-01-11T16:30:00'), // Start date and time
        endDate: new Date('2025-01-11T17:30:00'),
        },
        { //done - ROUND 1
        id: 2,
        name: "BidWars",
        location: "South Campus",
        coordinates: "18.529713294130918, 73.85750071354775",
        startDate: new Date('2025-01-11T14:00:00'), // Start date and time
        endDate: new Date('2025-01-11T16:00:00'),
        },
        { // done
        id: 3,
        name: "Budge-It",
        location: "Boat Club",
        coordinates: "18.53115729841918, 73.85640089661952",
        startDate: new Date('2025-01-12T10:30:00'), // Start date and time
        endDate: new Date('2025-01-12T11:30:00'),
        },
        { // done - ROUND 1
        id: 4,
        name: "Brandscan",
        location: "Seminar Hall 2",
        coordinates: "18.529713294130918, 73.85750071354775",
        startDate: new Date('2025-01-11T11:30:00'), // Start date and time
        endDate: new Date('2025-01-11T12:30:00'),
        },
        {
        id: 5,
        name: "Bullrun",
        location: "COEP Tech.",
        coordinates: "18.529713294130918, 73.85750071354775",
        startDate: new Date('2025-01-06T10:00:00'), // Start date and time
        endDate: new Date('2025-01-10T22:00:00'),
        },
        { // done
        id: 6,
        name: "CEO Challenge",
        location: "Dept. of Management",
        coordinates: "18.529713294130918, 73.85750071354775",
        startDate: new Date('2025-01-11T13:00:00'), // Start date and time
        endDate: new Date('2025-01-11T16:00:00'),
        },
        { // done
        id: 7,
        name: "Shishya",
        location: "Main Auditorium",
        coordinates: "18.529672603558055, 73.85745779820415",
        startDate: new Date('2025-01-11T14:00:00'), // Start date and time
        endDate: new Date('2025-01-11T16:00:00'),
        },
        { // done
        id: 8,
        name: "Spectra",
        location: "Academic Complex",
        coordinates: "18.53144246092687, 73.85569709291698",
        startDate: new Date('2025-01-12T11:30:00'), // Start date and time
        endDate: new Date('2025-01-12T14:30:00'),
        },
        { // done
        id: 9,
        name: "Optify",
        location: "COEP Tech.",
        coordinates: "18.529713294130918, 73.85750071354775",
        startDate: new Date('2025-01-11T10:00:00'), // Start date and time
        endDate: new Date('2025-01-12T22:00:00'),
        },
        { // done
        id: 10,
        name: "Courtroom Clash",
        location: "Dept. of Manufacturing",
        coordinates: "18.529713294130918, 73.85750071354775",
        startDate: new Date('2025-01-11T14:00:00'), // Start date and time
        endDate: new Date('2025-01-11T16:00:00'),
        },
        { // done
        id: 11,
        name: "Pitcher's Valley",
        location: "Main Auditorium",
        coordinates: "18.529672603558055, 73.85745779820415",
        startDate: new Date('2025-01-12T11:00:00'), // Start date and time
        endDate: new Date('2025-01-12T16:30:00'),
        },
        { // done
        id: 12,
        name: "Lightning Pitch",
        location: "Main Auditorium",
        coordinates: "18.529672603558055, 73.85745779820415",
        startDate: new Date('2025-01-11T11:30:00'), // Start date and time
        endDate: new Date('2025-01-11T14:00:00'),
        },
        {
        id: 13,
        name: "Investor-Startup Debate",
        location: "Main Auditorium",
        coordinates: "18.529672603558055, 73.85745779820415",
        startDate: new Date('2025-01-09T14:00:00'), // Start date and time
        endDate: new Date('2025-01-09T16:00:00'),
        }, 
        { // round 2
        id: 14,
        name: "BidWars",
        location: "LP Arena",
        coordinates: "0,0",
        startDate: new Date('2025-01-12T10:30:00'),
        endDate: new Date('2025-01-12T13:00:00')
        },
        { // round 2
        id: 15,
        name: "Brandscan",
        location: "LP Arena",
        coordinates: "0,0",
        startDate: new Date('2025-01-11T17:30:00'),
        endDate: new Date('2025-01-11T18:30:00')
        }
    ]);

    // State for Live Masterclasses
    const [liveMasterclasses] = useState([
        { // done
        id: 1,
        name: "LinkedIn Amplified",
        location: "Dept. of Management",
        coordinates: "18.52931568410297, 73.85840511903386",
        startDate: new Date('2025-01-12T14:30:00'),
        endDate: new Date('2025-01-12T15:30:00'),
        },
        { // done
        id: 2,
        name: "Design Decoded",
        location: "Dept. of Management",
        coordinates: "18.52931568410297, 73.85840511903386",
        startDate: new Date('2025-01-12T13:00:00'),
        endDate: new Date('2025-01-12T14:00:00'),
        },
        { // done
        id: 3,
        name: "Stock Talks",
        location: "Dept. of Management",
        coordinates: "18.52931568410297, 73.85840511903386",
        startDate: new Date('2025-01-12T10:30:00'),
        endDate: new Date('2025-01-12T11:30:00'),
        },
        { // done
        id: 4,
        name: "Startup 101",
        location: "Dept. of Management",
        coordinates: "18.52931568410297, 73.85840511903386",
        startDate: new Date('2025-01-11T15:30:00'),
        endDate: new Date('2025-01-11T16:30:00'),
        },
        { // done
        id: 5,
        name: "Data Defense",
        location: "Dept. of Management",
        coordinates: "18.52931568410297, 73.85840511903386",
        startDate: new Date('2025-01-11T14:00:00'),
        endDate: new Date('2025-01-11T15:00:00'),
        },
        { // done
        id: 6,
        name: "The Creator's Blueprint",
        location: "Dept. of Management",
        coordinates: "18.52931568410297, 73.85840511903386",
        startDate: new Date('2025-01-11T11:00:00'),
        endDate: new Date('2025-01-11T12:30:00'),
        }
    ]);

    const handleEventClick = (coordinates) => {
        // Open Google Maps in a new tab with the specified coordinates
        window.open(`https://www.google.com/maps?q=${coordinates}`, '_blank');
    };

    // Function to get the current date and time
    const getCurrentDateTime = () => {
        return new Date();
    };

    // Function to filter live events/masterclasses
    const getLiveEvents = (events) => {
        const currentDateTime = getCurrentDateTime();
        return events.filter((event) => {
        return event.startDate <= currentDateTime && event.endDate >= currentDateTime;
        });
    };

    // Function to filter unique events by name
    const getUniqueEvents = (events) => {
        const uniqueNames = new Set();
        return events.filter((event) => {
        if (!uniqueNames.has(event.name)) {
            uniqueNames.add(event.name);
            return true;
        }
        return false;
        });
    };

    // EventCard component
    const EventCard = ({ event, onClick, isLive }) => {
        const isSameDay = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
        };
    
        if (isLive) {
        return (
            <button
            style={{ backgroundColor: '#EF4444', color: '#FFFFFF' }}
            className="border-2 border-white px-5 py-2.5 rounded-lg cursor-pointer w-full sm:w-[90%] md:w-[80%] lg:w-[80%] mb-5 text-center shadow-md transition-transform duration-200 hover:scale-105"
            onClick={onClick}
            >
            {event.name} {/* Only display the event name */}
            </button>
        );
        }
    
        return (
        <div
            className="border border-gray-300 rounded-lg p-5 text-center shadow-md cursor-pointer transition-transform duration-200 hover:scale-105 hover:bg-[#3b6a92] bg-[#20405c] w-full sm:w-[48%] md:w-[32%] lg:w-[24%] mb-5 ml-4 mr-4"
            onClick={onClick}
        >
            <h2 className="text-xl font-bold mb-2.5">{event.name}</h2>
            <p className="text-white-600">
            Date: {isSameDay(event.startDate, event.endDate)
                ? event.startDate.toLocaleDateString()
                : `${event.startDate.toLocaleDateString()} - ${event.endDate.toLocaleDateString()}`
            }
            </p>
            <p className="text-white-600">
            Time: {event.startDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })} - {event.endDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
            </p>
            <p className="text-white-600">Location: {event.location}</p>
            <div className="flex items-center justify-center mt-2.5">
            <MapPin size={16} className="mr-1.5" />
            <span>View on Map</span>
            </div>
        </div>
        );
    };

    const liveEvents = getLiveEvents(events);
    const liveMasterclassesEvents = getLiveEvents(liveMasterclasses);

    // Filter unique events and masterclasses
    const uniqueEvents = getUniqueEvents(events);
    const uniqueMasterclasses = getUniqueEvents(liveMasterclasses);

    return (
        <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center mb-10 mt-24">
            <h1 className="text-center mb-5 text-2xl font-bold">Live Events / Masterclasses</h1>
            <div className="flex flex-wrap justify-center gap-5 w-full max-w-[1200px]">
            {liveEvents.map((event) => (
                <EventCard
                key={event.id}
                event={event}
                onClick={() => handleEventClick(event.coordinates)}
                isLive={true}
                instances={[
                    {
                    instanceId: event.id,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    },
                ]}
                />
            ))}
            {liveMasterclassesEvents.map((masterclass) => (
                <EventCard
                key={masterclass.id}
                event={masterclass}
                onClick={() => handleEventClick(masterclass.coordinates)}
                isLive={true}
                instances={[
                    {
                    instanceId: masterclass.id,
                    startDate: masterclass.startDate,
                    endDate: masterclass.endDate,
                    },
                ]}
                />
            ))}
            </div>
        </div>

        {/* Existing Events Section */}
        <h1 className="text-center mb-5 text-2xl font-bold">Events</h1>
        <div className="flex flex-wrap justify-center gap-5 w-full max-w-[1200px]">
            {uniqueEvents.map((event) => (
            <EventCard
                key={event.id}
                event={event}
                onClick={() => handleEventClick(event.coordinates)}
                isLive={false}
            />
            ))}
        </div>

        {/* Live Masterclasses Section */}
        <h1 className="text-center mt-10 mb-5 text-2xl font-bold">Masterclasses</h1>
        <div className="flex flex-wrap justify-center gap-5 w-full max-w-[1200px]">
            {uniqueMasterclasses.map((masterclass) => (
            <EventCard
                key={masterclass.id}
                event={masterclass}
                onClick={() => handleEventClick(masterclass.coordinates)}
                isLive={false}
            />
            ))}
        </div>
        </div>
    );
    };

    export default Live;