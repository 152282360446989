import BabaKalyani from "../../assets/past_speakers/baba_kalyani.webp";
import PratapPawar from "../../assets/past_speakers/pratap_pawar.webp";
import HarshMariwala from "../../assets/past_speakers/harsh_mariwala.webp";
import AshokRamachandran from "../../assets/past_speakers/ashok_ramchandran.webp";
import AmanDhattarwal from "../../assets/past_speakers/aman_datharwall.webp";
import AbhinNiyu from "../../assets/past_speakers/abhi_niyu.webp";
import AmitKalyani from "../../assets/past_speakers/amit_kalyani.webp";
import AbhijitPawar from "../../assets/past_speakers/abhijit_pawar.webp";
import RachanaRanade from "../../assets/past_speakers/rachna_ranade.webp";
import GaneshprasadS from "../../assets/past_speakers/ganeshprasad.webp";
import PrasadLendwe from "../../assets/past_speakers/prasadlendwe.webp";

import SandeepJain from "../../assets/past_speakers/sandeep_jain.png.webp";
import NehaNagar from "../../assets/past_speakers/neha_nagar.png.webp";
import ArshGoyal from "../../assets/past_speakers/arsh_goyal.png.webp";
import KapilShelke from "../../assets/past_speakers/kapil_shelke.png.webp";
import AnshMehra from "../../assets/past_speakers/ansh_mehra.png.webp";

import CustomChips from "./CustomChips";

import SpeakerCard from "./SpeakerCard";

export default function PastSpeakers() {
  return (
    <div className="flex flex-col justify-center items-center group">
      <CustomChips text={"Past Speakers"} />
      <p className="bg-clip-text text-center text-transparent font-bold bg-gradient-to-r from-[#dcdcdc] via-[#7d60bb] to-[#dedede] uppercase text-2xl md:text-4xl lg:text-5xl my-4">
        Pioneers Who Inspired Us
      </p>
      <div className="flex flex-wrap md:px-2 justify-evenly w-screen lg:w-4/5">
        <SpeakerCard
          name={"Baba Kalyani"}
          speakerimage={BabaKalyani}
          profession={
            "Managing Director of Bharat Forge | Founder of Kalyani Group | Padma Bhushan"
          }
        />

        <SpeakerCard
          name={"Pratap Pawar"}
          speakerimage={PratapPawar}
          profession={
            "Chairman, Sakal Media Group | Former President, MCCIA | Padma Shri"
          }
        />

        <SpeakerCard
          name={"Harsh Mariwala"}
          speakerimage={HarshMariwala}
          profession={
            "AFounder & Chairman, Marico ltd | Chairman & MD, Kaya ltd."
          }
        />

        <SpeakerCard
          name={"Ashok Ramachandran"}
          speakerimage={AshokRamachandran}
          profession={"CEO & President | Schindler India"}
        />

        <SpeakerCard
          name={"Aman Dhattarwal"}
          speakerimage={AmanDhattarwal}
          profession={"Founder, Apni Kaksha"}
        />

        <SpeakerCard
          name={"Abhi & Niyu"}
          speakerimage={AbhinNiyu}
          profession={"Content Creators, Following Love"}
        />

        <SpeakerCard
          name={"Amit Kalyani"}
          speakerimage={AmitKalyani}
          profession={"Deputy MD, Bharat Forge"}
        />

        <SpeakerCard
          name={"Abhijit Pawar"}
          speakerimage={AbhijitPawar}
          profession={"MD, Sakal Media Group"}
        />

        <SpeakerCard
          name={"Rachana Ranade"}
          speakerimage={RachanaRanade}
          profession={"Rachana Ranade and Associates | Youtube influencer"}
        />

        <SpeakerCard
          name={"Ganeshprasad S."}
          speakerimage={GaneshprasadS}
          profession={"Think School"}
        />

        <SpeakerCard
          name={"Prasad Lendwe"}
          speakerimage={PrasadLendwe}
          profession={"Founder of CONVEY by FinnovationZ"}
        />

        <SpeakerCard
          name={"Neha Nagar"}
          speakerimage={NehaNagar}
          profession={"CEO | Taxationhelp.in | FinFluencer"}
        />

        <SpeakerCard
          name={"Arsh Goyal"}
          speakerimage={ArshGoyal}
          profession={"Youtuber | Software Engineer at Samsung India"}
        />

        <SpeakerCard
          name={"Sandeep Jain"}
          speakerimage={SandeepJain}
          profession={"Founder & CEO | GeekForGeeks"}
        />

        <SpeakerCard
          name={"Kapil Shelke"}
          speakerimage={KapilShelke}
          profession={"Founder & CEO | Tork Motors"}
        />

        <SpeakerCard
          name={"Ansh Mehra"}
          speakerimage={AnshMehra}
          profession={"UX Designer | Educator on YouTube & Instagram"}
        />
      </div>
    </div>
  );
}
