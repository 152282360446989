import CustomChips from "./CustomChips";
import MasterclassCards from "./MasterclassCards";
import { MasterclassCardPrevious } from "./MasterclassCards";
import { motion } from "framer-motion";

import aimastery from "../../assets/masterclass/anshM1.JPG.webp";
import b2bwizardry from "../../assets/masterclass/suhasiniK.JPG.webp";
import saassavvy from "../../assets/masterclass/akashS.JPG.webp";
import designedge from "../../assets/masterclass/anshM2.JPG.webp";
import masternarrate from "../../assets/masterclass/aakashM.JPG.webp";

import linkedinamp from "../../assets/masterclass/teja.jpeg.webp";
import designdecoded from "../../assets/masterclass/sapta.png.webp";
import stocktalks from "../../assets/masterclass/harsh.png.webp";
import datadefence from "../../assets/masterclass/vinayak_ghodse.jpg.webp";
import thecreatorsblueprint from "../../assets/masterclass/manish.png.webp";
import startup101 from "../../assets/masterclass/pooja_shah.png.webp";


export default function Masterclass() {
  const MC_data = [
    [
      "AI Mastery",
      "Ansh Mehra",
      "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
      aimastery,
    ],
    [
      "SAAS Savvy",
      "Akash Surekha",
      "linear-gradient(160deg, #FF4B2B, #FF416C)",
      saassavvy,
    ],
    [
      "B2B Wizardry",
      "Suhasini Kirloskar",
      "linear-gradient(160deg, #11998E, #38EF7D)",
      b2bwizardry,
    ],
    [
      "Design Edge",
      "Ansh Mehra",
      "linear-gradient(135deg, #F97794 10%, #623AA2 100%)",
      designedge,
    ],
    [
      "Master Narrate",
      "Akash Majumder",
      "linear-gradient(90deg, #FAD961 0%, #F76B1C 100%)",
      masternarrate,
    ],
  ];

  const MC_prev_new = [
    ["LinkedIn Amplified", "Teja Gudluru", linkedinamp],
    ["Design Decoded", "Saptarshi Prakash", designdecoded],
    ["Stock Talks", "Harsh Goela", stocktalks],
    ["Startup 101", "Pooja Shah", startup101],
    ["Data Defence", "Vinayak Godse", datadefence],
    ["The Creator's Blueprint", "Manish Pandey", thecreatorsblueprint],
  ];

  return (
    <div className="h-fit py-16 lg:py-4 w-screen overflow-hidden flex flex-col items-center gap-4">
      <CustomChips text={"Masterclass"} />

      <div className="font-psfsans text-3xl md:text-5xl font-bold my-2 bg-clip-text text-center text-transparent bg-gradient-to-r from-[#dcdcdc] via-[#7d60bb] to-[#dedede]">
        Crafting Excellence Through MASTERCLASS
      </div>

      <div className="flex flex-col mt-8 items-center justify-center bg-center bg-contain bg-none lg:bg-gradient-to-b from-[#5e71f6] via-[#a255f7] to-[#070310] p-2 lg:p-4 rounded-t-2xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center justify-center gap-4 mb-12">
          {MC_prev_new.map(([title, speakerName, imagePath], index) => (
            <MasterclassCardPrevious
              key={index}
              image_path={imagePath}
              masterclass={title}
              speaker={speakerName}
            />
          ))}
        </div>
      </div>

     
      <a href="/register">
        <motion.button
          whileTap={{ scaleX: 1.1 }}
          whileHover={{ scale: 1.1 }}
          className="bg-gradient-to-r font-psfsans from-psfviolet to-sky-400 p-2 px-8 text-lg rounded-xl w-60 place-self-end"
        >
          Register
        </motion.button>
      </a>

      <p className="font-psfsans text-base md:textxl lg:text-xl text-psfviolet italic -mt-2">
        Attend our Masterclass at PSF'25
      </p>


      <div className="masterclass-grid flex flex-col gap-2 md:gap-4 w-full items-center mt-20">
        <div className="font-psfsans text-2xl md:text-4xl font-bold my-2 bg-clip-text text-center text-transparent bg-gradient-to-r from-[#dcdcdc] via-[#7d60bb] to-[#dedede]">
          Spotlight: Stars of the Past Edition
        </div>
        {MC_data.map(([title, speakerName, bg, imagePath], index) => (
          <MasterclassCards
            key={index}
            image_path={imagePath}
            masterclass={title}
            speaker={speakerName}
            bg={bg}
          />
        ))}
      </div>
    </div>
  );
}
