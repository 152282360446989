import paper2 from "../../assets/team/paper2.jpg.webp";

import psf1 from "../../assets/team/psf1.webp.webp";

import sec1 from "../../assets/team/sec1.jpg.webp";
import sec2 from "../../assets/team/sec2.jpg.webp";

import mar1 from "../../assets/team/mar_kandharkar.jpg.webp";
import mar2 from "../../assets/team/mar_khadloya.jpg.webp";
import mar3 from "../../assets/team/mar_sanika.jpg.webp";

import ops1 from "../../assets/team/ops1.jpg.webp";
import ops2 from "../../assets/team/ops2.jpg.webp";
import ops3 from "../../assets/team/ops3.jpg.webp";

import en1 from "../../assets/team/en1.jpg.webp";
import en2 from "../../assets/team/en2.jpg.webp";
import en3 from "../../assets/team/en3.jpg.webp";

import iipr1 from "../../assets/team/iipr1.jpg.webp";

import ir1 from "../../assets/team/ir1.jpg.webp";
import ir2 from "../../assets/team/ir2.jpg.webp";

import media1 from "../../assets/team/media1.jpg.webp";
import media2 from "../../assets/team/media2.jpg.webp";
import design1 from "../../assets/team/design1.jpg.webp";

import pr1 from "../../assets/team/pr1.jpg.webp";
import pr2 from "../../assets/team/pr2.jpg.webp";

import acd1 from "../../assets/team/acd1.jpg.webp";
import acd2 from "../../assets/team/acd2.jpg.webp";

import fin1 from "../../assets/team/fin1.jpg.webp";
import fin2 from "../../assets/team/fin2.jpg.webp";

import vfx1 from "../../assets/team/vfx1.jpg.webp";
import vfx2 from "../../assets/team/vfx2.jpg.webp";

import decor1 from "../../assets/team/decor1.jpg.webp";
import decor2 from "../../assets/team/decor2.jpg.webp";

import aog1 from "../../assets/team/aog1.jpeg.webp";
import aog2 from "../../assets/team/aog2.jpeg.webp";

import web_tech from "../../assets/team/web.jpg.webp";

import psf_daily from "../../assets/team/psf_daily.png.webp";

export default function Team() {
  return (
    <div className="w-screen flex flex-col items-center justify-center  font-domine text-slate-900 selection:text-white selection:bg-black">
      {/* first page */}
      <div
        className="w-full md:w-5/6 lg:w-4/5 min-h-screen mt-4 bg-cover bg-center rounded-sm flex flex-col px-2 pb-4 "
        style={{
          backgroundImage: `url(${paper2})`,
        }}
      >
        {/* heading start */}
        <div className="flex flex-col lg:flex-row gap-2 items-center justify-center px-4 py-8 text-slate-900 p-2">
          <div className="hidden lg:block max-w-60 h-fit lg:h-28 text-wrap ring-2 ring-slate-800 ring-opacity-75 rounded-sm text-base lg:text-xl p-2 font-medium">
            The startup syndicate rewriting the rules of innovation
          </div>
          <div className="flex flex-grow items-center justify-center">
            {/* <h1 className="font-imfell text-2xl md:text-4xl lg:text-8xl font-black text-center">
              PSF Daily
            </h1> */}

            <img
              src={psf_daily}
              alt="headtitle"
              className="place-self-center"
            />
          </div>
          <div className="hidden lg:block max-w-72 h-fit lg:h-28 text-wrap ring-2 ring-slate-800 ring-opacity-75 rounded-sm text-base lg:text-xl p-2 font-medium">
            Where every challenge is a puzzle, and every solution is legendary
          </div>
        </div>
        {/* heading end */}

        {/* horizontal line */}
        <div className="h-[2px] w-full bg-slate-900 " />

        {/* grid for psf title */}
        <div className="grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 gap-2 mt-4 items-center justify-center">
          <div className="ring-[1px] ring-slate-900 ring-opacity-75 h-10 text-center justify-center flex text-slate-900 font-medium p-2 ">
            COEP Technological University
          </div>
          <div className="ring-[1px] ring-slate-900 ring-opacity-75 h-10 text-center justify-center flex text-slate-900 font-medium p-2 ">
            Pune Startup Fest 2025
          </div>
          <div className="ring-[1px] ring-slate-900 ring-opacity-75 h-10 text-center justify-center flex text-slate-900 font-medium p-2 ">
            11th & 12th January, 2025
          </div>
        </div>

        {/* headline */}
        <div className="flex flex-col lg:flex-row  mt-4 items-center">
          <div className="w-96 p-2 ring-[2px] ring-slate-800 ring-opacity-75 rounded-sm">
            <img
              src={psf1}
              alt="pune startup fest"
              className=" saturate-0 hover:saturate-150 transition-all transform duration-500 w-96"
            />
            <p className="text-center italic font-light text-slate-800">
              Scenes at Pune Startup Fest 2024
            </p>
          </div>

          <div className="text-lg font-medium text-slate-900 flex flex-col gap-4 pl-8  text-wrap">
            <div className="bg-slate-600 w-full h-[2px]" />
            <p>
              Pune Start-Up Fest (PSF) is the annual entrepreneurial festival
              organized by the BHAU's I&E-Cell of COEP Tech.
            </p>
            <p>
              Pune Start-Up Fest is an annual startup festival organised by
              BHAU's I&E-Cell, COEP Technological University. It has a startup
              expo, known as LAUNCHPAD, which stands out as a distinctive
              startup showcase and provides an excellent platform for numerous
              students and startups to connect with this ever growing
              entrepreneurial world.
            </p>
            <div className="bg-slate-600 w-full h-[2px]" />
          </div>
        </div>

        {/* meet the team */}
        <div className="text-center w-full mt-4 flex  flex-col justify-center items-center ">
          <h2 className="w-full md:w-5/6 lg:w-3/5 text-3xl md:text-4xl lg:text-5xl font-thin text-wrap text-center ">
            Meet the Masterminds Behind
          </h2>
          <h2 className="w-full md:w-5/6 lg:w-3/5 text-3xl md:text-4xl lg:text-5xl font-bold text-wrap text-center ">
            Pune Startup Fest
          </h2>
        </div>

        {/* secretaries */}
        <div className="flex flex-col lg:flex-row gap-4 items-center justify-center">
          <div className="flex flex-col gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-96 w-60 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${sec1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Deep Ghadia</p>
              <p className="text-base font-light">(Secretary)</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-96 w-60 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${sec2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Piyush Ladda</p>
              <p className="text-base font-light">(Joint Secretary)</p>
            </div>
          </div>
        </div>
      </div>

      {/* second page */}
      <div
        className="w-full md:w-5/6 lg:w-4/5 min-h-screen mt-4 bg-cover bg-center rounded-sm flex flex-col px-2"
        style={{
          backgroundImage: `url(${paper2})`,
        }}
      >
        {/* portfolios headline */}

        <h2 className="mt-4 text-center text-3xl font-medium">
          The Syndicate Leaders
        </h2>
        {/* horizontal line */}
        <div className="h-[2px] w-full bg-slate-900 " />

        {/* Accounts */}
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center lg:items-stretch">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Accounts & Documentation
            </h3>
            <p className="text-base font-light text-justify">
              Their responsibilities include managing the event’s budget,
              tracking expenses, and ensuring all transactions are properly
              recorded. They draft proposals and obtain necessary permissions
              from authorities. They coordinate with other teams to align
              financial matters, and after the event, handle post-event
              reporting, summarizing financial outcomes and documenting lessons
              for future improvements.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${acd2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Prathmesh Golhar</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${acd1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Preet Damani</p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* AOG */}
        <div className="flex flex-col lg:flex-row-reverse gap-4 justify-center items-center lg:items-stretch pb-4">
          <div className="flex flex-col max-w-96 justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Alumni Outreach Group
            </h3>
            <p className="text-base font-light text-justify">
              The Alumni Outreach Group is responsible for bringing funds and
              sponsorships from the esteemed alumni of our college. As a
              coordinator they form connections and networks with our alumnus
              from across the globe. Joining the AOG team will help them enhance
              your communication skills but also offer amazing networking
              opportunities with influential alumnus.
            </p>
          </div>

          <div className="flex flex-col gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${aog1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Siddhi Sukesh</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${aog2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Vedanti Watekar</p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* design and Vfx */}
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center lg:items-stretch pb-4">
          {/* design */}
          <div className="flex flex-col lg:flex-row-reverse gap-4">
            <div className="flex flex-col items-center justify-around ">
              <h3 className="font-bold text-4xl leading-none tracking-tight pt-4 text-center">
                Design and VFX
              </h3>
              <p className="text-base font-light text-justify  max-w-60">
                The Design & VFX team are the face of PSF. They are responsible
                for designing all the posters, social media posts, reels and
                videos for all the events around the fest. They make social
                media post, posters, reels and videos and apply different
                aspects of designing and video editing.
              </p>
            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center gap-4">
              <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
                <div
                  className="h-72 w-48 bg-white bg-cover bg-center"
                  style={{ backgroundImage: `url(${design1})` }}
                ></div>
                <div className="flex flex-col items-end gap-0 leading-none">
                  <p className="text-xl font-bold">Himanshu Prasad</p>
                  <p className="text-base font-light">(Design)</p>
                </div>
              </div>
            </div>
          </div>

          {/* vfx */}
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col lg:flex-row items-center justify-center gap-4">
              <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
                <div
                  className="h-72 w-48 bg-white bg-cover bg-center"
                  style={{ backgroundImage: `url(${vfx1})` }}
                ></div>
                <div className="flex flex-col items-end gap-0 leading-none">
                  <p className="text-xl font-bold">Yash R Pawar</p>
                  <p className="text-base font-light">(VFX)</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center gap-4">
              <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
                <div
                  className="h-72 w-48 bg-white bg-cover bg-center"
                  style={{ backgroundImage: `url(${vfx2})` }}
                ></div>
                <div className="flex flex-col items-end gap-0 leading-none">
                  <p className="text-xl font-bold">Soumyajit Das</p>
                  <p className="text-base font-light">(VFX)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third page */}
      <div
        className="w-full md:w-5/6 lg:w-4/5 min-h-screen mt-4 bg-cover bg-center rounded-sm flex flex-col px-2 pb-8"
        style={{
          backgroundImage: `url(${paper2})`,
        }}
      >
        {/* decor */}
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center lg:items-stretch">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Decor
            </h3>
            <p className="text-base font-light text-justify">
              When it comes to transforming the ordinary into the extraordinary,
              the Campus Decor team is unmatched. These creative maestros are
              the heartbeat of the fest, weaving the theme into every corner of
              the campus. From dazzling stages that leave you in awe to
              immersive setups that transport you to another world, they ensure
              the fest isn't just an event—it's an experience.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${decor1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Aditi Kadam</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${decor2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Vaishnavi Gaikwad</p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* E&N */}
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Events & Networking
            </h3>
            <p className="text-base font-light text-center w-full md:w-5/6 lg:w-4/5">
              The Events and Networking team is the ultimate bridge between
              ambition and opportunity by forging connections with startups,
              innovators, and dreamers to make the fest a hub of limitless
              potential. These visionaries aren't just planning events—they're
              crafting experiences that leave a mark. From adrenaline-packed
              showdowns to thought-provoking challenges, they ensure every event
              is a masterpiece.
            </p>
          </div>

          <div className="flex flex-col lg:flex-row items-center justify-center gap-4">
            <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
              <div
                className="h-72 w-48 bg-white bg-cover bg-center"
                style={{ backgroundImage: `url(${en3})` }}
              ></div>
              <div className="flex flex-col items-end gap-0 leading-none">
                <p className="text-xl font-bold">Samina Ali</p>
              </div>
            </div>

            <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
              <div
                className="h-72 w-48 bg-white bg-cover bg-center"
                style={{ backgroundImage: `url(${en2})` }}
              ></div>
              <div className="flex flex-col items-end gap-0 leading-none">
                <p className="text-xl font-bold">Yash M Pawar</p>
              </div>
            </div>

            <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
              <div
                className="h-72 w-48 bg-white bg-cover bg-center"
                style={{ backgroundImage: `url(${en1})` }}
              ></div>
              <div className="flex flex-col items-end gap-0 leading-none">
                <p className="text-xl font-bold">Shlok Chilatre</p>
              </div>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* Finance & Sponsorship */}
        <div className="flex flex-col lg:flex-row-reverse gap-4 justify-center items-center lg:items-stretch">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Finance & Sponsorship
            </h3>
            <p className="text-base font-light text-justify">
              The Finance and Marketing team works to ensure the event's
              financial support while driving brand visibility. They are
              responsible for managing budgets, negotiating with sponsors, and
              crafting marketing strategies that attract partners and promote
              the fest. This portfolio offers a unique opportunity to
              collaborate with industry leaders and refine essential skills in
              negotiation and financial management.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${fin1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Anushka Bidkar</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${fin2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Nilay Kotru</p>
            </div>
          </div>
        </div>
      </div>

      {/* fourth page */}
      <div
        className="w-full md:w-5/6 lg:w-4/5 min-h-screen mt-4 bg-cover bg-center rounded-sm flex flex-col px-2 pb-8"
        style={{
          backgroundImage: `url(${paper2})`,
        }}
      >
        {/* iipr  */}
        <div className="flex flex-col lg:flex-row gap-4 items-center lg:items-stretch justify-center">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Innovation & IPR
            </h3>
            <p className="text-base font-light text-justify">
              The IIPR team focuses on promoting innovation and protecting these
              ideas by the means of patents, trademarks and copyrights. We
              organize workshops and activities to spread awareness about
              Intellectual Property Rights (IPR) and guide students in topics
              like these. This year, we are collaborating with Viksit Bharat to
              drive progress towards making India a developed nation by 2047.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${iipr1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Aishani Limaye</p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* ir */}
        <div className="flex flex-col lg:flex-row-reverse gap-4 justify-center items-center lg:items-stretch">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Investor Relations
            </h3>
            <p className="text-base font-light text-justify">
              As the lifeline between the fest and the nation's top investors,
              this team takes on the thrilling challenge of building
              relationships that matter. With every call, meeting, and pitch,
              they bring unmatched energy to ensure investors see the brilliance
              of Pune Startup Fest. Beyond networking, they are the backbone of
              flagship events like Pitcher's Valley and Lightning Pitch, where
              groundbreaking ideas meet the capital to bring them to life
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${ir1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Akash Mestha</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${ir2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Sakshi Khanorkar </p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* marketing */}
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center lg:items-stretch">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Marketing
            </h3>
            <p className="text-base font-light text-justify">
              They are the heartbeat of Pune Startup Fest, the ones who ensure
              the world knows what's coming. The Publicity team is on a mission
              to make PSF the most talked-about event of the year. With a
              relentless drive and boundless creativity, they craft campaigns
              that captivate college students, school-goers, and corporate
              professionals alike. They don't just promote the fest—they create
              a movement. From strategic outreach to dynamic social media
              blitzes, they make sure no corner is left untouched.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${mar1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Atharv Kandharkar</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${mar3})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Sanika Jogipethkar</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${mar2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Atharva Khadloya </p>
            </div>
          </div>
        </div>
      </div>

      {/* fifith page */}
      <div
        className="w-full md:w-5/6 lg:w-4/5 min-h-screen mt-4 bg-cover bg-center rounded-sm flex flex-col px-2 pb-8"
        style={{
          backgroundImage: `url(${paper2})`,
        }}
      >
        {/* media  */}
        <div className="flex flex-col lg:flex-row-reverse gap-4 items-center lg:items-stretch justify-center p-4">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Media & Photography
            </h3>
            <p className="text-base font-light text-justify">
              They are the eyes and voice of Pune Startup Fest, crafting the
              narrative that defines the event. The Media & Photography team
              goes beyond managing digital platforms—they bring the fest to life
              with breathtaking visuals and compelling content. From behind the
              lens to the frontlines of creativity, they are the masterminds who
              grow and engage the audience like never before.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2  mt-4 rounded-xl ">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${media1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold"> Srushti Avhad</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2  mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${media2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Vedant Meshram </p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* operations */}
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Operations & Logistics
            </h3>
            <p className="text-base font-light text-justify w-full md:w-5/6 lg:w-3/5">
              The Operations and Logistics team is all about getting things
              done—efficiently and effectively. From managing infrastructure and
              handling purchases to coordinating sound systems and organizing
              refreshments, they're the ones behind every smooth operation.
              Their hard work and smart solutions keep everything running
              seamlessly, ensuring the fest is a success.{" "}
            </p>
          </div>

          <div className="flex flex-col lg:flex-row items-center justify-center gap-4">
            <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
              <div
                className="h-72 w-48 bg-white bg-cover bg-center"
                style={{ backgroundImage: `url(${ops2})` }}
              ></div>
              <div className="flex flex-col items-end gap-0 leading-none">
                <p className="text-xl font-bold">Shubham Dengane</p>
              </div>
            </div>

            <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
              <div
                className="h-72 w-48 bg-white bg-cover bg-center"
                style={{ backgroundImage: `url(${ops3})` }}
              ></div>
              <div className="flex flex-col items-end gap-0 leading-none">
                <p className="text-xl font-bold">Kaivalya Raut</p>
              </div>
            </div>

            <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
              <div
                className="h-72 w-48 bg-white bg-cover bg-center"
                style={{ backgroundImage: `url(${ops1})` }}
              ></div>
              <div className="flex flex-col items-end gap-0 leading-none">
                <p className="text-xl font-bold">Rana Deshmukh </p>
              </div>
            </div>
          </div>
          <p className="font-bold text-center mt-4">
            "They make it all happen, no matter the challenge!"
          </p>
        </div>
      </div>

      {/* sixth page */}
      <div
        className="w-full md:w-5/6 lg:w-4/5 min-h-screen mt-4 bg-cover bg-center rounded-sm flex flex-col px-2 pb-8"
        style={{
          backgroundImage: `url(${paper2})`,
        }}
      >
        {/* pr */}
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center lg:items-stretch mt-4">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Public Relations
            </h3>
            <p className="text-base font-light text-justify">
              The PR team is all about making powerful connections happen.
              They're responsible for inviting top-tier professionals—CEOs,
              CTOs, influencers, and speakers—to Pune Startup Fest, ensuring
              that each guest has a smooth and seamless experience. From
              reaching out via emails and LinkedIn to managing schedules,
              travel, and hospitality, the PR team ensures everything runs with
              out a hitch
            </p>
            <p className="font-bold text-center">
              "They make sure the best minds come to PSF, making it an event
              students can't miss!"
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${pr1})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Nehal Agrawal</p>
            </div>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-72 w-48 bg-white bg-cover bg-center"
              style={{ backgroundImage: `url(${pr2})` }}
            ></div>
            <div className="flex flex-col items-end gap-0 leading-none">
              <p className="text-xl font-bold">Soham Haldankar</p>
            </div>
          </div>
        </div>

        {/*  thin horizontal line */}
        <div className="h-[1px] w-full bg-slate-700 my-8" />

        {/* web */}
        <div className="flex flex-col lg:flex-row-reverse gap-4 justify-center items-center lg:items-stretch mt-4">
          <div className="flex flex-col max-w-96  justify-around">
            <h3 className="font-bold text-4xl leading-none tracking-tight pt-4">
              Web & Tech
            </h3>
            <p className="text-base font-light text-justify">
              The Portfolio Showcase team ensures everything runs smoothly
              during Pune Startup Fest's digital events. While they build
              easy-to-navigate websites, their main focus is on managing all
              tech operations during live sessions. Whether it's setting up for
              events or making sure everything runs smoothly behind the scenes,
              they are the ones ensuring the tech side of things is flawless.
            </p>
          </div>

          <div className="flex flex-col w-fit gap-2 p-2 ring-[1px] ring-slate-700 mt-4">
            <div
              className="h-60 w-96 bg-white bg-cover bg-top"
              style={{ backgroundImage: `url(${web_tech})` }}
            ></div>
            <div className="flex flex-row justify-between gap-0 leading-none">
              <p className="text-xl font-bold">Tejas Kolhe</p>
              <p className="text-xl font-bold">Abhijeet Jadhav</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
