import CustomChips from "../Mainpage/CustomChips";

export default function PastInvestorsPV({ event, pastInvestors }) {
  return (
    <div className="min-h-96 w-full flex flex-col gap-4">
      {/* Event Chip */}
      <div className="ml-32">
        <CustomChips text={event} />
      </div>

      {/* Investors List */}
      <div className="flex flex-row flex-wrap justify-center gap-4 md:gap-6 lg:gap-8 px-4 lg:px-8">
        {pastInvestors.map((investor, index) => (
          <div className="flex flex-col gap-1 w-40 md:w-50 lg:w-60 group">
            <div
              key={index}
              className="w-40 md:w-50 lg:w-60 h-48 md:h-60 lg:h-72 text-slate-200 font-alumni relative overflow-hidden bg-gradient-to-br from-[#455098] to-[#6671e6] bg-opacity-75 rounded-2xl ring-psfviolet ring-opacity-50 hover:ring-[1px]"
            >
              <div
                className="absolute bg-center bg-cover w-full h-full overflow-hidden ring-[1px] ring-psfdark transition-transform duration transform hover:scale-105"
                style={{ backgroundImage: `url(${investor[3]})` }}
              >
                <div className="absolute border-noneduration-200 hover:opacity-90 p-2 md:p-4 flex flex-col w-full h-full z-10 ">
                  <p className="font-bold text-base text-center md:text-xl leading-none">
                    {investor[0]}
                  </p>
                  {/* <p className="font-light text-xs md:text-sm">{investor[1]}</p> */}
                </div>
              </div>
            </div>

            <p className="font-normal text-sm md:text-base text-wrap text-center">
              {investor[1]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export function PastInvestorsLP({ event, pastInvestors }) {
  return (
    <div className="min-h-96 w-full flex flex-col gap-4">
      {/* Event Chip */}
      <div className="ml-32">
        <CustomChips text={event} />
      </div>

      {/* Investors List */}
      <div className="flex flex-row flex-wrap justify-center gap-4 md:gap-6 lg:gap-8 px-4 lg:px-8">
        {pastInvestors.map((investor, index) => (
          <div className="flex flex-col gap-1 w-40 md:w-50 lg:w-60 group">
            <div
              key={index}
              className="w-40 md:w-50 lg:w-60 h-48 md:h-60 lg:h-72 text-black font-alumni relative overflow-hidden bg-gradient-to-b from-[#7ec6fe] to-[#4492fb] bg-opacity-75 rounded-2xl ring-psfviolet ring-opacity-50 hover:ring-[1px]"
            >
              <div
                className="absolute bg-center bg-cover w-full h-full overflow-hidden ring-[1px] ring-psfdark transition-transform duration transform hover:scale-105"
                style={{ backgroundImage: `url(${investor[3]})` }}
              >
                <div className="absolute border-noneduration-200 hover:opacity-90 p-2 md:p-4 flex flex-col w-full h-full z-10 ">
                  <p className="font-bold text-base text-center md:text-xl leading-none">
                    {investor[0]}
                  </p>
                  {/* <p className="font-light text-xs md:text-sm">{investor[1]}</p> */}
                </div>
              </div>
            </div>

            <p className="font-normal text-sm md:text-base text-wrap text-center">
              {investor[1]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
