import React, { useEffect, useState } from "react";
import eventNotifications from "./eventNotifications.json"; // Path to your JSON file
import { db } from "../../firebase-config";  // Import Firebase Firestore functions
import { collection, getDocs } from "firebase/firestore";

const EventNotification = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(eventNotifications);
  }, []);

  const sendNotification = async (notification) => {
    try {
      
      const tokensCollection = collection(db, "EVENTS");
      const tokensSnapshot = await getDocs(tokensCollection);
      
      if (tokensSnapshot.empty) {
        alert("No FCM tokens found in the database!");
        return;
      }

      const tokens = tokensSnapshot.docs.map(doc => doc.data().token);

      for (let token of tokens) {
        const response = await fetch("https://psf25-deploy.onrender.com/sendNotification", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: notification.title,
            body: notification.body,
            token, // Send notification to one token at a time
          }),
        });
  
        const data = await response.json();
        if (response.ok) {
          console.log(`Notification sent successfully to token ${token}:`, data);
        } else {
          console.error(`Error sending notification to token ${token}:`, data);
          //alert("Failed to send notification to some users. See console for details.");
        }
      }      
  
    } catch (err) {
      console.error("Error sending notification:", err);
      //alert("An error occurred while sending the notification.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-gray-800">Event Notifications</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="bg-white p-6 shadow-lg rounded-lg border border-gray-200"
          >
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              {notification.title}
            </h2>
            <p className="text-gray-600 mb-4">{notification.body}</p>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={() => sendNotification(notification)}
            >
              Send Notification
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventNotification;
