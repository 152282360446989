import React from "react";

import Hero from "./Hero";
import EventsSections from "./EventsSections";
import Stats from "./Stats";
import Theme from "./Theme";
import Launchpad from "./Launchpad";
import Feedback from "./Feedback";
import Patronages from "./Patronages";
// import Quotes from "./Quotes";
import Masterclass from "./Masterclass";

import PastSpeakers from "./PastSpeakers";

import Testimonials from "./Testimonials";

import Schedule from "./Schedule";

export default function Mainpage() {
  return (
    <div className="w-full items-center justify-center flex flex-col gap-20 md:gap-40 pb-16">
      <Hero />
      
      <Schedule />
      <Stats />
      

      <Theme />
      <Patronages />

      <PastSpeakers />

      <div id="events">
        <EventsSections />
      </div>

      <Masterclass />
      <Launchpad />
      <Testimonials />
      {/* <Quotes /> */}
      <Feedback />
    </div>
  );
}
