import React, { useEffect, useState } from 'react';
import { app } from './../../firebase-config';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const db = getFirestore(app);

const StartupCards = () => {
  const [categories, setCategories] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    const fetchStartups = async () => {
      try {
        const startupsCollection = collection(db, 'LaunchpadStartups');
        const snapshot = await getDocs(startupsCollection);

        const categoriesData = [];
        for (const docSnap of snapshot.docs) {
          const categoryName = docSnap.id;
          const startups = docSnap.data();
          const startupList = Object.entries(startups)
            .map(([id, details]) => ({ id, ...details }))
            .sort((a, b) => a.id.localeCompare(b.id)); // Sort by startup ID
          categoriesData.push({ categoryName, startups: startupList });
        }
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching startups:', error);
      }
    };

    fetchStartups();
  }, []);

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="pt-24 p-8 bg-psfblack text-psfwhite font-psfsans">
      <h1 className="text-3xl font-bold mb-6 text-center text-psfviolet">Startup Expo LAUNCHPAD</h1>
      {categories.map((category) => (
        <div key={category.categoryName} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-psfviolet">{category.categoryName}</h2>
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-zinc-400`}>
            {category.startups.map((startup) => (
              <div
                key={startup.id}
                className={`p-6 rounded-lg overflow-y-auto bg-violet-700 bg-opacity-10 ring-[1px] ring-violet-500 ring-opacity-50`}
              >
                <h3 className={`text-xl font-bold mb-2 text-white`}>
                  {startup.stallNum}. {startup.name}
                </h3>
                <p className="mb-2 text-psfblack">
                  <span className="font-bold">Stall Number:</span>{' '}
                  {startup.stallNum || 'Not Assigned'}
                </p>
                <p className="mb-2 text-psfblack">
                  <span className="font-bold">Zone:</span>{' '}
                  {category.categoryName}
                </p>
                <p className="mb-2 text-psfblack">
                  <span className="font-bold">Founder:</span> {startup.founder}
                </p>
                <p className="mb-2 text-psfblack">
                  <span className="font-bold">Email:</span> {startup.email}
                </p>
                <p className="mb-2 text-psfblack">
                  <span className="font-bold">Description:</span>{' '}
                  {expandedDescriptions[startup.id] ? (
                    <>
                      {startup.description}{' '}
                      <button
                        className="text-blue-600 underline"
                        onClick={() => toggleDescription(startup.id)}
                      >
                        Show Less
                      </button>
                    </>
                  ) : (
                    <>
                      {startup.description.substring(0, 100)}
                      {startup.description.length > 100 && (
                        <>
                          ...{' '}
                          <button
                            className="text-blue-600 underline"
                            onClick={() => toggleDescription(startup.id)}
                          >
                            Read More
                          </button>
                        </>
                      )}
                    </>
                  )}
                </p>
                <p className="mb-2 text-psfblack">
                  <span className="font-bold">Website:</span>{' '}
                  <a
                    href={startup.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  >
                    {startup.website}
                  </a>
                </p>
                
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StartupCards;
