import CustomChips from "./CustomChips";
import { motion } from "framer-motion";
import { useState } from "react";

import TestimonialsCard from "./TestimonialsCard";

const testimonials_data = [
  {
    statement:
      "Aditya and team: this is a great event and fantasticly managed. Kudos to you and team.",
    author: "Santosh Kotnis",
    startupName: "SpringUp Capital",
  },
  {
    statement:
      "Grateful for the exceptional experience at Pune Startup Fest; immense thanks to the students, organizers, and everyone involved for their tireless efforts.",
    author: "Ashish Paradeshi",
    startupName: "AI Market Pro",
  },
  {
    statement:
      "Thank you team, fantastic work! It was delightful to talk to each of you, and the enthusiasm was engaging. Appreciate the support and all your help! All the best to each of you 🌼",
    author: "Deepa Bhowmick",
    startupName: "DaisyLife",
  },
  {
    statement:
      "Excellent event, and as usual very well organised... Looking forward to further interaction with this ecosystem.",
    author: "Vishal",
    startupName: "PCombinator & KanakDhara",
  },
  {
    statement:
      "Thank you so much for motivating the start-up founders. This was a much needed event in the journey of entrepreneurship.",
    author: "Swati Smart",
    startupName: "Dezi Jewellery",
  },
  {
    statement:
      "Thank you Aditya and team for organising the event so seamlessly at such a large scale. Also I can't fail to mention the assistance provided by both the organizers and volunteers was truly invaluable. We immensely enjoyed being a part of PSF'24. Congratulations on a successful event! 👏🏻",
    author: "Isha Syed",
    startupName: "Quantile AI Team",
  },
  {
    statement:
      "Thanks COEP and iCell team, It was really great and full of energy event. Management and hospitality really surpassed our expectations. Kudos to All team💐",
    author: "Nilesh Bakshe",
    startupName: "Varadom Technologies",
  },
  {
    statement:
      "Thank you guys. Great hosting. Amazing COEP team. Keep in touch 🙏🏻😃",
    author: "Ashwin Khatod",
    startupName: "RUBAB MEN",
  },
  {
    // this is unknown idk who made this
    statement:
      "Congratulations Events Head and the entire team for a very smoothly organised event.",
    author: " Sagar Gwalani Pettel",
    startupName: "Startup at PSF'24",
  },
  {
    statement:
      "We participated in such an event for the first time and it was a great learning experience. Looking forward to connecting with such innovative people & initiatives from COEP and team.",
    author: "Yash Raut",
    startupName: "Team SHSTRA",
  },
  {
    statement:
      "It was a wonderful opportunity connecting with all and creating awareness about our healthcare platform! Everyone was very helpful and friendly 💕 thanks much guys! Would love to stay in touch!",
    author: "Sharvari",
    startupName: "EasyHeals",
  },
  {
    statement:
      "Please accept our heartfelt thanks to each one of you for your dedication, hard work, and seamless coordination in organizing such a fantastic event.",
    author: "Pravin More",
    startupName: "Integs Cloud Technologies",
  },
  {
    statement:
      "I wanted to express my gratitude for organizing such a fantastic and seamlessly executed event. It provided us with the opportunity to network with influential individuals, fostering potentially fruitful connections for our endeavors.",
    author: "Krishna Badwane",
    startupName: "Indian Drivers",
  },
  {
    statement:
      "A huge shoutout and tons of thanks to the entire organizing team of Pune Startup Fest 2024. The experience could not be more perfect! We would look forward to come back to all future events organized by COEP team.",
    author: "Atanu Roy",
    startupName: "Clofio",
  },
  {
    statement:
      "Since the first interaction, I've found maturity in the conversation with CoEP volunteers than with many other universities. 10/10 to each volunteer for their hard work and continued efforts during the event.",
    author: "Abhishek Kotecha",
    startupName: "Datavtar",
  },
  {
    statement:
      "The event was really very well organized. The volunteers were helpful. Had a great time 😃 Thank you all 🙏",
    author: "Amit",
    startupName: "The Papaya Project",
  },
  {
    statement:
      "The event was fantastic, excellently managed, and truly spectacular! We received invitation from 3 Investors for further discussions. A big thank you to all the volunteers who contributed their time and effort! Special appreciation to Yash for his assistance, from registration to the end of the event.",
    author: "Sunil Gosavi",
    startupName: "The Indian Tea Qulture",
  },
  {
    statement:
      "It was a great initiative by COEP organizing this event. It's going to add value for every start-up participated in this event. Organizing team was really supportive and prompt in their response.",
    author: "Vishweshwar Joshi",
    startupName: "Startupsgurukul",
  },
  {
    statement:
      "I wanted to express my sincere gratitude for organizing the Start-Up Fest. It was a fantastic event, and Farmwala is truly grateful for the opportunity to be part of it. Thank you for creating such a valuable platform for collaboration and networking.",
    author: "Team Farmwala",
    startupName: "Farmwala",
  },
];

export default function Testimonials() {
  const testimonial_array = testimonials_data.concat(testimonials_data);
  const [isHovered, setIsHovered] = useState(false);

  // Define animation duration based on screen width
  const isMobile = window.innerWidth < 1200; // Adjust breakpoint as needed
  const duration = isMobile ? 200 : 100;

  return (
    <div className="flex flex-col items-center w-screen overflow-hidden gap-4 p-4">
      <CustomChips text={"Testimonials"} />

      <div className="font-psfsans text-3xl md:text-5xl font-bold my-2 bg-clip-text text-center text-transparent bg-gradient-to-r from-[#f1e496] via-[#f588a9] to-[#fce359]">
        Words That Define Us
      </div>

      <motion.div
        initial={{ x: 0 }}
        animate={{ x: isHovered ? 0 : "-50%" }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        transition={{
          duration: duration,
          repeat: Infinity,
          ease: "linear",
        }}
        className="flex flex-row items-start justify-stretch gap-4"
      >
        {testimonial_array.map((testimonial, index) => (
          <TestimonialsCard
            key={index}
            stmt={testimonial.statement}
            author={testimonial.author}
            startup={testimonial.startupName}
          />
        ))}
      </motion.div>
    </div>
  );
}
