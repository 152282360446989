import TimelineCard from "./TimelineCard";
import { TimelineThemeCard } from "./TimelineCard";

const timelineData = [
  {
    year: 2019,
    theme: "Manifesting Dreams",
    stmt: "",
    description:
      "The first-ever Pune Startup Fest was organized, setting the stage for years of innovation and collaboration.",
    startup_count: 110,
    investors: 70,
    internship_opportunities: 400,
    new_events: ["Shishya", "BidMaster", "BullRun", "BudgeIt", "Inquizate"],
    removed_events: [],
  },
  {
    year: 2020,
    theme: "Discovering the Known Unknowns",
    stmt: "",
    description:
      "The Unique Startup Expo, which provides an excellent platform for numerous students and Start-ups to connect with this ever growing entrepreneurial world.",
    startup_count: 125,
    investors: 100,
    internship_opportunities: 500,
    new_events: ["BrandScan"],
    removed_events: ["Shishya", "Inquizate"],
  },
  {
    year: 2021,
    theme: "Bolstering Innovation",
    stmt: "(A Blueprint for the Future)",
    description:
      "Pune Startup Fest'21 strives to foster the entrepreneurship culture among students and epitomize the power of innovation.",
    startup_count: 85,
    investors: 60,
    internship_opportunities: 400,
    new_events: ["BizTrivia"],
    removed_events: [],
  },
  {
    year: 2022,
    theme: "Caressing Ideas, Cultivating Innovation",
    stmt: "(Ascent of Entrepreneurial Renaissance)",
    description:
      "The first-ever Pune Startup Fest was organized, setting the stage for years of innovation and collaboration.",
    startup_count: 100,
    investors: 90,
    internship_opportunities: 400,
    new_events: ["The Imitation Game", "BizMeme"],
    removed_events: [],
  },
  {
    year: 2023,
    theme: "A Concerto Of Innovators",
    description:
      "The event was a resounding success and a testament to the vibrancy and diversity of the entrepreneurial community.",
    startup_count: 170,
    investors: 100,
    internship_opportunities: 800,
    new_events: [
      "The Pitchers Valley",
      "Monopoly Marathon",
      "Consultant's Quest",
      "BidMaster renamed to BidWars",
    ],
    removed_events: ["The Imitation Game", "Bizmeme"],
  },
  {
    year: 2024,
    theme: "Venturing the Innoverse",
    description:
      "This annual entrepreneurship fest has become a cornerstone for aspiring entrepreneurs, providing a platform to showcase their concepts to investors, mentors, students, and the public.",
    startup_count: 150,
    investors: 130,
    internship_opportunities: 900,
    new_events: ["Lightning Pitch", "Optify" , "BizWatch"],
    removed_events: ["Monopoly Marathon", "Consultant's Quest", "BizTrivia"],
  },
];

export default function Timeline_PSF() {
  return (
    <div className="w-full lg:w-4/5 px-4 overflow-hidden">
      <h1 className="w-full text-center mb-13 text-5xl lg:text-6xl font-bold font-alumni bg-clip-text text-transparent"
      style={{
        backgroundImage: 'linear-gradient(90deg, #74EBD5 0%, #9FACE6 70%)'
        }}
      >Timeline</h1>
      <div>
        {timelineData.map((data, index) => (
          <div key={index} className="flex flex-col gap-4 items-center">
            <div className="flex flex-row gap-4">
              <div className="min-h-96 hidden lg:block">
                <TimelineThemeCard
                  year={data.year}
                  theme={data.theme}
                  stmt={data.stmt}
                />
              </div>

              <div className="flex flex-col items-center ">
                <div
                  className="w-8 h-8 rounded-full bg-fixed bg-contain"
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg, rgba(55,18,98,1) 0%, rgba(125,85,161,1) 50%, rgba(7,3,16,1) 60%)",
                  }}
                />
                {index < timelineData.length - 1 && (
                  <div
                    className="min-h-96 h-max flex-1 w-[4px] my-4 bg-fixed bg-contain"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, rgba(55,18,98,1) 0%, rgba(125,85,161,1) 50%, rgba(7,3,16,1) 60%)",
                    }}
                  />
                )}
                {/* Don't show the connector after the last item */}
              </div>
              <div>
              <div className="min-h-12 block lg:hidden">
                <TimelineThemeCard
                  year={data.year}
                  theme={data.theme}
                  stmt={data.stmt}
                />
              </div>
                <TimelineCard data={data} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
