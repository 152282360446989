import { useRef } from "react";
import { useInView } from "framer-motion";

// Exporting `Partners` as the default export
export default function Partners({ image, partnership }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="flex flex-col gap-2"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    >
      <img src={image} alt="sponsor partner" className="h-40 md:h-40" />
      <p className="text-xl uppercase text-center font-alumni font-medium">
        {partnership}
      </p>
    </div>
  );
}

// Exporting `TitleSponsor` and `ExecutiveSponsor` as named exports
export function TitleSponsor({ image, partnership }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="flex flex-col gap-2"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    >
      <img src={image} alt="sponsor partner" className="h-40 md:h-64" />
      <p className="text-2xl uppercase text-center font-alumni font-semibold">
        {partnership}
      </p>
    </div>
  );
}

export function ExecutiveSponsor({ image, partnership }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="flex flex-col gap-2"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    >
      <img src={image} alt="sponsor partner" className="h-40 md:h-48" />
      <p className="text-2xl uppercase text-center font-alumni font-semibold ">
        {partnership}
      </p>
    </div>
  );
}

export function MediaSponsors({ image }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <img
      src={image}
      alt="media sponsor"
      className="h-40 md:h-52"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    />
  );
}

export function PastSponsor({ image }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <img
      src={image}
      alt="past sponsors"
      className="h-24 md:h-28"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    />
  );
}