import React from "react";

import EventsSectionsCards from "./EventsSectionsCards";
import CustomChips from "./CustomChips";

import optify_pic from "../../assets/events/optify.jpeg.webp";
import bizwatch_pic from "../../assets/events/bizwatch.jpeg.webp";
import bidwars_pic from "../../assets/events/bidwars.jpeg.webp";
import bullrun_pic from "../../assets/events/bullrun.jpeg.webp";
import budgeit_pic from "../../assets/events/budgeit.jpeg.webp";
import brandscan_pic from "../../assets/events/brandscan.jpeg.webp";
import CEO_challenge from "../../assets/events/CEO_challenge.jpeg.webp";
import Spectra from "../../assets/events/spectra_web.png.webp";
import Courtroom_clash from "../../assets/events/Courtroom_clash.jpeg.webp";

// Constant array for events data
const eventsData = [
  {
    color: "#6F41D2",
    title: "BizWatch",
    desc: "BizWatch is a dynamic and engaging business event that revolves around the theme of web series",
    pic: bizwatch_pic,
  },
  {
    color: "#ed4647",
    title: "Optify",
    desc: "Optify is a photography and reels making competition that challenges contestants to capture the essence of a theme in a single photo or reel",
    pic: optify_pic,
  },
  {
    color: "#d3ae37",
    title: "BidWars",
    desc: "If you love cricket, if you have followed IPL, and if you can mix common sense with passion into your bidding strategy, this event is a must for you.",
    pic: bidwars_pic,
  },
  {
    color: "#3cca97",
    title: "BullRun",
    desc: "Bull Run is basically a virtual stock market and like a stock market, the participant may buy/sell shares and invest in the stock market",
    pic: bullrun_pic,
  },
  {
    color: "#9e9e9e",
    title: "Budge-It",
    desc: "To manage your personal expenses or to organize the event, it’s the budget that guides you through the process. The budget is not just a collection of numbers, but an expression of our values and aspirations",
    pic: budgeit_pic,
  },
  {
    color: "#428eef",
    title: "BrandScan",
    desc: "Brandscan tests the knowledge of the participants in the fields of businesses logos, business personalities, and marketing done by brands.",
    pic: brandscan_pic,
  },
  {
    color: "#ff557f",
    title: "CEO Challenge",
    desc: "Do you have what it takes to step into the shoes of a CEO? Test your knowledge, analytical thinking, and decision-making skills in this thrilling two-round competition that will challenge your business acumen and strategic mindset.",
    pic: CEO_challenge,
  },
  {
    color: "#d48224",
    title: "Courtroom Clash",
    desc: "Courtroom clash is similar to the moot court. The competition will test participants' ability to argue and analyse a legal case based on Intellectual Property Rights (IPR)",
    pic: Courtroom_clash,
  },
  {
    color: "#a755c8",
    title: "Spectra",
    desc: "A B-Plan Competition. A platform to unleash your entrepreneurial ideas, present to investors, and compete for funding and recognition.",
    pic: Spectra,
  },
];

export default function EventsSections() {
  return (
    <div className="flex flex-col w-full items-center">
      <CustomChips text={"Events"} />
      <div className="flex flex-row flex-wrap justify-center w-full md:w-4/5 text-center text-3xl md:text-5xl font-psfsans font-medium m-2 gap-1 md:gap-2">
        <p className=" bg-clip-text my-2 text-transparent font-bold bg-gradient-to-r from-[#dcdcdc] via-[#7d60bb] to-[#dedede] uppercase">
          Dive into our exciting Events
        </p>
      </div>

      <div className="flex flex-wrap w-full justify-center p-2 md:p-4 gap-4 items-stretch -mt-2">
        {eventsData.map((event, index) => (
          <EventsSectionsCards
            key={index}
            color={event.color}
            title={event.title}
            desc={event.desc}
            pic={event.pic}
          />
        ))}
      </div>
    </div>
  );
}
