export default function ConnectCards({ position, mail }) {
  const handleEmailClick = () => {
    window.location.href = `mailto:${mail}`;
  };

  return (
    <button
      onClick={handleEmailClick}
      aria-label={`Send an email to ${mail}`}
      className="main bg-psfviolet rounded-lg flex flex-col items-center p-2 bg-opacity-15 ring-0 ring-psfviolet hover:ring-[1px] hover:bg-opacity-30"
    >
      <div className="flex flex-row gap-2 w-full items-center justify-center">
        <p className="font-light text-sm md:text-base">{position}</p>
      </div>
      <p className="text-lg font-medium">{mail}</p>
    </button>
  );
}

export function ConnectSec({ position, name, mail, phone }) {
  const handleEmailClick = () => {
    window.location.href = `mailto:${mail}`;
  };

  return (
    <button
      onClick={handleEmailClick}
      aria-label={`Send an email to ${mail}`}
      className="main bg-psfviolet rounded-lg flex flex-col items-center p-2 bg-opacity-15 ring-0 ring-psfviolet hover:ring-[1px] hover:bg-opacity-30"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-0 w-full items-center justify-center">
        <p className="text-center text-xl md:text-2xl font-semibold">{name}</p>
        <p className="text-center md:text-left md:pl-4 text-lg font-light italic">{phone}</p>
        <p className="text-center font-light text-lg italic md:text-base">
          {position}
        </p>
        <p className="text-center md:text-left md:pl-4 text-base md:text-lg font-medium">{mail}</p>
      </div>
    </button>
  );
}
