import { db } from '../../firebase-config';
import { collection, writeBatch, doc } from 'firebase/firestore';

const startups = [
  { name: "Startup 1", order: 1, active: false, completed: false },
  { name: "Startup 2", order: 2, active: false, completed: false },
  { name: "Startup 3", order: 3, active: false, completed: false },
  { name: "Startup 4", order: 4, active: false, completed: false },
  { name: "Startup 5", order: 5, active: false, completed: false },
  { name: "Startup 6", order: 6, active: false, completed: false },
  { name: "Startup 7", order: 7, active: false, completed: false },
  { name: "Startup 8", order: 8, active: false, completed: false },
  { name: "Startup 9", order: 9, active: false, completed: false },
  { name: "Startup 10", order: 10, active: false, completed: false },
  { name: "Startup 11", order: 11, active: false, completed: false },
  { name: "Startup 12", order: 12, active: false, completed: false },
];

export const seedStartups = async () => {
  const batch = writeBatch(db);
  
  startups.forEach((startup) => {
    const startupRef = doc(collection(db, 'lpstartups'));
    batch.set(startupRef, startup);
  });

  try {
    await batch.commit();
    console.log('Successfully added startups');
  } catch (error) {
    console.error('Error adding startups:', error);
  }
};