import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";

export default function EventsSectionsCards({ title, desc, color, pic }) {
  const navigate = useNavigate();
  return (
    <div className="bg-[#100A21] hover:bg-[#191133] p-4 w-5/6 md:w-3/8 lg:w-1/4 rounded-3xl ring-[1px] ring-[#6f41d244] group flex flex-col justify-between transition-transform duration-300">
      <div>
        {/* image div */}
        <div
          className="h-56 bg-center bg-cover bg-no-repeat rounded-xl p-4 mb-4 flex flex-col"
          style={{
            backgroundImage: `url(${pic})`,
          }}
        ></div>

        {/* title and description div */}
        <div className="font-bold text-xl text-white">{title}</div>
        <div className="font-light text-sm text-white">{desc}</div>
      </div>

      {/* button */}
      <button
        className="p-2 mt-4 mb-2 rounded-full group-hover:rounded-xl transition-transform duration-300 md:w-min flex items-start lg:w-full"
        style={{ backgroundColor: color }}
        onClick={() => navigate("/register/events")}
      >
        <div className="flex flex-row gap-1 w-full">
          <div className="group-hover:rotate-90 transition-transform duration-300 ">
            <ArrowOutwardIcon />
          </div>
          <p>Register</p>
        </div>
      </button>
    </div>
  );
}
