import CustomChips from "../Mainpage/CustomChips";

import { useRef } from "react";
import { useInView } from "framer-motion";

export default function AboutCards({ data }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="w-full flex flex-col items-center "
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateX(+50px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
      }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:w-4/5 items-stretch bg-[#100a21] ring-[1px] ring-psfviolet ring-opacity-30 rounded-3xl p-4 md:p-8">
        <div className="flex flex-col gap-4 px-1 md:px-4">
          <CustomChips text={data[0]} />
          <p className="font-masterclass text-2xl lg:text-4xl font-bold mb-4">
            {data[1]}
          </p>
          <p className="font-psfsans text-sm md:text-base lg:text-lg font-base">
            {data[2]}
          </p>
          <p className="font-psfsans text-sm md:text-base lg:text-lg font-base">
            {data[3]}
          </p>
          <p className="font-psfsans text-sm md:text-base lg:text-lg font-base">
            {data[4]}
          </p>
        </div>

        <div className="relative lg:h-full lg:w-full h-80 w-full rounded-b-2xl lg:rounded-r-3xl overflow-hidden">
          <div
            className="absolute lg:h-full lg:w-full h-80 w-full bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${data[5]})`,
            }}
          ></div>
          <div
            className="absolute h-full w-full hidden lg:block"
            style={{
              background:
                "linear-gradient(90deg, rgba(16,10,33,1) 0%, rgba(16,10,33,0) 100%)",
            }}
          ></div>

          <div
            className="absolute h-full w-full block lg:hidden"
            style={{
              background:
                "linear-gradient(180deg, rgba(16,10,33,1) 0%, rgba(16,10,33,0) 100%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export function Biec({ biecData }) {
  return (
    <div className="w-full p-2 flex flex-col items-center justify-center">
      <div className="w-full md:w-4/5 rounded-3xl h-fit pb-12  bg-opacity-15 bg-gradient-to-t from-[#2a2c87ab]  to-transparent flex flex-col gap-4 items-center pt-8">
        <CustomChips text={"BIEC"} />

        <p className="text-2xl md:text4xl lg:text-5xl font-psfsans font-bold w-full md:w-3/5 lg:w-2/5 text-center text-wrap bg-clip-text text-transparent bg-gradient-to-r from-[#9ee9fa] to-[#c14ef7]">
          {biecData[0]}
        </p>

        <p className="text-xl font-medium font-psfsans w-full md:w-2/5 text-center mt-8">
          {biecData[1]}
        </p>

        <div
          className="w-96 h-60 rounded-3xl bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${biecData[6]})`,
          }}
        ></div>

        <div className="flex flex-col items-center gap-4 w-11/12 md:w-4/5 lg:w-3/5 ">
          <p className="ring-[1px] rounded-2xl p-2 lg:p-4 text-base md:text-xl font-light font-psfsans text-left">
            {biecData[2]}
          </p>
          <p className="ring-[1px] rounded-2xl p-2 lg:p-4 text-base md:text-xl font-light font-psfsans text-left">
            {biecData[3]}
          </p>
          <p className="ring-[1px] rounded-2xl p-2 lg:p-4 text-base md:text-xl font-light font-psfsans text-left">
            {biecData[4]}
          </p>
        </div>
      </div>
    </div>
  );
}

export function Psf({ PsfData }) {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full md:w-4/5 h-fit rounded-3xl bg-gradient-to-b from-[#2a2c87ab]  to-transparent flex flex-col items-center py-16">
        <CustomChips text={PsfData[0]} />
        <p className="text-2xl md:text4xl lg:text-7xl font-psfsans font-bold w-full md:w-4/5 text-center text-wrap bg-clip-text text-transparent bg-gradient-to-r from-[#9ee9fa] to-[#c14ef7] mt-4">
          {PsfData[0]}
        </p>

        <div className="flex flex-col items-center gap-4 w-11/12 md:w-4/5 mt-8">
          <p className="rounded-2xl p-2 lg:p-4 text-base md:text-xl  font-medium text-left font-psfsans ring-[1px] ring-psfviolet">
            {PsfData[1]}
          </p>
          <div className="flex flex-col lg:flex-row gap-4">
            <div
              className="w-96 h-60 rounded-3xl bg-cover bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${PsfData[5]})`,
              }}
            ></div>
            <div
              className="w-96 h-60 rounded-3xl bg-cover bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${PsfData[4]})`,
              }}
            ></div>
            <div
              className="w-96 h-60 rounded-3xl bg-cover bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${PsfData[6]})`,
              }}
            ></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center  ">
            <p className="rounded-2xl p-2 lg:p-4 text-base md:text-xl font-light text-left font-psfsans ring-[1px] ring-psfviolet">
              {PsfData[2]}
            </p>
            <p className="rounded-2xl p-2 lg:p-4 text-base md:text-xl font-light text-left font-psfsans ring-[1px] ring-psfviolet">
              {PsfData[3]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PehchaanCard({ PehchaanData }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="w-full flex flex-col items-center "
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateX(+50px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
      }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:w-4/5 items-stretch bg-[#100a21] ring-[1px] ring-psfviolet ring-opacity-30 rounded-3xl p-4 md:p-8">
        <div className="flex flex-col gap-4 px-1 md:px-4">
          <CustomChips text={PehchaanData[0]} />

          <p className="font-psfsans text-2xl lg:text-4xl font-bold mb-2">
            {PehchaanData[0]}
          </p>

          <p className="font-psfsans text-sm md:text-base lg:text-lg font-base">
            {PehchaanData[1]}
          </p>
          <p className="font-psfsans text-sm md:text-base lg:text-lg font-base">
            {PehchaanData[2]}
          </p>
        </div>

        <div className="relative lg:h-full lg:w-full h-80 w-full rounded-b-2xl lg:rounded-r-3xl overflow-hidden">
          <div
            className="absolute lg:h-full lg:w-full h-80 w-full bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${PehchaanData[3]})`,
            }}
          ></div>
          <div
            className="absolute h-full w-full hidden lg:block"
            style={{
              background:
                "linear-gradient(90deg, rgba(16,10,33,1) 0%, rgba(16,10,33,0) 100%)",
            }}
          ></div>

          <div
            className="absolute h-full w-full block lg:hidden"
            style={{
              background:
                "linear-gradient(180deg, rgba(16,10,33,1) 0%, rgba(16,10,33,0) 100%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
