import React, { useState } from "react";
import { Check, Copy } from 'lucide-react';

const InternshipCard = ({ internship }) => {
  const {
    name,
    StartupName,
    DescriptionOfStartup,
    InternshipOpportunityURL,
    InternshipDomain,
    TenureOfInternship,
    ModeOfInternship,
    Location,
    Salary,
    Role,
    email,
  } = internship;

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500); // Reset after 1.5 seconds
  };

  return (
    <div className="bg-[#1A1A2E] text-white rounded-lg p-6 shadow-lg">
      {/* Header: Startup Name */}
      <h3 className="text-2xl font-semibold mb-3">{StartupName}</h3>

      {/* Internship Description */}
      <p className="text-sm text-gray-300 mb-4">{DescriptionOfStartup}</p>

      {/* Internship Details */}
      <div className="space-y-2 mb-4">
        <p className="text-sm">
          <span className="font-medium text-gray-400">Posted by:</span> {name}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">Domain:</span> {InternshipDomain}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">Tenure:</span> {TenureOfInternship}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">Mode:</span> {ModeOfInternship}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">Location:</span> {Location}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">Salary:</span> {Salary}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">Role:</span> {Role}
        </p>
        <p className="text-sm">
          <span className="font-medium text-gray-400">View Opportunity:</span>{" "}
          {InternshipOpportunityURL === "Working Offline" ? (
            <span className="text-blue-400">{InternshipOpportunityURL}</span>
          ) : (
            <a
              href={InternshipOpportunityURL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:underline"
            >
              {InternshipOpportunityURL}
            </a>
          )}
        </p>
        <p className="text-sm flex items-center">
          <span className="font-medium text-gray-400">Email:</span>{" "}
          <a href={`mailto:${email}`} className="text-blue-400 hover:underline ml-1">
            {email}
          </a>
          <button
            onClick={handleCopy}
            className="ml-2 text-white px-2 py-1 rounded text-xs"
          >
            {copied ? <Check size={18} /> : <Copy size={18} />}
          </button>
        </p>
      </div>
    </div>
  );
};

export default InternshipCard;
