import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import LaunchpadScreen from './LaunchpadScreen'; // Voting screen component
import { app } from '../../firebase-config';

const auth = getAuth(app);

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState('');

    // Handle Admin Login
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await setPersistence(auth, browserSessionPersistence); // Session persists until browser closes
            await signInWithEmailAndPassword(auth, email, password);
            setIsLoggedIn(true); // Mark as logged in
        } catch (err) {
            setError('Invalid email or password.');
        }
    };

    // Handle Logout
    const handleLogout = async () => {
        await signOut(auth);
        setIsLoggedIn(false);
    };

    // Render LaunchPadScreen if logged in
    if (isLoggedIn) {
        return (
            <div className="min-h-screen relative bg-psfwhite">
                 <button
                    onClick={handleLogout}
                    className="absolute top-4 right-4 px-4 py-2 text-psfwhite bg-psfviolet rounded-md transition hover:bg-psfblue"
                >
                    Logout
                </button>
                <div className="w-full h-full">
                    <LaunchpadScreen />
                </div>
            </div>
        );
    }

    // Render Login Form
    return (
        <div className="min-h-screen flex items-center justify-center bg-psfwhite">
            <div className=" p-8 space-y-6 rounded-lg shadow-lg border border-psfgrey">
                <h1 className="text-2xl font-semibold text-psfblue text-center">Admin Login LaunchPad</h1>
                <form onSubmit={handleLogin} className="space-y-4  text-black">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-psfblue focus:outline-none"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-psfblue focus:outline-none"
                    />
                    <button
                        type="submit"
                        className="w-full px-4 py-2  bg-psfviolet rounded-md transition"
                    >
                        Login
                    </button>
                </form>
                {error && <p className="text-psfviolet text-center text-sm">{error}</p>}
            </div>
        </div>
    );
};

export default AdminLogin;