import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../firebase-config';

const db = getFirestore(app);

const LaunchpadResult = () => {
  const [startups, setStartups] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All Categories');

  const fetchStartups = async () => {
    try {
      const categoriesSnapshot = await getDocs(collection(db, 'LaunchpadStartups'));
      const startupsData = [];

      for (const categoryDoc of categoriesSnapshot.docs) {
        const category = categoryDoc.id; // e.g., 'HealthCare' or 'Tech'
        const categoryData = categoryDoc.data();

        if (categoryData) {
          Object.keys(categoryData).forEach((startupID) => {
            const startupDetails = categoryData[startupID];
            startupsData.push({
              category,
              startupID,
              name: startupDetails.name,
              numVotes: startupDetails.numVotes,
            });
          });
        }
      }

      // Sort startups by votes in descending order
      startupsData.sort((a, b) => b.numVotes - a.numVotes);
      setStartups(startupsData);
    } catch (error) {
      console.error('Error fetching startups:', error);
    }
  };

  useEffect(() => {
    fetchStartups(); // Initial fetch

    const intervalId = setInterval(fetchStartups, 60000); // Refresh every 60 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Filter startups based on the selected category
  const filteredStartups =
    selectedCategory === 'All Categories'
      ? startups
      : startups.filter((startup) => startup.category === selectedCategory);

  return (
    <div className="bg-psfblack min-h-screen p-6">
      <h1 className="text-3xl font-psfserif bg-gradient-to-l from-psfblue to-psfviolet mb-6 text-center rounded-md">
        Startup Voting Results
      </h1>
      <div className="flex justify-center mb-6">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="p-2 rounded-md bg-psfwhite text-psfblack font-psfsans text-lg shadow-md shadow-psfblue hover:shadow-psfblack focus:outline-none focus:ring-0 focus:border-none transition-all duration-200 appearance-none"
        >
          <option value="All Categories">All Categories</option>
          {[...new Set(startups.map((s) => s.category))].map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className='overflow-x-auto'>
        <table className="min-w-full table-auto text-white text-center">
          <thead className="bg-psfblue">
            <tr>
              <th className="py-2 px-4">Startup ID</th>
              <th className="py-2 px-4">Startup Name</th>
              <th className="py-2 px-4">Category</th>
              <th className="py-2 px-4">Votes</th>
            </tr>
          </thead>
          <tbody>
            {filteredStartups.map((startup, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? 'bg-psfwhite' : 'bg-psfgrey'
                } border-b my-2`}
              >
                <td className=" px-4 py-2">{startup.startupID}</td>
                <td className=" px-4 py-2">{startup.name}</td>
                <td className=" px-4 py-2">{startup.category}</td>
                <td className=" px-4 py-2">{startup.numVotes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LaunchpadResult;
