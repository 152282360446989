import React from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { app } from '../../firebase-config';

const db = getFirestore(app);

const LaunchpadPopulate = () => {
    const populateDummyData = async () => {
        try {
            const dummyData = {
                Technology: {
                    s001: { name: 'Tech Innovators', numVotes: 0, voters: [] },
                    s002: { name: 'AI Pioneers', numVotes: 0, voters: [] },
                    s003: { name: 'Cloud Masters', numVotes: 0, voters: [] },
                },
                Healthcare: {
                    s011: { name: 'MediCare Plus', numVotes: 0, voters: [] },
                    s012: { name: 'HealthTrack', numVotes: 0, voters: [] },
                    s013: { name: 'Wellness World', numVotes: 0, voters: [] },
                },
                Education: {
                    s021: { name: 'EduWave', numVotes: 0, voters: [] },
                    s022: { name: 'LearnHub', numVotes: 0, voters: [] },
                    s023: { name: 'SkillTree', numVotes: 0, voters: [] },
                },
            };

            for (const [category, startups] of Object.entries(dummyData)) {
                const categoryRef = doc(db, 'LaunchpadStartups', category);
                await setDoc(categoryRef, startups);
            }

            console.log('Dummy data populated successfully!');
            alert('Dummy data populated successfully!');
        } catch (error) {
            console.error('Error populating dummy data:', error);
            alert('Error populating dummy data. Check the console for details.');
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Populate Database</h1>
            <button
                onClick={populateDummyData}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
                Populate Dummy Data
            </button>
        </div>
    );
};

export default LaunchpadPopulate;
