// importing logos
import { motion } from "framer-motion";
import bhau_logo from "../../assets/logo/E-Cell-Logo-white.png";
import psf_logo from "../../assets/logo/PSF25_white.png";
import coep_logo from "../../assets/logo/coep-tech-white.png";
import bhau_insti_logo from "../../assets/logo/bhau_insti_logo.png";

import h003 from "../../assets/hero/003.jpg.webp";
import h005 from "../../assets/hero/005.jpg.webp";
import h006 from "../../assets/hero/006.jpg.webp";
import h007 from "../../assets/hero/007.jpg.webp";
import h008 from "../../assets/hero/008.jpg.webp";
import h010 from "../../assets/hero/010.jpg.webp";
import h013 from "../../assets/hero/013.jpg.webp";
import h015 from "../../assets/hero/015.jpg.webp";
import h017 from "../../assets/hero/017.jpg.webp";
import h018 from "../../assets/hero/018.jpg.webp";
import h021 from "../../assets/hero/021.jpg.webp";
import h022 from "../../assets/hero/022.jpg.webp";
import h023 from "../../assets/hero/023.jpg.webp";
import h024 from "../../assets/hero/024.jpg.webp";
import h025 from "../../assets/hero/025.jpg.webp";
import h027 from "../../assets/hero/027.jpg.webp";
import h029 from "../../assets/hero/029.jpg.webp";
import h031 from "../../assets/hero/031.jpg.webp";
import h035 from "../../assets/hero/035.jpg.webp";
import h037 from "../../assets/hero/037.jpg.webp";
import h038 from "../../assets/hero/038.jpg.webp";
import h039 from "../../assets/hero/039.jpg.webp";
import h040 from "../../assets/hero/040.jpg.webp";
import h041 from "../../assets/hero/041.jpg.webp";
import h042 from "../../assets/hero/042.jpg.webp";
import h046 from "../../assets/hero/046.jpg.webp";
import h047 from "../../assets/hero/047.jpg.webp";
import h048 from "../../assets/hero/048.jpg.webp";
import h049 from "../../assets/hero/049.jpg.webp";
import h051 from "../../assets/hero/051.jpg.webp";
import h055 from "../../assets/hero/055.jpg.webp";

// Array of images
const row1 = [h003, h005, h006, h007, h008, h010, h013, h017];
const row2 = [h018, h021, h022, h023, h024, h025, h027];
const row3 = [h031, h035, h037, h038, h039, h040, h041, h042];
const row4 = [h046, h047, h048, h049, h051, h055, h029, h015];

export default function Hero() {
  // Helper function to render images dynamically
  const renderImageCards = (imageArray) => {
    return imageArray.map((image, index) => (
      <div
        key={index}
        className="w-96 bg-cover bg-center bg-opacity-30 rounded-xl"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    ));
  };

  return (
    <div
      data-scroll
      data-scroll-speed="-0.2"
      className="relative h-screen w-screen  block mt-20  overflow-hidden bg-[#070310]"
    >
      <div className="absolute grid grid-rows-4 w-fit gap-4  h-full overflow-hidden ">
        {/* Row 1 */}
        <motion.div
          className="-ml-40 flex flex-row gap-4 overflow-hidden"
          initial={{ x: 0 }}
          animate={{ x: "-50%" }}
          transition={{ duration: 40, repeat: Infinity, ease: "linear" }}
        >
          {renderImageCards(row1.concat(row1))}
        </motion.div>

        {/* Row 2 - Reverse images for variety */}
        <motion.div
          className="-ml-40 flex flex-row gap-4"
          initial={{ x: "-50%" }}
          animate={{ x: 0 }}
          transition={{ duration: 48, repeat: Infinity, ease: "linear" }}
        >
          {renderImageCards(row2.concat(row2))}
        </motion.div>

        {/* Row 3 - Reuse images */}
        <motion.div
          className="-ml-40 flex flex-row gap-4"
          initial={{ x: 0 }}
          animate={{ x: "-50%" }}
          transition={{ duration: 48, repeat: Infinity, ease: "linear" }}
        >
          {renderImageCards(row3.concat(row3))}
        </motion.div>

        {/* Row 4 - Alternate order */}
        <motion.div
          className="-ml-40 flex flex-row gap-4"
          initial={{ x: "-50%" }}
          animate={{ x: 0 }}
          transition={{ duration: 40, repeat: Infinity, ease: "linear" }}
        >
          {renderImageCards(row4.concat(row4))}
        </motion.div>
      </div>

      {/* aesthetic gradient */}
      <div className="absolute z-10 w-full h-full bg-[#070310] bg-opacity-75 bg-cover"></div>

      {/* top hero section */}
      <div className="absolute z-20 flex flex-col justify-center items-center w-full h-full gap-16 -mt-16 backdrop-saturate-50">
        <motion.div
          className="flex w-fit flex-col items-stretch bg-clip-text text-transparent bg-gradient-to-br from-[#B721FF]  to-[#21D4FD] rounded-3xl mt-8"
          initial={{ scale: 1.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 3, ease: [0.22, 1, 0.36, 1] }}
        >
          <p className="w-full text-left text-6xl md:text-8xl  font-semibold font-samarkan leading-none  -mb-1 lg:mb-4">
            Pune
          </p>
          <p className="w-full uppercase text-center text-7xl lg:text-8xl  font-black tracking-tighter font-psfsans leading-none -my-2 lg:-my-4">
            Startup
          </p>
          <p className="w-full lowercase text-right text-6xl md:text-8xl font-bold font-masterclass leading-none italic -mt-1 lg:-mt-2">
            Fest
          </p>
        </motion.div>

        <div className="flex flex-col items-center justify-center gap-4">
          <div className="flex flex-row items-center gap-4 mt-4">
            <img
              src={coep_logo}
              alt="coep tech logo"
              className="w-16 md:w-24"
            />
            <img
              src={bhau_logo}
              alt="coep tech logo"
              className="w-16 md:w-24"
            />
            <img src={psf_logo} alt="coep tech logo" className="w-16 md:w-24" />
            <img
              src={bhau_insti_logo}
              alt="coep tech logo"
              className="w-16 md:w-24"
            />
          </div>

          <a href="/register/visitors">
            <motion.button
              whileTap={{ scaleX: 1.2 }}
              whileHover={{ scale: 1.1 }}
              className="z-30 backdrop-blur-3xl hover:bg-white  hover:text-black w-40 h-16 rounded-2xl ring-[1px] ring-psfviolet transition-colors duration-300 transform text-2xl text-center font-light font-psfsans"
            >
              Register
            </motion.button>
          </a>
        </div>
      </div>
    </div>
  );
}
