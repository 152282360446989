import React from "react";
import { Sparkles, Calendar, Clock, ArrowRight } from "lucide-react";
import manish from "../../assets/registration/masterclass/manish.webp";
import vinayak from "../../assets/registration/masterclass/vinayak.webp";
import pooja from "../../assets/registration/masterclass/pooja.webp";
import harsh from "../../assets/registration/masterclass/harsh.webp";
import saptarshi from "../../assets/registration/masterclass/saptarshi.webp";
import teja from "../../assets/registration/masterclass/teja.webp";

const MasterclassGrid = () => {
  const masterclasses = [
    {
      title: "Creator's Blueprint",
      instructor: "Manish Pandey",
      date: "11th January 2025",
      time: "11:45 AM Onwards",
      description:
        "Learn how to build your unique creator brand and make an impact.",
      image: manish,
      link: "https://unstop.com/p/masterclass-manish-pandey-coep-technological-university-pune-maharashtra-1297495?lb=vOs0McA",
    },
    {
      title: "Data Defence",
      instructor: "Vinayak Godse",
      date: "11th January 2025",
      time: "1:45 PM Onwards",
      description:
        "Understand the core principles of data protection and security.",
      image: vinayak,
      link: "https://unstop.com/p/masterclass-vinayak-godse-coep-technological-university-pune-maharashtra-1295552?lb=vOs0McA",
    },
    {
      title: "Startup 101",
      instructor: "Pooja Shah",
      date: "11th January 2025",
      time: "3:15 PM Onwards",
      description:
        "Learn the basics of launching and managing a successful startup.",
      image: pooja,
      link: "https://unstop.com/p/masterclass-pooja-shah-coep-technological-university-pune-maharashtra-1295595?lb=vOs0McA",
    },
    {
      title: "Stock Talks",
      instructor: "Harsh Goela",
      date: "12th January 2025",
      time: "10:15 AM Onwards",
      description:
        "Master the art of investing in stocks and financial markets.",
      image: harsh,
      link: "https://unstop.com/p/masterclass-harsh-goela-coep-technological-university-pune-maharashtra-1295509?lb=vOs0McA",
    },
    {
      title: "Design Decoded",
      instructor: "Saptarshi Prakash",
      date: "12th January 2025",
      time: "12:45 PM Onwards",
      description: "Explore advanced design principles and techniques.",
      image: saptarshi,
      link: "https://unstop.com/p/masterclass-saptarshi-prakash-coep-technological-university-pune-maharashtra-1295498?lb=vOs0McA",
    },
    {
      title: "LinkedIn Amplified",
      instructor: "Teja Gudluru",
      date: "12th January 2025",
      time: "2:15 PM Onwards",
      description:
        "Optimize your LinkedIn profile to unlock networking opportunities.",
      image: teja,
      link: "https://unstop.com/p/masterclass-teja-gudluru-coep-technological-university-pune-maharashtra-1297825?lb=vOs0McA",
    },
  ];

  return (
    <div className="min-h-screen  p-6">
      <h1 className="text-4xl font-bold text-center mb-12 text-white tracking-wider">
        Upcoming Masterclasses
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
        {masterclasses.map((masterclass, index) => (
          <div
            key={index}
            className="group relative bg-white rounded-xl overflow-hidden hover:shadow-2xl transition-all duration-300 hover:-translate-y-1"
          >
            {/* Image Container */}
            <div className="relative h-64 w-full overflow-hidden">
              <img
                src={masterclass.image}
                alt={masterclass.title}
                className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/40 to-transparent" />
            </div>

            {/* Content Container */}
            <div className="p-6">
              <div className="absolute top-2 right-2">
                <Sparkles className="w-6 h-6 text-purple-400 opacity-50" />
              </div>

              <h3 className="text-xl font-bold text-gray-800 mb-2">
                {masterclass.title}
              </h3>
              <p className="text-gray-600 mb-4">{masterclass.instructor}</p>

              <div className="flex items-center gap-4 text-sm text-gray-500 mb-6">
                <div className="flex items-center gap-1">
                  <Calendar className="w-4 h-4" />
                  <span>{masterclass.date}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  <span>{masterclass.time}</span>
                </div>
              </div>

              <a
                href={masterclass.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full bg-gradient-to-br from-[#B721FF]  to-[#2d21fd] text-white py-2 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center gap-2 group"
              >
                Enroll Now
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MasterclassGrid;
