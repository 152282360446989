import Feedback_bg from "../../assets/feedback_bg.png";
import Feedback_bg_bg from "../../assets/feedback_bg_bg.png";

import { useRef } from "react";
import { useInView } from "framer-motion";

import React, { useState } from "react";

export default function Feedback() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [query, setQuery] = useState("");

  const handleSubmit = () => {
    // Validate input to ensure it's not empty
    if (query.trim() === "") {
      alert("Please enter a query before submitting.");
      return;
    }

    // Construct mailto link
    const mailtoLink = `mailto:queries.psf@gmail.com?subject=PSF%20Query&body=${encodeURIComponent(
      query
    )}`;

    // Open default email client
    window.location.href = mailtoLink;

    // Clear input after submission
    setQuery("");
  };

  return (
    <div
      className="feedback flex flex-col gap-2 md:gap-4 w-full items-center p-4 bg-contain bg-center mb-4"
      ref={ref}
      style={{
        backgroundImage: `url(${Feedback_bg_bg})`,
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    >
      <img src={Feedback_bg} className="-mb-36 md:-mb-72" alt="Feedback Icon" />
      <p className="text-3xl md:text-5xl font-semibold">Your Feedback is</p>
      <p className="text-3xl md:text-5xl font-semibold">Valuable</p>
      <p className="text-sm md:text-lg lg:text-xl text-zinc-200 w-5/6 md:w-4/5 lg:w-2/5 text-center">
        Let us know how we can make your experience with us even better.
      </p>

      {/* input area */}
      <div className="bg-[#fbfbfb] w-5/6 md:w-3/4 lg:w-1/3 h-12 md:h-16 rounded-xl flex flex-row p-2 gap-2">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
          className="bg-transparent text-zinc-800 flex-grow rounded-lg p-2 outline-none focus:ring-2 focus:ring-psfviolet"
        />
        <button
          onClick={handleSubmit}
          className="w-16 md:w-32 h-8 md:h-12 bg-psfviolet rounded-lg text-sm md:text-base text-white hover:bg-opacity-90 transition-colors duration-300"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
