import CustomChips from "./CustomChips";
import ORG_logo from "../../assets/logo/organisers.png"
export default function Organisers() {
  return (
    <div className="flex flex-col w-full items-center">
      <CustomChips text={"Organisers"} />

      <img src={ORG_logo} alt="Organisers" className="w-96" />

      <p className="text-xl font-medium text-center">COEP Technological University</p>
      <p className="text-xl font-medium text-center">BHAU's Innovation & Entrepreneurship Cell</p>


    </div>
  );
}
