import React, { useState } from "react";
import { Rocket, ArrowRight, AlertCircle } from "lucide-react";

const LaunchpadForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    startupName: "",
    description: "",
    address: "",
    website: "",
    startupZone: "",
    founderName: "",
    contactNumber: "",
    altContactNumber: "",
    fundingRound: "",
    businessModel: "",
    linkedinProfile: "",
    reference: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Startup Registration Form Submission:", formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const fundingRounds = [
    "Bootstrapped",
    "Pre-Seed",
    "Seed",
    "Series A",
    "Series B",
    "Series C",
    "Other",
  ];
  const businessModels = ["B2B", "B2C", "D2C", "Other"];

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-transparent lg:p-6">
      <div className="flex items-center gap-3 mb-4">
        <Rocket className="w-8 h-8 text-indigo-600" />

        <h1 className="text-4xl font-bold text-white-900 text-center">
          Startup Registration
        </h1>
      </div>

      <div className="text-red-500 text-sm md:text-base  flex items-center  lg:m-6">
        <AlertCircle size={16} className="mr-2" />
        Registrations closed for PSF'25
      </div>

      <div className="w-full max-w-full md:w-11/12 lg:w-3/5 flex flex-col lg:flex-row bg-slate-50 rounded-2xl overflow-hidden border-2 border-slate-100">
        {/* Form Section */}
        <div className="w-full p-4 md:p-8 lg:p-12 bg-white">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Email *
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              />
            </div>

            {/* Startup Name */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Start-Up Name *
              </label>
              <input
                type="text"
                name="startupName"
                value={formData.startupName}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              />
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Description of Start-Up *
              </label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white min-h-[100px]"
                placeholder="Provide a brief description..."
                maxLength={500}
                required
              />
            </div>

            {/* Address */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Address *
              </label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white min-h-[100px]"
                placeholder="Enter your address..."
                required
              />
            </div>

            {/* Website */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Website *
              </label>
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                placeholder="https://example.com"
                required
              />
            </div>

            {/* Startup Zone */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Start-Up Zone *
              </label>
              <input
                type="text"
                name="startupZone"
                value={formData.startupZone}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              />
            </div>

            {/* Founder Name */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Name of Founder *
              </label>
              <input
                type="text"
                name="founderName"
                value={formData.founderName}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              />
            </div>

            {/* Contact Numbers */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Contact Number *
                </label>
                <input
                  type="tel"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Alternative Contact Number
                </label>
                <input
                  type="tel"
                  name="altContactNumber"
                  value={formData.altContactNumber}
                  onChange={handleChange}
                  className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                />
              </div>
            </div>

            {/* Funding Round */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Current Funding Round *
              </label>
              <select
                name="fundingRound"
                value={formData.fundingRound}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              >
                <option value="">Select Funding Round</option>
                {fundingRounds.map((round) => (
                  <option key={round} value={round}>
                    {round}
                  </option>
                ))}
              </select>
            </div>

            {/* Business Model */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Business Model *
              </label>
              <select
                name="businessModel"
                value={formData.businessModel}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              >
                <option value="">Select Business Model</option>
                {businessModels.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>

            {/* LinkedIn Profile */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                LinkedIn Profile *
              </label>
              <input
                type="url"
                name="linkedinProfile"
                value={formData.linkedinProfile}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
                required
              />
            </div>

            {/* Reference */}
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-2">
                Reference
              </label>
              <input
                type="text"
                name="reference"
                value={formData.reference}
                onChange={handleChange}
                className="block w-full px-4 py-3 text-slate-900 border border-slate-200 rounded-xl focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 bg-white"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-gradient-to-br from-[#B721FF]  to-[#2d21fd] text-white mt-8 py-4 px-6 rounded-xl font-medium hover:bg-indigo-700 transition-colors flex items-center justify-center group"
            >
              Submit Application
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LaunchpadForm;
