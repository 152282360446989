import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MasonryGrid from "./MasonryGrid";
import AboutUs from "./About";
import Team from "./Team";
import TimelinePSF from "./Timeline_PSF";
import Brandscan from "./Brandscan";
import Wordsearch from "./Wordsearch";
import IprBlog from "./IprBlog";
import InternshipList from "../InternshipCard/InternshipList";
import StartupCards from "./StartupCards";
import Live from "./liveEvents";
//
// const links = [
//   { name: "Gallery", section: "gallery" },
//   { name: "Team", section: "team" },
//   { name: "Timeline", section: "timeline" },
//   { name: "About Us", section: "about" },
//   { name: "Brandscan", section: "brandscan" },
//   { name: "Wordsearch", section: "wordsearch" },
//   { name: "IIPR Blog", section: "iiprblog" },
//   { name: "Contact", section: "contact" },
//   { name: "Events", section: "events" },
// ];
//

export default function Explore({ section = "gallery" }) {
  const [selectedOption, setSelectedOption] = useState(section); // Initialize with the prop value
  const navigate = useNavigate(); // React Router navigation hook

  // Update the state if the `section` prop changes
  useEffect(() => {
    setSelectedOption(section);
  }, [section]);

  useEffect(() => {
    // Redirect to main page and add hash for target section
    if (selectedOption === "contact") {
      navigate("/#contact", { replace: true });
    } else if (selectedOption === "events") {
      navigate("/#events", { replace: true });
    }
  }, [selectedOption, navigate]);

  return (
    <div className="py-32 flex flex-col items-center">
      {selectedOption === "StartupExpo" && <StartupCards />}
      {selectedOption === "timeline" && <TimelinePSF />}
      {selectedOption === "team" && <Team />}
      {selectedOption === "about" && <AboutUs />}
      {selectedOption === "iiprblog" && <IprBlog />}
      {selectedOption === "LiveEvents" && <Live />}
      {selectedOption === "Internships" && <InternshipList />}
      {selectedOption === "wordsearch" && <Wordsearch />}
      {selectedOption === "gallery" && <MasonryGrid />}
      {selectedOption === "brandscan" && <Brandscan />}
    </div>
  );
}
