import { useRef } from "react";
import { useInView } from "framer-motion";

export function PlateBronze({ name }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
      className=" font-alumni font-semibold -tracking-tight text-center text-xl lg:text-2xl  p-2 md:p-4"
    >
      {name}
    </div>
  );
}

export function PlateGeneral({ name }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
      className=" font-alumni font-semibold -tracking-tight text-center text-xl lg:text-2xl  p-2 md:p-4"
    >
      {name}
    </div>
  );
}

export function PlateGold({ name }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
      className=" font-alumni font-semibold text-center -tracking-tight text-3xl lg:text-5xl  p-2 md:p-4"
    >
      {name}
    </div>
  );
}

export function PlatePlatinum({ name }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
      className="font-alumni font-semibold text-center -tracking-tight text-3xl md:text-4xl lg:text-6xl  p-2 md:p-4"
    >
      {name}
    </div>
  );
}

export function PlateSilver({ name }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
      className=" font-alumni font-semibold -tracking-tight text-center text-2xl md:text-4xl  p-2 md:p-4"
    >
      {name}
    </div>
  );
}
