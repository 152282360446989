import React, { useState, useEffect } from 'react';
import { getFirestore, collection, doc, onSnapshot, query, updateDoc, getDoc, getDocs } from 'firebase/firestore';
import { app } from '../../firebase-config';

const db = getFirestore(app);

const LaunchpadScreen = () => {
    const [startups, setStartups] = useState([]);
    const [feedbackMessage, setFeedbackMessage] = useState('');


    // Fetch Startups from Firestore
    useEffect(() => {
        const fetchStartups = async () => {
            try {
                const categoriesSnapshot = await getDocs(collection(db, 'LaunchpadStartups'));
                const startupsData = [];

                for (const categoryDoc of categoriesSnapshot.docs) {
                    const category = categoryDoc.id;
                    const categoryRef = doc(db, 'LaunchpadStartups', category);
                    const categoryData = (await getDoc(categoryRef)).data();

                    if (categoryData) {
                        Object.keys(categoryData).forEach((startupID) => {
                            const startupDetails = categoryData[startupID];
                            startupsData.push({
                                category,
                                startupID,
                                name: startupDetails.name,
                                numVotes: startupDetails.numVotes,
                            });
                        });
                    }
                }
                setStartups(startupsData);
            } catch (error) {
                console.error('Error fetching startups:', error);
            }
        };

        fetchStartups();
    }, []);

    // Handle Votes
    const handleVote = async (category, startupID) => {
        try {
            const categoryRef = doc(db, 'LaunchpadStartups', category);
            const startup = startups.find(
                (s) => s.category === category && s.startupID === startupID
            );

            if (!startup) return;

            // Increment Votes
            await updateDoc(categoryRef, {
                [`${startupID}.numVotes`]: startup.numVotes + 1,
            });

            // Update Local State
            setStartups((prevStartups) =>
                prevStartups.map((s) =>
                    s.startupID === startupID
                        ? { ...s, numVotes: s.numVotes + 1 }
                        : s
                )
            );

            setFeedbackMessage('Your vote has been successfully registered'); //vote successful message

            // Clear feedback message after 3 seconds
            setTimeout(() => {
                setFeedbackMessage('');
            }, 2000); // Message disappears after 3 seconds
        } catch (error) {
            console.error('Error updating vote:', error);
            setFeedbackMessage('Failed to register your vote. Please try again.');

            // Clear feedback message after 3 seconds
            setTimeout(() => {
                setFeedbackMessage('');
            }, 2000); // Message disappears after 3 seconds
        }
    };

    const categories = [...new Set(startups.map((s) => s.category))];

    return (
        <div>
            <div className="bg-psfblack min-h-screen p-6 w-full">
                <h1 className="text-3xl font-psfserif bg-gradient-to-l from-psfblue to-psfviolet mb-6 text-center rounded-md">
                    Startup Zone Voting
                </h1>

                {/* Show feedback message */}
                {feedbackMessage && (
                    <div className="text-center text-psfwhite mb-6">
                        <p>{feedbackMessage}</p>
                    </div>
                )}

                {categories.map((category) => (
                    <div key={category} className="mb-8">
                        <h2 className="py-4 text-2xl font-psfserif text-psfwhite mb-4 inline rounded-full underline underline-offset-4">
                            {category}
                        </h2>
                        <div className="flex gap-8 flex-wrap my-4">
                            {startups
                                .filter((startup) => startup.category === category)
                                .map((startup) => (
                                    <div
                                        key={startup.startupID}
                                        className="shadow-lg border-2 rounded-md p-4 w-full sm:w-1/4 lg:w-1/5 border-psfgrey hover:shadow-psfblue hover:border-psfblue"
                                    >
                                        <h3 className="text-xl font-psfsans font-medium text-psfwhite">
                                            {startup.name}
                                        </h3>
                                        <p className="text-psfwhite">Startup ID: {startup.startupID}</p>
                                        <p className="text-psfwhite mb-4">Votes: {startup.numVotes}</p>
                                        <button
                                            onClick={() => handleVote(startup.category, startup.startupID)}
                                            className="text-psfwhite border-2 px-4 py-2 rounded-md border-psfgrey hover:border-psfblue hover:shadow-md hover:bg-psfblue hover:text-psfwhite transition duration-100"
                                        >
                                            Vote
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LaunchpadScreen;