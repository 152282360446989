import day1 from "../../assets/Shedule/day1.jpeg.webp"
import day2 from "../../assets/Shedule/day2.jpeg.webp"

export default function Schedule() {
  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 mt-24 w-screen px-1 lg:-mb-24 md:w-4/5 gap-8 min-h-screen items-center justify-center'>
        <img src={day1} alt="schedule" className="w-full min-h-96 w-96"/>
        <img src={day2} alt="schedule" className="w-full min-h-96 w-96"/>

    </div>
  );
}
