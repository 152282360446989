import React, { useState, useRef } from "react";
import { User, Mail, Phone, Users, AlertCircle, CheckCircle, XCircle } from "lucide-react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

import date_logo from "../../assets/registration/events/Asset 2.webp";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyAkdogXk5YhIEtCts_9R0o3opwN8LJetIE",
  authDomain: "psf25-820b8.firebaseapp.com",
  projectId: "psf25-820b8",
  storageBucket: "psf25-820b8.firebasestorage.app",
  messagingSenderId: "240526482890",
  appId: "1:240526482890:web:e95a39d8072c28194b8333",
  measurementId: "G-C5SEFJT22W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const RegistrationForm = () => {
  const [focused, setFocused] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    members: "",
    personType: "",
  });
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState(null);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const membersRef = useRef(null);

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!emailRegex.test(formData.email))
      newErrors.email = "Enter a valid email address.";
    if (!/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Enter a valid 10-digit phone number.";
    if (!formData.members)
      newErrors.members = "Please select the number of members.";
    if (!formData.personType)
      newErrors.personType = "Please select your person type.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const userDocRef = doc(collection(db, "UserData"));
        await setDoc(userDocRef, formData);
        
        // Show success notification
        setNotification({
          type: 'success',
          message: 'Registration successful! Thank you for registering for Pune Start-Up Fest.'
        });
        
        // Reset form
        setFormData({ name: "", email: "", phone: "", members: "", personType: "" });
        setErrors({});
        
        // Auto-hide notification after 5 seconds
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      } catch (error) {
        console.error("Error adding document: ", error);
        
        // Show error notification
        setNotification({
          type: 'error',
          message: 'Registration failed. Please try again or contact support.'
        });
        
        // Auto-hide notification after 5 seconds
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      }
    }
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleKeyPress = (e, nextRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      nextRef?.current?.focus();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {/* Notification Component */}
      {notification && (
        <div 
          className={`fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg max-w-md transition-all duration-300 transform ${
            notification.type === 'success' 
              ? 'bg-gradient-to-r from-green-500/90 to-emerald-500/90' 
              : 'bg-gradient-to-r from-red-500/90 to-rose-500/90'
          } text-white backdrop-blur-sm`}
        >
          <div className="flex items-center space-x-3">
            {notification.type === 'success' ? (
              <CheckCircle className="h-6 w-6 flex-shrink-0" />
            ) : (
              <XCircle className="h-6 w-6 flex-shrink-0" />
            )}
            <p className="text-sm font-psfsans">{notification.message}</p>
            <button 
              onClick={() => setNotification(null)}
              className="ml-auto flex-shrink-0 text-white/80 hover:text-white transition-colors"
            >
              <XCircle className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      <div
        className="flex items-center justify-center mt-8 px-2 sm:px-4 w-full md:w-11/12 lg:w-4/5 rounded-t-2xl py-8"
        style={{
          background:
            "linear-gradient(180deg, rgba(55,18,98,1) 0%, rgba(200,157,230,0) 100%)",
        }}
      >
        <div className="relative bg-[#100d20] rounded-xl md:rounded-2xl shadow-2xl w-full max-w-xl">
          <div className="px-4 sm:px-6 md:px-8 pt-12 md:pt-16 pb-6 md:pb-8">
            <div className="text-center mb-8 md:mb-12 flex flex-col items-center">
              <h2 className="text-2xl md:text-4xl font-bold font-psfsans mb-2 md:mb-4 text-clip text-transparent bg-clip-text bg-gradient-to-r from-[#a455f7] to-[#3e80f6]">
                Register Here
              </h2>

              <img src={date_logo} alt="invite_logo" className="max-w-48" />
              <p className="text-base md:text-lg font-psfsans font-semibold mt-2 text-psfviolet text-center">
                COEP Technological University
              </p>
            </div>

            <form className="space-y-6 md:space-y-8" onSubmit={handleSubmit}>
              <div className="relative group">
                <User
                  className={`absolute left-2 md:left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 ${
                    focused === "name" ? "text-blue-400" : "text-[#6EACDA]"
                  }`}
                  size={18}
                />
                <input
                  ref={nameRef}
                  type="text"
                  placeholder="Enter your Name"
                  value={formData.name}
                  onFocus={() => setFocused("name")}
                  onBlur={() => setFocused("")}
                  onChange={(e) => handleChange("name", e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, emailRef)}
                  className="w-full pl-8 md:pl-12 pr-4 py-2 md:py-3 border-b-2 border-[#6EACDA] bg-transparent text-white placeholder-gray-400 outline-none focus:border-blue-400 transition-all duration-200 hover:border-blue-300 text-sm md:text-base"
                />
                {errors.name && (
                  <div className="text-red-500 text-xs md:text-sm mt-1 flex items-center">
                    <AlertCircle size={16} className="mr-2" />
                    {errors.name}
                  </div>
                )}
              </div>

              <div className="relative group">
                <Mail
                  className={`absolute left-2 md:left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 ${
                    focused === "email" ? "text-blue-400" : "text-[#6EACDA]"
                  }`}
                  size={18}
                />
                <input
                  ref={emailRef}
                  type="email"
                  placeholder="Enter your Email"
                  value={formData.email}
                  onFocus={() => setFocused("email")}
                  onBlur={() => setFocused("")}
                  onChange={(e) => handleChange("email", e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, phoneRef)}
                  className="w-full pl-8 md:pl-12 pr-4 py-2 md:py-3 border-b-2 border-[#6EACDA] bg-transparent text-white placeholder-gray-400 outline-none focus:border-blue-400 transition-all duration-200 hover:border-blue-300 text-sm md:text-base"
                />
                {errors.email && (
                  <div className="text-red-500 text-xs md:text-sm mt-1 flex items-center">
                    <AlertCircle size={16} className="mr-2" />
                    {errors.email}
                  </div>
                )}
              </div>

              <div className="relative group">
                <Phone
                  className={`absolute left-2 md:left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 ${
                    focused === "phone" ? "text-blue-400" : "text-[#6EACDA]"
                  }`}
                  size={18}
                />
                <input
                  ref={phoneRef}
                  type="tel"
                  placeholder="Enter your Contact Number"
                  value={formData.phone}
                  onFocus={() => setFocused("phone")}
                  onBlur={() => setFocused("")}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, membersRef)}
                  className="w-full pl-8 md:pl-12 pr-4 py-2 md:py-3 border-b-2 border-[#6EACDA] bg-transparent text-white placeholder-gray-400 outline-none focus:border-blue-400 transition-all duration-200 hover:border-blue-300 text-sm md:text-base"
                />
                {errors.phone && (
                  <div className="text-red-500 text-xs md:text-sm mt-1 flex items-center">
                    <AlertCircle size={16} className="mr-2" />
                    {errors.phone}
                  </div>
                )}
              </div>

              <div className="relative group">
                <Users
                  className={`absolute left-2 md:left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 ${
                    focused === "members" ? "text-blue-400" : "text-[#6EACDA]"
                  }`}
                  size={18}
                />
                <select
                  ref={membersRef}
                  value={formData.members}
                  onFocus={() => setFocused("members")}
                  onBlur={() => setFocused("")}
                  onChange={(e) => handleChange("members", e.target.value)}
                  className="w-full pl-8 md:pl-12 pr-8 py-2 md:py-3 border-b-2 border-[#6EACDA] bg-transparent text-white outline-none focus:border-blue-400 appearance-none transition-all duration-200 hover:border-blue-300 cursor-pointer text-sm md:text-base"
                  style={{ WebkitAppearance: "none", MozAppearance: "none" }}
                >
                  <option
                    value=""
                    disabled
                    className="bg-[#021526] text-sm md:text-base"
                  >
                    Number of Members
                  </option>
                  {[1, 2, 3].map((num) => (
                    <option
                      key={num}
                      value={num}
                      className="bg-[#021526] text-sm md:text-base"
                    >
                      {num} {num === 1 ? "Member" : "Members"}
                    </option>
                  ))}
                </select>
                {errors.members && (
                  <div className="text-red-500 text-xs md:text-sm mt-1 flex items-center">
                    <AlertCircle size={16} className="mr-2" />
                    {errors.members}
                  </div>
                )}
              </div>

              <div className="relative group">
                <Users
                  className={`absolute left-2 md:left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-200 ${
                    focused === "personType" ? "text-blue-400" : "text-[#6EACDA]"
                  }`}
                  size={18}
                />
                <div
                  onFocus={() => setFocused("personType")}
                  onBlur={() => setFocused("")}
                  className="w-full pl-8 md:pl-12 pr-4 py-2 md:py-3 border-b-2 border-[#6EACDA] bg-transparent text-white outline-none focus-within:border-blue-400 transition-all duration-200 hover:border-blue-300 cursor-pointer text-sm md:text-base"
                >
                  <div className="flex flex-wrap gap-4">
                    {["Visitor", "Investor", "Student", "Corporate", "Alumni"].map(
                      (type) => (
                        <label
                          key={type}
                          className="flex items-center space-x-2 text-white text-sm md:text-base cursor-pointer"
                        >
                          <input
                            type="radio"
                            value={type}
                            checked={formData.personType === type}
                            onChange={(e) =>
                              handleChange("personType", e.target.value)
                            }
                            className="transition-all duration-200"
                          />
                          <span>{type}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>
                {errors.personType && (
                  <div className="text-red-500 text-xs md:text-sm mt-1 flex items-center">
                    <AlertCircle size={16} className="mr-2" />
                    {errors.personType}
                  </div>
                )}
              </div>

              <p className="text-white text-sm md:text-base font-psfsans mt-4">
                By registering, you agree to our <a href="#" className="text-blue-400">Terms of Service</a> and <a href="#" className="text-blue-400">Privacy Policy</a>.
              </p>

              <div className="flex justify-center mt-8">
                <button
                  type="submit"
                  className="w-full md:w-3/5 px-6 py-3 text-white bg-gradient-to-r from-[#6EACDA] to-[#3e80f6] rounded-xl shadow-lg text-lg font-psfsans focus:outline-none hover:bg-gradient-to-l transition-all duration-200"
                >
                  Register Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
