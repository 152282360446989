import { useRef } from "react";
import { useInView } from "framer-motion";

import pitchersvalley_bg from "../../assets/investors/shark_background.jpg";

import lp_img from "../../assets/investors/lp.jpg.webp";
import pv_img from "../../assets/investors/pv.JPG.webp";

import neeraj_tyagi from "../../assets/investors/pv_neeraj-tyagi.png.webp";
import mohit from "../../assets/investors/pv_mohit.png.webp";
import sanjaymehta from "../../assets/investors/ppv_sanjaymehta.png.webp";
import mahavir from "../../assets/investors/ppv_mahavir-pratap-sharma.png.webp";
import madhu from "../../assets/investors/ppv_madhu-lunawat.png.webp";
import ganesh from "../../assets/investors/ppv_ganesh-natarajan.png.webp";
import bhawna from "../../assets/investors/ppv_bhawna-bhatnagar.png.webp";
import vatsal from "../../assets/investors/lp_vatsal.png.webp";
import ranjeet from "../../assets/investors/lp_ranjeet-shetye.png.webp";
import monica from "../../assets/investors/lp_monica.png.webp";
import mayuresh from "../../assets/investors/lp_mayuresh.png.webp";
import jose from "../../assets/investors/lp_jose-thattil.png.webp";
import bikram from "../../assets/investors/lp_bikram.png.webp";
import arindam from "../../assets/investors/lp_arindam.png.webp";
import anil from "../../assets/investors/lp_anil-tabib.png.webp";

import InvestorsEvents from "./InvestorsEvents";
import { PastInvestorsLP } from "./PastInvestors";
import PastInvestorsPV from "./PastInvestors";

// Importing venture associations logos
import anthill from "../../assets/venture_associations/anthill.png.webp";
import bharatInclusionSeedFund from "../../assets/venture_associations/bharatInclusionSeedFund.png.webp";
import earlyseeVentures from "../../assets/venture_associations/earlyseeVentures.png.webp";
import ethiqualGlobal from "../../assets/venture_associations/ethiqualGlobal.png.webp";
import eximiusVentures from "../../assets/venture_associations/eximiusVentures.png.webp";
import hemSecurities from "../../assets/venture_associations/hemSecurities.png.webp";
import hundredx from "../../assets/venture_associations/hundredx.png.webp";
import indiaAccelerator from "../../assets/venture_associations/indiaAccelerator.png.webp";
import inflectionPointVentures from "../../assets/venture_associations/inflectionPointVentures.png.webp";
import leadAngels from "../../assets/venture_associations/leadAngels.png.webp";
import mumbaiAngels from "../../assets/venture_associations/mumbaiAngels.png.webp";
import one360 from "../../assets/venture_associations/one360.png.webp";
import seafund from "../../assets/venture_associations/seafund.png.webp";
import sucseedIndovation from "../../assets/venture_associations/sucseedIndovation.png.webp";
import ventureCatalysts from "../../assets/venture_associations/ventureCatalysts.png.webp";
import wefoundcircle from "../../assets/venture_associations/wefoundcircle.png.webp";
import windroseCapital from "../../assets/venture_associations/windroseCapital.png";

export default function Investors() {
  const PV = [
    "Pitchers Valley",
    "Pitchers valley is an exhilarating live pitching event, where registered startups undergo rigorous scrutiny based on their pitch deck, out of which, around 10 exceptional startups are handpicked to present their innovatinve business ideas in front of some of the most prominent venture investors of India.",
    "The second edition of Pitchers Valley took place lasy year, wherein an infusion of INR 11.5 crore was deployed as a capital investment.",
    lp_img,
  ];

  const LP = [
    "Lightning Pitch",
    "The Lightning Pitch is an exclusive event that took place featuring 10 investors and 15 startups. In the first round, Startups presented their product in a 3-minute pitch to investors. If interested, investors provided a card for the second round.",
    "The audience could predict investments through polls. In second round, selected startups engaged in 25-minute discussions with investors in private to explore collaborations and possibilities.",
    "image lp",
    pv_img,
  ];

  // background-color: #FBAB7E; background-image: linear-gradient(45deg, #FBAB7E 35%, #F7CE68 74%);
  // background-color: #21D4FD; background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

  const LP_title_color =
    "eventTitle text-5xl font-alumni tracking-tight pb-4 font-bold bg-clip-text text-transparent bg-gradient-to-bl from-[#FFE53B] to-[#FBAB7E]";
  const PV_title_color =
    "eventTitle text-5xl font-alumni tracking-tight pb-4 font-bold bg-clip-text text-transparent bg-gradient-to-tr from-[#4C83FF] to-[#2AFADF]";

  const PV_investors = [
    // name, description, year, image
    [
      "Mohit Gulati",
      "CIO & Managing Partner | ITI Growth Opportunities Fund",
      "PSF24",
      mohit,
    ],
    [
      "Neeraj Tyagi",
      "Cofounder & CEO | We founder Circle and Invstt",
      "PSF24",
      neeraj_tyagi,
    ],
    // ["Priyanka Mandnani", "CIO | Terex Ventures", "PSF24", some_investor],
    // ["Nupur Hemant", "Venture Capitalist", "PSF24", some_investor],

    [
      "Dr . Ganesh Natarajan",
      "Chairman | 5F World \n Chairman | Honeywell Automation India & Lighthouse Communities",
      "PSF23",
      ganesh,
    ],
    [
      "Mahavir Pratap Sharma",
      "Chairman | TiE India Angels \n Co-Founder & Chairman | RAIN",
      "PSF23",
      mahavir,
    ],
    ["Bhawna Bhatnagar", "Co-Founder | WeFounder Circle", "PSF23", bhawna],
    ["Sanjay Mehta", "Founder and Partner | 100X.VC", "PSF23", sanjaymehta],
    [
      "Madhu Lunawat",
      "Co-Founder & Director | Pantomath Group \n Director | Asit C Mehta Financial",
      "PSF23",
      madhu,
    ],
  ];

  const LP_investors = [
    // name, description, year, image
    ["Jose Thattil", "CEO & CO-Founder | PhiCommerce", "PSF24", jose],
    ["Vatsal Kanakiya", "CEO & Founder | 100X.VC", "PSF24", vatsal],
    ["Anil Tabib", "Charter Member | TiE Pune", "PSF24", anil],
    ["Arindam Mukhopadhyay", "Partner | India Accelerator", "PSF24", arindam],
    [
      "Mayuresh Raut",
      "Co-Founder & Managing Partner | Seafund",
      "PSF24",
      mayuresh,
    ],
    [
      "Monica Gupta",
      "Co-Founder & Managing Partner | Earlyseeds Ventures",
      "PSF24",
      monica,
    ],
    ["Bikram Mahajan", "Partner | Unicorn India Venture", "PSF24", bikram],
    ["Ranjeet Shetye", "Partner | Yournest Venture Capital", "PSF24", ranjeet],
  ];

  // Array of venture association logos and their names
  const ventureAssociations = [
    {name:"Windrose Capital", logo: windroseCapital},
    { name: "100X", logo: hundredx },
    { name: "Mumbai Angels", logo: mumbaiAngels },
    { name: "Sucseed Indovation", logo: sucseedIndovation },
    { name: "Inflection Point Ventures", logo: inflectionPointVentures },
    { name: "Eximius Ventures", logo: eximiusVentures },
    { name: "Anthill", logo: anthill },
    { name: "Lead Angels", logo: leadAngels },
    { name: "Venture Catalysts", logo: ventureCatalysts },
    { name: "We Found Circle", logo: wefoundcircle },
    { name: "Earlysee Ventures", logo: earlyseeVentures },
    { name: "Ethiqual Global", logo: ethiqualGlobal },
    { name: "360 One", logo: one360 },
    { name: "India Accelerator", logo: indiaAccelerator },
    { name: "Bharat Inclusion Seed Fund", logo: bharatInclusionSeedFund },
    { name: "Sea Fund", logo: seafund },
  ];

  const ref = useRef(null);

  const isInView = useInView(ref, { once: true });

  return (
    <div className="flex flex-col h-fit min-h-screen pb-12 mb-24 w-screen overflow-hidden font-psfsans">
      {/* Investors */}
      <div
        className="flex flex-col w-full items-center relative bg-cover bg-no-repeat bg-fixed md:mt-16"
        style={{ backgroundImage: `url(${pitchersvalley_bg})` }}
      >
        <div
          className="absolute top-0 left-0 h-[300vh] md:h-[200vh] lg:h-[100vh]  w-full"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(7,3,16,1) 20%, rgba(7,3,16,0) 75%)`,
          }}
        ></div>

        {/* events cards */}
        <div
          className="mt-32 md:w-4/5 grid grid-row-2 lg:grid-cols-2  gap-20 lg:gap-8 p-4 lg:px-16 z-10"
          ref={ref}
          style={{
            transform: isInView ? "none" : "translateY(30px)",
            opacity: isInView ? 1 : 0,
            transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
            transitionTimingFunction: "ease-out",
            willChange: "transform, opacity",
          }}
        >
          <InvestorsEvents
            eventTitle={PV[0]}
            eventDescription1={PV[1]}
            eventDescription2={PV[2]}
            eventImage={pv_img}
            color={PV_title_color}
          />
          <InvestorsEvents
            eventTitle={LP[0]}
            eventDescription1={LP[1]}
            eventDescription2={LP[2]}
            eventImage={lp_img}
            color={LP_title_color}
          />
        </div>

        {/* past investors */}
        <div
          className="flex flex-col items-center w-full pb-8"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(7,3,16,0) 0%, rgba(7,3,16,0.7) 25%, rgba(7,3,16,1) 100%)`,
          }}
        >
          <div className="flex flex-col gap-16 w-full md:w-5/6 mt-24">
            <PastInvestorsPV
              event={"Pitchers Valley 2024" }
              pastInvestors={PV_investors}
            />
            <PastInvestorsLP
              event={"Lightning Pitch 2024"}
              pastInvestors={LP_investors}
            />
          </div>
        </div>
      </div>

      {/* Venture Associations */}
      <div className="bg-[#070310] w-full flex flex-col items-center min-h-screen mb-80 md:mb-32 lg:mb-12">
        <div className="absolute w-full min-h-screen">
          {/* text div */}
          <div className="relative">
            {/* mobile view */}
            <p className="text-6xl tracking-tighter font-black text-left pl-12 opacity-50 relative md:hidden">
              Venture
            </p>
            <p className="text-6xl tracking-tighter font-black text-left pl-12 opacity-50 relative md:hidden">
              Associations
            </p>

            {/* Desktop view */}
            <p
              className="text-[8vw]  tracking-tighter font-black text-center hidden md:block opacity-40 relative transform"
              style={{ transform: "scaleY(1.05)" }}
            >
              Venture Associations
            </p>

            {/* Gradient Overlay */}
            <div
              className="absolute bottom-0 left-0 h-1/4   md:h-full w-full"
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(7,3,16,0) 25%, rgba(7,3,16,1) 75%)`,
              }}
            ></div>
          </div>

          {/* vc logos */}
          <div className="flex flex-col items-center lg:-mt-12 pb-12 h-fit">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:grid-rows-4 items-center gap-2 lg:gap-4 w-4/5 h-fit">
              {ventureAssociations.map((vc, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center w-auto h-36 lg:h-full bg-slate-600 rounded-lg bg-opacity-10 backdrop-blur-sm p-2 md:p-4 ring-[1px] ring-white ring-opacity-10 transform transition-all duration-200 ease-in-out hover:ring-opacity-50"
                >
                  <img
                    src={vc.logo}
                    alt={vc.name}
                    className="h-16 md:h-20 lg:h-32 w-auto rounded-md"
                  />
                  <div className="absolute h-full w-full bg-[#06020f] opacity-10 transform transition-all duration-300 ease-in-out"></div>
                  <p className="text-sm text-center mt-1 font-semibold  ">
                    {vc.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
