import React, { useState } from "react";
import AdminLogin from "./AdminLogin";
import LaunchpadScreen from './LaunchpadScreen';
import LaunchpadResult from "./LaunchpadResults";

const MainScreen = () => {
    const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false); // Track admin authentication
    const [showLogin, setShowLogin] = useState(false); // Toggle Admin Login screen

    const handleAdminLogin = (isAuthenticated) => {
        // Callback for Admin Authentication
        setIsAdminAuthenticated(isAuthenticated);
        setShowLogin(false); // Hide login screen after success
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-psfwhite">
            {!isAdminAuthenticated && !showLogin && (
                <div className="w-full p-8 space-y-6  rounded-lg shadow-lg border border-psfgrey">
                    <h1 className="text-2xl font-semibold text-psfblue">
                        Welcome to LaunchPad
                    </h1>
                    <div className="flex flex-col space-y-4">
                        <button
                            onClick={() => setShowLogin(true)} // Show Admin Login
                            className="px-4 max-w-md py-2 text-psfwhite bg-psfviolet rounded-md transition hover:bg-psfblue"
                        >
                            Admin Login For Launchpad Screen
                        </button>
                    </div>
                </div>
            )}

            {showLogin && (
                <AdminLogin onLoginSuccess={handleAdminLogin} />
            )}

            {isAdminAuthenticated && <LaunchpadScreen />}
            {isAdminAuthenticated && <LaunchpadResult />}

        </div>
    );
};

export default MainScreen;