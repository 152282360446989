import React, { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { MessageSquare, CheckCircle, XCircle, X } from "lucide-react";

const StallOwnerQueryForm = () => {
  const [query, setQuery] = useState("");
  const [stallNumber, setStallNumber] = useState("");
  const [startupName, setStartupName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null); // null | 'success' | 'error'
  const db = getFirestore();

  const resetForm = () => {
    setStallNumber("");
    setStartupName("");
    setContactNumber("");
    setQuery("");
  };

  const dismissAlert = () => {
    setSubmitStatus(null);
  };

  const submitQuery = async () => {
    if (!stallNumber || !startupName || !contactNumber || !query) {
      setSubmitStatus('error');
      return;
    }

    setIsSubmitting(true);
    try {
      const queriesRef = collection(db, "queries");
      await addDoc(queriesRef, {
        stallNumber,
        startupName,
        contactNumber,
        query,
        timestamp: new Date().toISOString(),
        status: "pending",
      });
      resetForm();
      setSubmitStatus('success');
    } catch (error) {
      console.error("Error submitting query: ", error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center justify-start pt-32 p-4">
      {/* Main Heading */}
      <h1 className="text-4xl font-bold text-cyan-800 mb-12">Launchpad Queries</h1>
      
      {/* Success Alert */}
      {submitStatus === 'success' && (
        <div className="w-full max-w-lg mb-6 rounded-lg border border-green-200 bg-green-50 p-4">
          <div className="flex items-start justify-between">
            <div className="flex items-start gap-3">
              <CheckCircle className="h-5 w-5 text-green-600 mt-0.5" />
              <div>
                <h3 className="font-medium text-green-800">Success!</h3>
                <p className="text-green-700 mt-1">
                  Your query has been submitted successfully. We'll get back to you soon.
                </p>
              </div>
            </div>
            <button 
              onClick={dismissAlert}
              className="text-green-500 hover:text-green-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      {/* Error Alert */}
      {submitStatus === 'error' && (
        <div className="w-full max-w-lg mb-6 rounded-lg border border-red-200 bg-red-50 p-4">
          <div className="flex items-start justify-between">
            <div className="flex items-start gap-3">
              <XCircle className="h-5 w-5 text-red-600 mt-0.5" />
              <div>
                <h3 className="font-medium text-red-800">Submission Failed</h3>
                <p className="text-red-700 mt-1">
                  Please ensure all fields are filled correctly and try again.
                </p>
              </div>
            </div>
            <button 
              onClick={dismissAlert}
              className="text-red-500 hover:text-red-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      <div className="w-full max-w-lg bg-white rounded-lg shadow-lg p-8">
        <div className="flex items-center gap-4 mb-8">
          <MessageSquare className="w-8 h-8 text-blue-600" />
          <h2 className="text-3xl font-semibold text-gray-900">
            Submit Your Query
          </h2>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitQuery();
          }}
          className="space-y-6"
        >
          <div>
            <label
              htmlFor="stallNumber"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Stall Number
            </label>
            <input
              id="stallNumber"
              type="text"
              value={stallNumber}
              onChange={(e) => setStallNumber(e.target.value)}
              placeholder="Enter your stall number"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
            />
          </div>

          <div>
            <label
              htmlFor="startupName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Startup Name
            </label>
            <input
              id="startupName"
              type="text"
              value={startupName}
              onChange={(e) => setStartupName(e.target.value)}
              placeholder="Enter your startup name"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
            />
          </div>

          <div>
            <label
              htmlFor="contactNumber"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Contact Number
            </label>
            <input
              id="contactNumber"
              type="tel"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              placeholder="Enter your contact number"
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors"
            />
          </div>

          <div>
            <label
              htmlFor="query"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Query Description
            </label>
            <textarea
              id="query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter your query here..."
              required
              rows={4}
              className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors resize-none"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed mt-6 shadow-md"
          >
            {isSubmitting ? "Submitting..." : "Submit Query"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default StallOwnerQueryForm;