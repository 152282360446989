import React, { forwardRef } from "react";

import {
  PlateGeneral,
  PlateBronze,
  PlateSilver,
  PlateGold,
  PlatePlatinum,
} from "./Plate";

const AlumniCards = forwardRef(
  ({ title, names = [], textbg, logo_alumni = [] }, ref) => {
    // Function to get the correct plate component based on the title
    const getPlateComponent = (title) => {
      switch (title) {
        case "Platinum":
          return PlatePlatinum;
        case "Gold":
          return PlateGold;
        case "Silver":
          return PlateSilver;
        case "Bronze":
          return PlateBronze;
        default:
          return PlateGeneral; // Default to PlateGeneral if no match
      }
    };

    const PlateComponent = getPlateComponent(title);

    // Determine layout style based on title
    const isVerticalLayout = title === "Platinum" || title === "Gold";
    const layoutClass = isVerticalLayout ? "flex-col" : "flex-row";

    return (
      <div
        ref={ref}
        className="min-h-screen h-fit flex flex-col bg-no-repeat justify-center items-center gap-4 md:gap-8"
      >
        {/* Title */}
        <div
          className={`Alumni_title font-playfair font-normal text-4xl md:text-5xl lg:text-8xl mt-24 w-full text-center mb-24 bg-clip-text text-transparent ${textbg}`}
        >
          {title}
        </div>

        {/* Logos and Plates */}
        <div className="w-full md:w-4/5 flex flex-col gap-8items-center justify-center">
          {/* Render alumni logos */}
          {logo_alumni.length > 0 && (
            <div className="flex flex-row flex-wrap gap-4 justify-center">
              {logo_alumni.map((logo, index) => (
                <img
                  src={logo}
                  key={index}
                  alt={`${title} alumni logo ${index + 1}`} // Meaningful alt text
                  className="h-32"
                />
              ))}
            </div>
          )}

          {/* Render alumni plates */}
          <div
  className={`w-full flex ${layoutClass} justify-center gap-8 flex-wrap items-center`}
>
  {names.map((name, index) => (
    <PlateComponent key={index} name={name} />
  ))}
</div>
        </div>
      </div>
    );
  }
);

export default AlumniCards;
