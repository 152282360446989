import CustomChips from "./CustomChips";

export default function Vision() {
  return (
    <div className="flex flex-col items-center mt-16">
      <CustomChips text={"Vision"} />

      <p className="font-normal text-center text-xl text-wrap px-4 lg:px-12 mt-4">
        Our vision is to be “A successful institute model to change India for
        the better through young entrepreneurs who are the hope of tomorrow’s
        rural and urban India”. We aim to promote entrepreneurship in a
        self-supported, sustainable, and scalable manner by creating a
        world-class institute.
      </p>
    </div>
  );
}
