import {
  Routes,
  Route,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { getToken, onMessage } from "./firebase-config"; // Import Firebase setup
import { getMessaging } from "firebase/messaging";
import { setDoc, doc, getDocs, query, where, collection } from "firebase/firestore";
import { db } from "./firebase-config";

import { useEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "./index.css";
import Navbar from "./AppNavbar";
import Footer from "./Components/Mainpage/Footer";
import Mainpage from "./Components/Mainpage/Mainpage";
import Sponsors from "./Components/Sponsors/Sponsors";
import Investors from "./Components/Investors/Investors";
import Register from "./Components/Register/registrations";
import Alumni from "./Components/Alumni/Alumni";
import Explore from "./Components/Explore/Explore";
import Queries from "./Components/Query/Queries";
import QueryList from "./Components/Query/QueryList";
import UserLogin from "./Components/LP/auth";
import Voting from "./Components/LP/voting";
import Admin from "./Components/LP/admin";
import Scoreboard from './Components/LP/Scoreboard';
import Launchpad from './Components/Launchpads/Launchpad';
import LaunchpadResult from "./Components/Launchpads/LaunchpadResults";
import LaunchpadScreen from "./Components/Launchpads/LaunchpadScreen";
import LaunchpadPopulate from "./Components/Launchpads/LaunchpadPopulate";
import Auth from "./Components/Launchpads/Auth";
import Checklist from "./Components/Notification/Checklist";
import StartupRequest from "./Components/Notification/StartupReq";
import EventNotification from "./Components/Notification/EventNotification";
import InternshipList from "./Components/InternshipCard/InternshipList";
import ProtectedAdmin from "./Components/LP/protectedAdmin";
import StartupCards from "./Components/Explore/StartupCards";
import AddStartup from "./Components/Explore/AddStartup";
import Live from "./Components/Explore/liveEvents";

export default function App() {
  const location = useLocation();
  const scroll = new LocomotiveScroll();

  if (scroll) {
    console.log("scroll has not null value");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);


  useEffect(() => {
    // Request notification permission for iOS and Android
    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.warn("Notification permission denied.");
        }
      } catch (err) {
        console.error("Error requesting notification permission:", err);
      }
    };

    // Register the service worker
    const registerServiceWorker = async () => {
      if ("serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
          console.log("Service Worker registered with scope:", registration.scope);
        } catch (err) {
          console.error("Service Worker registration failed:", err);
        }
      }
    };

    const storeTokenInFirestore = async (token) => {
      try {
        
        const tokensCollection = collection(db, "EVENTS");
        const tokenQuery = query(tokensCollection, where("token", "==", token));
        const querySnapshot = await getDocs(tokenQuery);

        if (!querySnapshot.empty) {
          console.log("Token already exists in Firestore.");
          return; // Exit the function if the token is already present
        }

        const id = uuidv4();
        const tokenDocRef = doc(db, "EVENTS", id); 
        await setDoc(tokenDocRef, {
          token, // Store only the token
        });
        console.log("Token stored in Firestore successfully.");

      } catch (err) {
        console.error("Error storing token in Firestore:", err);
      }
    };

    const getFCMToken = async () => {
      try {
        // Check if notification permissions are granted
        if (Notification.permission !== "granted") {
          console.warn("Notification permission not granted. Skipping token generation.");
          return; // Exit if permission is not granted
        }
    
        const messagingInstance = getMessaging();
        const currentToken = await getToken(messagingInstance, {
          vapidKey: "BIWUojQMFl3tSKt-p7f0H4xk1WLnCtCmP_tS_YpWYBH9HOSLixSghLcUYSsp3cK9n-AaSQlh6qsh_ZDjkdR8DI0",
        });
    
        if (currentToken) {
          console.log("FCM Token:", currentToken);
          localStorage.setItem("fcmToken", currentToken);
          await storeTokenInFirestore(currentToken);
        } else {
          console.warn("No FCM token available.");
        }
      } catch (err) {
        console.error("Error getting FCM token:", err);
      }
    };


    // Handle foreground notifications
    const listenForForegroundMessages = () => {
      const messagingInstance = getMessaging();
      onMessage(messagingInstance, (payload) => {
        console.log("Foreground notification received:", payload);

        const { title, body, image } = payload.notification || {}; // Extract image from payload

        // Log to check if image is available
        console.log("Image URL:", image);

        if (Notification.permission === "granted") {
          // Check if an image exists in the notification payload
          const notificationOptions = {
            body: body || "You have a new message",
            icon: "path/to/default/icon.png", // Optional: Add default icon for the notification
            image: image || "https://raw.githubusercontent.com/vaish-navi24/Images_Notifications/main/taylor.jpg", // Fallback image if not available
          };
          // Show the notification with the image
          new Notification(title || "Notification", notificationOptions);

          // Optional: Add an alert for debugging
          alert(`${title}: ${body}`);
        }
      });
    };

    // Call all setup functions
    requestNotificationPermission();
    registerServiceWorker();
    getFCMToken();
    listenForForegroundMessages();
  }, []);


  return (
    <div className="selection:bg-[#6d41d0] selection:text-white">
      <Navbar />
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/Investors" element={<Investors />} />
        <Route path="/Sponsors" element={<Sponsors />} />
        <Route path="/Internship" element={<InternshipList />} />
        <Route path="/register" element={<Navigate to="/register/masterclass" />} />
        <Route path="/register/:section" element={<RegisterWrapper />} />
        <Route path="/Alumni" element={<Alumni />} />
        {/* Redirect /explore to /explore/gallery */}
        <Route path="/explore" element={<Navigate to="/explore/gallery" />} />
        <Route path="/explore/:section" element={<ExploreWrapper />} />
        <Route path="*" element={<Mainpage />} />
        <Route path="/query" element={<Queries />} />
        <Route path="/querylist" element={<QueryList />} />
        <Route path="/login" element={<UserLogin />} />
        <Route
          path="/voting"
          element={
            localStorage.getItem('username') ? <Voting /> : <Navigate to="/login" />
          }/>
        <Route path="/admin" element={<ProtectedAdmin />} />
        <Route path="/scoreboard" element={<Scoreboard />} />
        <Route path="/auth" element={<Auth />} />
          <Route path="/launchpad" element={<Launchpad/>}/>
          {/* <Route path="/launchpadScreen" element={<LaunchpadScreen/>}/> */}
          <Route path="/launchpadResult" element={<LaunchpadResult/>}/>
          <Route path="/launchpadPopulate" element={<LaunchpadPopulate/>}/>
          <Route path="/checklist" element={<Checklist/>}/>   
          <Route path='/startupreq' element={<StartupRequest/>}/>
          <Route path="eventsNotification" element={<EventNotification />} />
          <Route path="/StartupExpo" element={<StartupCards />} />
          <Route path="/AddStartup" element={<AddStartup />} />
          <Route path="/LiveEvents" element={<Live/>} />
          </Routes>
      <div id="contact">
        <Footer />
      </div>
    </div>
  );
}

// Wrapper to extract section parameter for Explore
function ExploreWrapper() {
  const { section } = useParams();
  return <Explore section={section} />;
}

// Wrapper to extract section parameter for Register
function RegisterWrapper() {
  const { section } = useParams();
  return <Register defaultSection={section} />;
}