import PSF_logo from "../../assets/logo/PSF25_white.png";
import CustomChips from "./CustomChips";
import { useRef } from "react";
import { useInView, motion } from "framer-motion";

export default function Invite() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="flex flex-col text-center items-center mt-20"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(30px)",
        opacity: isInView ? 1 : 0,
        transition: "all 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s",
        transitionTimingFunction: "ease-out",
        willChange: "transform, opacity",
      }}
    >
      <CustomChips text={"Invite"} />

      <p className="font-light text-2xl italic mt-4">
        We warmly welcome you to
      </p>
      <img src={PSF_logo} alt="pune startup fest" className="w-60 my-2" />
      <p className="font-black font-alumni text-3xl">11th & 12th Jan, 2025</p>

      <a href="/register/visitors">
        <motion.button
          whileTap={{ scaleX: 1.2 }}
          whileHover={{ scale: 1.1 }}
          className="mt-8 h-12 w-40 font-medium rounded-xl ring-white ring-[1px] hover:ring-[0px] transition-colors duration-300 transform hover:bg-[#e0ba38] hover:text-black p-2 px-4 text-center  text-gray-200"
        >
          Register
        </motion.button>
      </a>
    </div>
  );
}
