import React from "react";
import { motion } from "framer-motion";
import CustomChips from "./CustomChips";

import { useRef } from "react";
import { useInView } from "framer-motion";

export default function Launchpad() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const launchpad_desc1 =
    "Pune Startup Fest was conceptualized to provide a platform for startups to showcase their innovative ideas and products, categorized into various zones";
  const launchpad_desc2 =
    "We will be hosting an impressive array of investors, mentors, and incubators from across the nation, all converging under one roof";
  const launchpad_outro = "Ready to accelerate your growth?";

  const startup_zones = [
    "Technical",
    "Social",
    "Health & Lifestyle",
    "Agricultural",
    "Green Zone",
    "Educational",
    "Student",
    "Patents",
  ];

  return (
    <div className="flex flex-col md:flex-row w-full md:w-4/5 lg:w-3/5 p-4 md:p-8 gap-8 m-8 justify-center items-stretch overflow-hidden">
      {/* First div, left one */}
      <div className="details flex-1">
        <CustomChips text={"Launchpad"} />

        <div className="flex flex-col gap-4 md:gap-8 mt-4 md:mt-8">
          <div
            className="text-6xl gap-6 tracking-wide mt-4 font-semibold"
            ref={ref}
            style={{
              transform: isInView ? "none" : "translateY(+10px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
            }}
          >
            <p>We help</p>
            <p>businesses grow</p>
          </div>

          <div className="flex flex-col gap-8 m-0 md:mr-16 lg:mr-4">
            <div className="font-light">{launchpad_desc1}</div>
            <div className="font-light">{launchpad_desc2}</div>
            <div className="font-light">{launchpad_outro}</div>
          </div>
        </div>

        <a href="/Register">
          <motion.button
            whileTap={{ scaleX: 1.1 }}
            whileHover={{ scale: 1.1 }}
            className="bg-psfviolet mt-8 p-2 px-8 text-lg rounded-xl"
          >
            Register
          </motion.button>
        </a>
      </div>

      {/* Second div, the scroll one */}
      <div className="marquee flex-1 overflow-hidden relative">
        {/* Gradient overlay for aesthetic effect */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#080411] via-transparent to-[#080411] pointer-events-none z-10"></div>

        <motion.div
          className="flex flex-col w-full justify-center items-center h-[50vh]"
          initial={{ y: 0 }}
          animate={{ y: "-100%" }}
          transition={{
            duration: 16,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          {[...startup_zones, ...startup_zones, ...startup_zones].map(
            (zone, index) => (
              <div
                key={index}
                className="text-4xl lg:text-5xl font-semibold text-center py-6"
              >
                {zone}
              </div>
            )
          )}
        </motion.div>
      </div>
    </div>
  );
}
