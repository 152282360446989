import React, { useState } from "react";
import { Download, Eye } from "lucide-react";
import {
  IBM_patent,
  Apple_patent,
  Copyright,
  National_Youth_fest,
} from "./IPRComponent";
import CustomChips from "../Mainpage/CustomChips";

import copyright_doc from "../../assets/IPR_blog/Copyright_PSF.pdf";
import ibm_doc from "../../assets/IPR_blog/IBM Patents.pdf";
import apple_doc from "../../assets/IPR_blog/Apple Scroll and Bounce.pdf";
import National_youth_pdf from "../../assets/IPR_blog/NationalYouthFestival.pdf";

const IprBlog = () => {
  const iiprBlog_data = [
    {
      title: "Copyright",
      blog_component: Copyright,
      document: copyright_doc,
    },
    {
      title: "IBM Patents",
      blog_component: IBM_patent,
      document: ibm_doc,
    },
    {
      title: "Apple Patent",
      blog_component: Apple_patent,
      document: apple_doc,
    },
    {
      title: "National Youth Festival",
      blog_component: National_Youth_fest,
      document: National_youth_pdf,
    },
  ];

  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(null);

  const handlePreview = (index) => {
    setCurrentPreviewIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleDownload = (documentUrl) => {
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    } else {
      alert("Document not available.");
    }
  };

  const renderPreview = () => {
    if (currentPreviewIndex === null) {
      return (
        <div className="p-4 bg-gray-100 rounded-lg mt-4 text-black">
          Select an item to see the preview.
        </div>
      );
    }

    const data = iiprBlog_data[currentPreviewIndex];
    const Component = data.blog_component;

    // Check if Component exists and render it
    if (Component) {
      return (
        <div className="p-4 rounded-lg mt-4 font-psfsans bg-white text-black">
          <Component />
        </div>
      );
    }

    return (
      <div className="p-4 rounded-lg mt-4 ">
        <p className="text-black">No preview available.</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center min-h-screen w-full p-4">
      <CustomChips text={"Innovation & IPR"} />
      <div className="font-psfsans text-3xl md:text-5xl font-bold my-2 bg-clip-text text-center text-transparent bg-gradient-to-r from-[#dcdcdc] via-[#7d60bb] to-[#dedede]">
        Innovation and Intellectual Property Rights
      </div>
      <div className="w-full md:w-5/6 lg:w-4/5 py-4 flex flex-row flex-wrap gap-4 items-stretch justify-center">
        {iiprBlog_data.map((data, index) => (
          <div
            key={index}
            className="ring-[1px] ring-psfviolet ring-opacity-50 bg-[#0f091f] hover:bg-[#170e30] p-4 rounded-md w-full max-w-72 flex flex-col justify-between"
          >
            <div className="text-2xl font-semibold">{data.title}</div>
            <div className="flex gap-4 mt-2">
              <button
                onClick={() => handlePreview(index)}
                className="flex items-center gap-2 text-white hover:underline"
              >
                <Eye className="h-4 w-4" />
                {currentPreviewIndex === index ? "Hide" : "Preview"}
              </button>
              <button
                onClick={() => handleDownload(data.document)}
                className="flex items-center gap-2 ring-slate-700 ring-[1px] ring-opacity-75 px-4 py-1 rounded-full hover:underline text-sky-300"
              >
                <Download className="h-4 w-4" />
                Download
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full md:w-5/6 lg:w-3/5">{renderPreview()}</div>
    </div>
  );
};

export default IprBlog;
