import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import AlumniCards from "./AlumniCards";

import twinengg from "../../assets/alumni/twinengg.webp";
import ekatmERP from "../../assets/alumni/ekatmERP.webp";
import surya from "../../assets/alumni/surya.webp";
import proarc from "../../assets/alumni/proarc.webp";
import climeto from "../../assets/alumni/climeto.webp";
import pratham from "../../assets/alumni/pratham.webp";
import natrajmoulds from "../../assets/alumni/natrajmoulds.webp";
import technodry from "../../assets/alumni/technodry.webp";

const blue_bg = "linear-gradient(109.6deg, #1e0950 12%, #070310 100%)";
const gold_bg =
  "radial-gradient(circle farthest-corner at 20% 20%, #00352b 0%, #000f0c 100%)";
const silver_bg =
  "radial-gradient(circle farthest-corner at 60% 80%, #252525 0%, #000000 100%)";
const bronze_bg =
  "radial-gradient(circle farthest-corner at 70% 20%, #5f2e12 0%, #170603 90%)";
const general_bg =
  "radial-gradient(circle farthest-corner at 50% 70%, #1e0950 0%, #070310 90%)";
const footer_gradient = "";

// Alumni Data
const platinum_logo_alumni = [ekatmERP, technodry];

const gold_alumni_names = [
  "Vinayak Pai",
  "Aniket Malshe",
  // Add more names as needed
];

const silver_alumni_names = ["Pradeep Bhalwankar"];

const silver_logo_alumni = [climeto, surya, pratham, twinengg];

const bronze_alumni_names = [
  "Todkar Suresh",
  "Mukesh Malhotra",
  "Yogesh Patll",
  "VARAPRASAD CHIRANJEEV MAILAPALLI",
  "Dilip Bhide",
  "Upendra Sovani",
  "Pradeep Awhad",
  "Amit Subhedar",
  "Natraj Moulds",
  "Sudhir Bhaskar Hikare",
  "Swapnil Sudhakar Kulkarni",
  "Jayant Kurkure",
  "Rahul Patil",
  "Prasad Torvi",
  "Ravindra Shantaram Mahajan",
  "Ravindra Arun Moholkar",
  "Hari Natrajan",
  "Narendra Bhrame",
  "Pro Arc Welding and Cutting Systems Private Limited",
  "Chhagan V Patil",
  "Malhar Punde",
  "Rajit Anand",
  "Kishore Goswami",
  "Anant Takwale",
  "Rajendra Rane",
  // Add more names as needed
];

const bronze_logo_alumni = [proarc, natrajmoulds];

const general_alumni_names = [
  "Shripal Yuresh Gandhi",
  "Vivek Bhalerao Rote",
  "Pankaj Dheer",
  "Dattaray Kulkarni",
  "Sushmita Kunte",
  "Mayuresh Phadke",
  "Swapnil Gedam",
  "Vasanti Vinchulkar",
  "Dr Vishwas Udpikar",
  "Achyut Gopal Vaidya",
  "Priya Suryanarayanan",
  "Sunil Mhatre",
  "Sunay Narkar",
  "Sanket Kothekar",
  "Mayur S",
  "Sarang Pitre",
  "Prakash Vasudeo Sardesai",
  "Jitendra Athane",
  "Conation Technologies Private Limited",
  "Pratik Chaudhari",
  "Kshatriya Manulal Madhavsa",
  "Harshal Rode",
  "Ravi Malhotra",
  "Saurabh Deshmukh",
  "Yashashree Kolhe",
  "Pooja Karandikar",
  "Digambar Lohakare",
  "Bhushan",
  "CHETAN SHAHAJI SHINDE",
  "Suhas Dhotre",
  "Arjun Mangol Kanoth",
  "Anuradha Bhide",
  "Shripad Raj",
  "Ajinkya",
  "Chinmay Dharmadhikari",
  "Vaibhavi Charde",
  "Abhijit Matkar",
  "Rahul Deshmukh",
  "Kalpesh Gadekar",
  "Prashant Bhosale",
  "Nikhil Girish Patwardhan",
  "Chetan Malhotra",
  "Mahesh Patil",
  "Aditya Purkar",
  "Chetan Malhotra",
  "Anjali Madhav Ranade",
  "Poonam Chavan",
  "Anant Takwale",
  // Add more names as needed
];

gsap.registerPlugin(ScrollTrigger);

const Alumni = () => {
  const platinumRef = useRef(null);
  const goldRef = useRef(null);
  const silverRef = useRef(null);
  const bronzeRef = useRef(null);
  const generalRef = useRef(null);
  const bgref = useRef(null);
  const bgImageRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const sections = [
      { ref: platinumRef, image: blue_bg },
      { ref: goldRef, image: gold_bg },
      { ref: silverRef, image: silver_bg },
      { ref: bronzeRef, image: bronze_bg },
      { ref: generalRef, image: general_bg },
      { ref: footerRef, image: footer_gradient },
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = sections.find(
              (s) => s.ref.current === entry.target
            );
            if (section) {
              gsap.to(bgImageRef.current, {
                backgroundImage: section.image,
                opacity: 1,
                duration: 1,
                ease: "easeInOutSine",
              });
              gsap.to(bgref.current, {
                opacity: 0,
                duration: 2,
                ease: "easeInOutQuad",
              });

              gsap.delayedCall(0.5, () => {
                bgref.current.style.backgroundImage = section.image;
                bgref.current.style.opacity = 1;
                bgImageRef.current.style.opacity = 0;
              });
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.forEach((section) => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="text-white relative">
      <div
        className="fixed top-0 left-0 h-screen w-screen -z-20"
        ref={bgref}
        style={{ backgroundSize: "cover", backgroundPosition: "center" }}
      ></div>
      <div
        className="fixed top-0 left-0 h-screen w-screen -z-10 opacity-0"
        ref={bgImageRef}
        style={{ backgroundSize: "cover", backgroundPosition: "center" }}
      ></div>

      <div className="relative z-10">
        <AlumniCards
          ref={platinumRef}
          title="Platinum"
          logo_alumni={platinum_logo_alumni}
          textbg="bg-gradient-to-br from-[#74EBD5] to-[#9FACE6]"
        />

        <AlumniCards
          ref={goldRef}
          title="Gold"
          names={gold_alumni_names}
          textbg="bg-gradient-to-br from-[#FAD961] to-[#F76B1C]"
        />

        <AlumniCards
          ref={silverRef}
          title="Silver"
          names={silver_alumni_names}
          textbg="bg-gradient-to-br from-[#cfd9df] to-[#e2ebf0]"
          logo_alumni={silver_logo_alumni}
        />
        <AlumniCards
          ref={bronzeRef}
          title="Bronze"
          names={bronze_alumni_names}
          textbg="bg-gradient-to-br from-[#f6d365] to-[#fda085]"
          logo_alumni={bronze_logo_alumni}
        />
        <AlumniCards
          ref={generalRef}
          title="General"
          names={general_alumni_names}
          textbg="bg-gradient-to-br from-[#89f7fe] to-[#66a6ff]"
        />
      </div>

      <div ref={footerRef}></div>
    </div>
  );
};

export default Alumni;
