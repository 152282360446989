import AboutCards from "./AboutCards";
import { Biec, Psf, PehchaanCard } from "./AboutCards";

import coep_pic from "../../assets/about/coep_tech.png.webp";
import biel_pic from "../../assets/about/bhau.png.webp";
import iic_pic from "../../assets/about/iic.png.webp";

import biec_pic from "../../assets/about/biec.png.webp";

import psf1 from "../../assets/about/psf1.png.webp";
import psf2 from "../../assets/about/psf2.png.webp";
import psf3 from "../../assets/about/psf5.png.webp";

import pehchaan_pic from "../../assets/about/pehchaan.png.webp";

export default function About() {
  const aboutData = [
    [
      "COEP Tech",
      "COEP Technological University",
      "COEP TECH is the 3rd oldest engineering college in Asia. Established in 1854, this institution has a 170-year-old legacy. The longevity of the success of COEP TECH has been intact due to its strongly laid mission and vision.",
      "To be a Value-based Globally Recognized Institution ensuring academic excellence and fostering Research, Innovation and Entrepreneurial Attitude.",
      "To be a student-centric institute imbibing experiential, innovative and lifelong learning skills, and addressing societal problems. To promote and undertake all-inclusive research and development. To inculcate entrepreneurial attitudes and values amongst learners. To strengthen National and International, Industrial and institutional collaborations for symbiotic relations. To mentor aspiring Institutions to unleash their potential, for nation building.",
      coep_pic,
    ],
    [
      "BIEL",
      "BHAU Innovation, Entrepreneurship and Leadership",
      "COEP Tech's BHAU Institute of Innovation, Entrepreneurship, and Leadership is an emerging start-up incubator in India, established in 2017. This is a Department of Science and Technology, Govt. of India supported Technology Business Incubator (TBI).",
      "Our vision is to be “A successful institute model to change India for the better through young entrepreneurs who are the hope of tomorrow's rural and urban India”. We aim to promote entrepreneurship in a self-supported, sustainable, and scalable manner by creating a world-class institute.",
      "BHAU's focus is to develop successful entrepreneurs in the sector focus areas of Healthcare technologies, Cyber Security, IoT, and grassroot innovations (Agritech, Edutech, Clean technology, etc.) to make a larger social impact.",
      biel_pic,
    ],
    [
      "IIC COEP Tech",
      "Institution's Innovation Council COEP Tech",
      "IIC is an institute-led student activity cell. Various innovation and entrepreneurship-related activities are conducted as recommended by MoE's(Ministry of Education) Innovation Cell under the guidance of industry experts, alumni, director COEP TECH, faculty, patent attorney and experts.",
      "Cultivating Critical Thinking, Inventing Innovations and Encouraging Entrepreneurship for creation of Wealth",
      "To strengthen the IIC in terms of students' and teachers' memberships, by motivating them through curricular component promotional seminars and enthusing events. To organize the MIC-mandated annual series of events and activities such as Idea Competition, B-Plan Competition, Start-up fest, Hackathons, etc. To establish and leverage the network of mentors, tech-preneurs, investors, and facilitators for the benefit of stakeholders of IIC. To empower and facilitate the pre-incubatees, start-ups, first-generation entrepreneurs.",
      iic_pic,
    ],
  ];

  const BIEC_data = [
    "BHAU's Innovation & Entrepreneurship Cell",
    "BIEC is a student-led club, supported by the institute and mentored to promote entrepreneurship among students.",
    "The cell has been organizing various engaging events to inculcate an interest in entrepreneurship and start-ups. For example, IDEA Competition, B-Plan Competition, Case Study Activity, Budgeting, Workshops, and seminars, etc.",
    "Pune Start-Up Fest (PSF) is the annual entrepreneurial festival organized by the BHAU's I&E-Cell of COEP Tech.",
    "COEP TECH's I&E-Cell is a pre-incubation center of COEP TECH and thus provides mentoring to students having ideas or innovations and helping them follow steps like ideation, innovation, proof of concept, business model, prototyping, designing, etc.",
    "Various innovation and entrepreneurship-related activities are conducted as recommended by MoE's (Ministry of Education) Innovation Cell under the guidance of industry experts, alumni, Vice Chancellor COEP Tech, patent attorney, and experts from incubators.",
    biec_pic,
  ];

  const PSF_data = [
    "Pune Start-Up Fest",
    "Pune Start-Up Fest is an annual startup festival organised by BHAU's I&E-Cell, COEP Technological University. It has a startup expo, known as LAUNCHPAD, which stands out as a distinctive startup showcase and provides an excellent platform for numerous students and startups to connect with this ever growing entrepreneurial world.",
    "A variety of events are organised during the fest. Keynote lectures and Talk shows by prominent personalities are arranged to motivate aspiring entrepreneurs.",
    "Startups and students are given huge opportunities to develop entrepreneurial spirit by giving them access to resources and network throughout the community.",
    psf1,
    psf2,
    psf3,
  ];

  const Pehchaan_data = [
    "Pehchaan",
    "'Pehchaan' is a social initiative started by Pune Startup Fest. Through this initiative, Pune Startup Fest has the motive to help small-scale entrepreneurs. The idea behind the name 'Pehchaan' roots in the thought of giving an identity to all such entrepreneurs working on a small level. We come across many such entrepreneurs who create distinctive products on a small base. These creators have less recognition in this world, so with a visionary purpose to give them the recognition that they deserve, we came up with 'Pehchaan'.",
    "Women who make homemade pickles; women who have a great hand at intrinsic sewing; potters who want to mold their way to success and many other entrepreneurs with unique ideas are hidden in every corner of India. Pehchaan works to create a connection between these entrepreneurs and give them guidance to give an identity to their hard work. With 'Pehchaan', in the coming years, we aspire to reach every possible corner and make these undiscovered creators successful.",
    pehchaan_pic,
  ];

  return (
    <div className="flex flex-col gap-16">
      {/* about cards */}
      <div className="flex flex-col gap-16 px-2 mt-12">
        <AboutCards data={aboutData[0]} />
        <AboutCards data={aboutData[1]} />
        <AboutCards data={aboutData[2]} />
      </div>

      <div id="biec">
        {/* biec */}
        <Biec biecData={BIEC_data} />
      </div>

      {/* psf */}
      <Psf PsfData={PSF_data} />

      <div id="pehchaan">
        {/* Pehchaan card */}
        <PehchaanCard PehchaanData={Pehchaan_data} />
      </div>
    </div>
  );
}
