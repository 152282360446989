// firebase-config.js (modular SDK v9+)
import { initializeApp } from 'firebase/app';  // Initialize Firebase app
import { getFirestore, serverTimestamp } from 'firebase/firestore';  // Firestore and server timestamp
import {getMessaging, onMessage, getToken} from 'firebase/messaging'; // Messaging
const firebaseConfig = {
  apiKey: "AIzaSyAkdogXk5YhIEtCts_9R0o3opwN8LJetIE",
  authDomain: "psf25-820b8.firebaseapp.com",
  projectId: "psf25-820b8",
  storageBucket: "psf25-820b8.firebasestorage.app",
  messagingSenderId: "240526482890",
  appId: "1:240526482890:web:e95a39d8072c28194b8333",
  measurementId: "G-C5SEFJT22W"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore
const messaging = getMessaging(app);
export { db, serverTimestamp, app , messaging, getToken, onMessage};  // Export db and serverTimestamp
