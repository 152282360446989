import Connect from "./Connect";
import Socials from "./Socials";
import Invite from "./Invite";
import Organisers from "./Organisers";
import Vision from "./Vision";

import footer_bg from "../../assets/footer.png";

export default function Footer() {
  return (
    <div className="flex flex-col items-center w-screen mt-24">
      <div className="grid grid-cols-1 lg:grid-cols-2 z-30">
        <Connect />

        <div className="flex flex-col gap-4 items-center p-4">
          <Organisers />
          <Vision />
        </div>
      </div>

      <div className="relative w-full h-full">
        <div
          className="min-h-[70vh] w-full bg-center bg-no-repeat bg-cover "
          style={{
            backgroundImage: `url(${footer_bg})`, // Correctly wrap the variable in `url(...)`
          }}
        ></div>

        {/* Gradient overlay for aesthetic effect */}
        <div
          className="absolute inset-0 z-10"
          style={{
            background:
              "linear-gradient(0deg, rgba(7,3,16,1) 0%, rgba(7,3,16,0.5) 25%,  rgba(7,3,16,0.5) 75%, rgba(7,3,16,1) 100%)",
          }}
        ></div>

        <div className="absolute inset-0 z-20 flex flex-col gap-4 items-center justify-center p-4 -mt-[20vh]">
          <Invite />
        </div>
      </div>

      <div className="relative -mt-12 mb-24 lg:mb-8 z-30 flex flex-col-reverse gap-2 lg:flex-row w-full justify-around items-center">
        <p className="text-center">
          &copy; 2025 Pune StartUp Fest. All rights reserved.
        </p>
        <p className="text-center font-light">
          Crafted with care by the Web & Tech Team 💙
        </p>
        <Socials />
      </div>
    </div>
  );
}
