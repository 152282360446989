  import CustomChips from "./CustomChips"

  import coep from '../../assets/patronage_logo/coep-tech-white.png.webp'
  import ecell from '../../assets/patronage_logo/E-Cell-Logo-white.png.webp'

  import agnii from '../../assets/patronage_logo/agnii.png.webp'
  import aicte from '../../assets/patronage_logo/aicte.png.webp'
  import makeinindia from '../../assets/patronage_logo/makeinindia.png.webp'
  import moe from '../../assets/patronage_logo/moe.png.webp'
  import skillindia from '../../assets/patronage_logo/skillIndia.png.webp'
  import startupindia from '../../assets/patronage_logo/startupIndia.png.webp'

  import { motion } from "framer-motion"

  export default function Patronages() {
    
    const patrons = [ coep, ecell, aicte, makeinindia, moe, skillindia, agnii, startupindia ];
    return (

      <div className="flex flex-col items-center gap-2 w-full lg:w-3/5 overflow-hidden">
        <div className="px-4">
          <CustomChips text={"PastPatronages"} />
        </div>
        <div className="marquee relative w-full">
          <div className="absolute inset-0 bg-gradient-to-r from-[#080411] via-transparent to-[#080411] pointer-events-none z-10"></div>

          <motion.div
              className="flex flex-row  gap-8 md:gap-16 w-full justify-center items-center mt-4"
              initial={{ x: 0 }}
              animate={{ x: '-100%' }}
              transition={{
                duration: 32,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              {[...patrons,...patrons,...patrons,].map((logo, index) => (
                <img src={logo} alt="patron logo" key={index} className="h-16 md:h-20 rounded-md"/>
              ))}
          </motion.div>

        </div>

      


      </div>
    )
  }
