import React, { useEffect, useState } from 'react';
import { app } from './../../firebase-config';
import { getFirestore, collection, doc, getDocs, setDoc, getDoc } from 'firebase/firestore';

const db = getFirestore(app);

const ManageStartups = () => {
  const [categories, setCategories] = useState([]);
  const [newStartup, setNewStartup] = useState({
    category: '',
    id: '',
    name: '',
    numVotes: 0,
    voters: [],
    email: '',
    description: '',
    address: '',
    website: '',
    founder: '',
    contactNumber: '',
    fundingStatus: '',
    businessModel: '',
    reference: '',
    stallNum: '',
  });
  const [editStartup, setEditStartup] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [message, setMessage] = useState('');

  const startupFields = [
    { name: 'id', label: 'Startup ID', type: 'text', disabled: !!editStartup },
    { name: 'name', label: 'Startup Name', type: 'text' },
    { name: 'numVotes', label: 'Number of Votes', type: 'number', disabled: true},
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'description', label: 'Description', type: 'textarea' },
    { name: 'address', label: 'Address', type: 'text' },
    { name: 'website', label: 'Website', type: 'url' },
    { name: 'founder', label: 'Founder Name', type: 'text' },
    { name: 'contactNumber', label: 'Contact Number', type: 'text' },
    { name: 'fundingStatus', label: 'Funding Status', type: 'text' },
    { name: 'businessModel', label: 'Business Model', type: 'text' },
    { name: 'reference', label: 'Reference', type: 'text' },
    { name: 'stallNum', label: 'Stall Number', type: 'text' },
  ];

  useEffect(() => {
    const fetchStartups = async () => {
      try {
        const startupsCollection = collection(db, 'LaunchpadStartups');
        const snapshot = await getDocs(startupsCollection);

        const categoriesData = [];
        const categoryNames = [];
        for (const docSnap of snapshot.docs) {
          const categoryName = docSnap.id;
          categoryNames.push(categoryName);
          const startups = docSnap.data();
          const startupList = Object.entries(startups)
            .map(([id, details]) => ({ id, ...details }))
            .sort((a, b) => a.id.localeCompare(b.id)); // Sort by startup ID
          categoriesData.push({ categoryName, startups: startupList });
        }
        setCategories(categoriesData);
        setCategoryOptions(categoryNames);
      } catch (error) {
        console.error('Error fetching startups:', error);
      }
    };

    fetchStartups();
  }, []);

  const handleInputChange = (field, value) => {
    setNewStartup((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddOrUpdateStartup = async () => {
    const missingFields = startupFields.filter(
      (field) => !newStartup[field.name] && !field.disabled
    );

    if (!newStartup.category || missingFields.length > 0) {
      setMessage(`Please fill all fields: ${missingFields.map((f) => f.label).join(', ')}`);
      return;
    }

    try {
      const categoryRef = doc(db, 'LaunchpadStartups', newStartup.category);

      let existingStartupData = {};
      if (editStartup) {
        const categoryDoc = await getDoc(categoryRef);
        existingStartupData = categoryDoc.exists()
          ? categoryDoc.data()[newStartup.id] || {}
          : {};
      }

      const updatedData = {
        [newStartup.id]: {
          ...newStartup,
          numVotes: existingStartupData.numVotes || 0, // Preserve original numVotes
        },
      };

      await setDoc(categoryRef, updatedData, { merge: true });
      setMessage(editStartup ? 'Startup updated successfully!' : 'Startup added successfully!');
      setNewStartup({
        category: '',
        id: '',
        name: '',
        numVotes: 0,
        voters: [],
        email: '',
        description: '',
        address: '',
        website: '',
        founder: '',
        contactNumber: '',
        fundingStatus: '',
        businessModel: '',
        reference: '',
        stallNum: '',
      });
      setEditStartup(null);
      window.location.reload();
    } catch (error) {
      console.error('Error adding/updating startup:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  const handleEdit = (categoryName, startup) => {
    setEditStartup(startup);
    setNewStartup({ ...startup, category: categoryName });
  };

  return (
    <div className="pt-20 p-8 bg-psfblack font-psfsans">
      <h1 className="text-3xl font-bold mb-6 text-center text-psfviolet">Startup Management</h1>
      <div className="mb-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-psfwhite">{editStartup ? 'Edit Startup' : 'Add New Startup'}</h2>

        <select
          className={`p-2 border rounded mr-2 my-4 bg-white text-black block`}
          value={newStartup.category}
          onChange={(e) => handleInputChange('category', e.target.value)}
        >
          <option value="">Select Category</option>
          {categoryOptions.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>

        {startupFields.map((field) => (
          <div key={field.name} className="mb-4">
            <label htmlFor={field.name} className="text-white inline-block">{field.label}</label>
            {field.type === 'textarea' ? (
              <textarea
                placeholder={field.label}
                className={`p-2 border text-black rounded mr-2 block`}
                value={newStartup[field.name]}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
              />
            ) : (
              <input
                type={field.type}
                placeholder={field.label}
                className={`p-2 border text-black rounded mr-2 block`}
                value={newStartup[field.name]}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                disabled={field.disabled}
              />
            )}
          </div>
        ))}

        <button
          className={`p-2 bg-psfviolet my-4 text-white rounded hover:bg-blue-600 block`}
          onClick={handleAddOrUpdateStartup}
        >
          {editStartup ? 'Update Startup' : 'Add Startup'}
        </button>

        {editStartup && (
          <button
            className={`p-2 bg-red-600 my-4 text-black rounded hover:bg-blue-600 block`}
            onClick={() => {
              setEditStartup(null);
              setNewStartup({
                category: '',
                id: '',
                name: '',
                numVotes: 0,
                voters: [],
                email: '',
                description: '',
                address: '',
                website: '',
                founder: '',
                contactNumber: '',
                fundingStatus: '',
                businessModel: '',
                reference: '',
                stallNum: '',
              });
              setMessage('');
            }}
          >
            Cancel Edit
          </button>
        )}

        {message && <p className={`mt-4 text-psfwhite`}>*{message}</p>}
      </div>

      {categories.map((category) => (
        <div key={category.categoryName} className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 font-serif text-psfviolet`}>{category.categoryName}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {category.startups.map((startup) => (
              <div
                key={startup.id}
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
              >
                <h3 className="text-xl font-bold mb-2 text-psfdark">
                  {startup.id}. {startup.name}
                </h3>
                <p className="text-psfdark mb-2">Stall number: {startup.stallNum}</p>
                <button
                  className={`mt-2 p-2 bg-psfviolet text-white rounded hover:bg-psfblue`}
                  onClick={() => handleEdit(category.categoryName, startup)}
                >
                  Edit
                </button>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ManageStartups;