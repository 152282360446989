import React, { useState, useEffect } from 'react';
import { db } from '../../firebase-config';
import { 
  collection, 
  onSnapshot, 
  query, 
  where, 
  doc, 
  getDocs, 
  getDoc,
  updateDoc,
  arrayUnion 
} from 'firebase/firestore';
import { Timer, Send } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Voting = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [activeStartup, setActiveStartup] = useState(null);
  const [userVote, setUserVote] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const clearSession = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('deviceId');
    setUser(null);
    navigate('/login');
  };

  useEffect(() => {
    const validateUser = async () => {
      const storedDeviceId = localStorage.getItem('deviceId');
      if (!storedDeviceId) {
        clearSession();
        return;
      }

      try {
        // Find the user by device ID
        const usersRef = collection(db, 'lpusers');
        const deviceIdQuery = query(usersRef, where('deviceId', '==', storedDeviceId));
        const querySnapshot = await getDocs(deviceIdQuery);

        if (querySnapshot.empty) {
          clearSession();
          return;
        }

        // Set user data
        const userData = querySnapshot.docs[0].data();
        setUser({ username: userData.username, userId: querySnapshot.docs[0].id });
      } catch (error) {
        console.error('Error validating user:', error);
        clearSession();
      }
    };

    validateUser();

    // Listen for active startup
    const startupQuery = query(collection(db, 'lpstartups'), where('active', '==', true));
    const unsubStartup = onSnapshot(startupQuery, (snapshot) => {
      if (!snapshot.empty) {
        const startup = snapshot.docs[0].data();
        setActiveStartup({ id: snapshot.docs[0].id, ...startup });
        setMessage(''); // Clear the success message when a new pitch is detected
      } else {
        setActiveStartup(null);
      }
    });

    // Listen for timer
    const timerRef = doc(db, 'timer', 'current');
    const unsubTimer = onSnapshot(timerRef, (doc) => {
      if (doc.exists()) {
        setTimeLeft(doc.data().time);
      }
    });

    return () => {
      unsubStartup();
      unsubTimer();
    };
  }, [navigate]);

  const handleVote = async () => {
    if (!userVote || !activeStartup || !user) {
      navigate('/login');
      return;
    }

    setLoading(true);
    try {
      const userRef = doc(db, 'lpusers', user.userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        setMessage('User session invalid. Please log in again.');
        clearSession();
        return;
      }

      const userData = userDoc.data();
      const existingGuesses = userData.guesses || [];

      // Check if a vote already exists for the current startup
      const hasVoted = existingGuesses.some(
        (guess) => guess.startupId === activeStartup.id
      );

      if (hasVoted) {
        setMessage('You have already voted for this startup.');
        setLoading(false);
        return;
      }

      // Record the vote
      await updateDoc(userRef, {
        guesses: arrayUnion({
          startupId: activeStartup.id,
          guess: parseInt(userVote),
          timestamp: new Date().toISOString(),
        }),
      });

      setUserVote('');
      setMessage('Vote submitted successfully!');
    } catch (error) {
      console.error('Error submitting vote:', error);
      setMessage('Failed to submit vote. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-white">Lightning Pitch Voting</h1>
        <button 
          onClick={() => {
            localStorage.clear();
            navigate('/login');
          }}
          className="px-4 py-2 bg-red-600 text-black rounded-md"
        >
          Logout
        </button>
      </div>
      
      {activeStartup ? (
        <div className="bg-white rounded-lg shadow p-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-black mb-2">
              Current Pitch: {activeStartup.name}
            </h2>
            <div className="flex items-center gap-2 text-lg text-blue-600">
              <Timer className="w-5 h-5" />
              <span className="text-black">Time Remaining: {timeLeft}s</span>
            </div>
          </div>
          
          <div className="space-y-4">
            <input
              type="number"
              value={userVote}
              onChange={(e) => setUserVote(e.target.value)}
              placeholder="Enter number of investors"
              className="w-full px-4 py-2 border rounded-md text-black"
            />
            <button
              onClick={handleVote}
              disabled={loading || !userVote}
              className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 disabled:opacity-50"
            >
              <Send className="w-4 h-4" />
              Submit Vote
            </button>
          </div>

          {message && (
            <div className="mt-4 p-4 bg-blue-50 text-blue-700 rounded-md">
              {message}
            </div>
          )}
        </div>
      ) : (
        <div className="text-center text-gray-600 bg-white rounded-lg shadow p-6">
          No active pitch voting session.
        </div>
      )}
    </div>
  );
};

export default Voting;
