import React, { useState } from "react";
import { db } from "../../firebase-config";  // Import Firebase Firestore functions
import { collection, getDocs } from "firebase/firestore";

const Checklist = () => {
  const [investors, setInvestors] = useState(	["Muralidharan Ramachandran", "Harsh Patel", "Sachin Deshpande", "Sriram Sastrigal", "Prasant Naigaonkar", "ketan premani", "tushar kansal", "santosh y", "ketharaman s.png", "Rushabh Shah", "prashant gupta", "rohit lalwani", "Ayush Taneja", "Rohan Shrisrimal", "Shailesh Kaul", "rinoo rajesh", "vishesh rajaram.png", "Animesh Samuel", "nirmesh prakash", "Lotan (Vishwajeet) Gawale", "Arjun Panchal", "chirag pungaliya", "Aditya Narendra", "Gaurav Navani", "santosh kotnis.jpeg", "vishwajeet", "Kartik Jain", "dr mohit dubey", "mahesh kulkarni", "rajeev", "Jayant Kumar", "rohan patverdhan", "syed", "ratul lahari", "Atul Shah", "ankush ghoush.png", "suren kulkarni", "alhad deshpande", "Ankush Shah", "kunal sinha.png", "pradeep motvani", "agnel d", "ashish banka.png", "ity jain", "nishant oberoi", "sanket deshpande", "sanjay pekam", "pratik vyas", "Dhiraj Ostwal", "Manish Gandhi-1", "aasma shaikh", "gaurav navani", "ishika sinha", "Ketan kataria", "Harsh Rajnikant Patel", "Sujit Bhatevara", "Honish Zaveri ", "Vineet Agarwal", "Advait kurlekar.png", "Vinayak Nath", "rishabh raj", "veynkat iyer", "Pragalbh Thakur", "Kaustubh Bagalkote", "Girish Deshpande", "Ved Salvi .JPG", "Rishabh Randive", "Shashank Despande", "Isha Ostwal", "Saurabh Singhavi", "Pradeep Kumar Bahuguna", "Arvinder Sachdev", "Gaurav Anand", "Amol Mujumdar ", "Aman Tekriwal", "Gururaj Dangare", "Deepak Patil", "Vinay Peshwa ", "Paresh Madani", "Bharat Oswal"]); // Array of investor names
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedInvestors, setSelectedInvestors] = useState([]);

  // Handle checkbox toggle
  const toggleCheckbox = (investorName) => {
    setSelectedInvestors((prevSelected) =>
      prevSelected.includes(investorName)
        ? prevSelected.filter((name) => name !== investorName)
        : [...prevSelected, investorName]
    );
  };

  // Handle sending notification
  const handleSendNotification = async (investorName) => {
    try {
      // Fetch all tokens from the TOKENS_IN collection
      const tokensCollection = collection(db, "startupRequests");
      const tokensSnapshot = await getDocs(tokensCollection);
      
      if (tokensSnapshot.empty) {
        alert("No FCM tokens found in the database!");
        return;
      }

      const tokens = tokensSnapshot.docs.map(doc => doc.data().token);
      const notificationImage = "https://raw.githubusercontent.com/vaish-navi24/Images_Notifications/main/new.jpg";
 tokens.push('dpj2SL8oDK_LvwY4DHdIEG:APA91bGfVjUGP6vWpyC0YJLTxXQpIfyFVR_-29r5cMsQwVsw9TuqWclfCdlQDL-o4LLUAAhmdug5DjHBziIr6N2evaDB2K5MxqUbw1Cv3Z7lizGRD2yYFMU')
      // Iterate through the tokens and send the notification
      const notifications = tokens.map(async (token) => {
        const response = await fetch("https://psf25-deploy.onrender.com/notify", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: "Investor Arrival",
            body: `${investorName} has arrived on campus.`,
            token,
            image: notificationImage, 
          }),
        });

        return response.ok;
      });

      // Wait for all notifications to be sent
      const results = await Promise.all(notifications);
      //console.log("Sending notification to token:", token);

      if (results.every((status) => status)) {
        alert("Notifications sent successfully to all users!");
      } else {
        alert("Some notifications failed to send.");
      }
    } catch (error) {
      console.error("Error sending notifications:", error);
      alert("Error sending notifications.");
    }
  };

  // Filter investors based on the search query
  const filteredInvestors = investors.filter((investor) =>
    investor.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto p-6 bg-gray-100 min-h-screen ">
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-800 mt-20">
        Investor Checklist
      </h1>

      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          className="border rounded-lg p-3 w-full shadow focus:ring focus:ring-psfdark text-psfdark"
          placeholder="Search investors..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Checklist */}
      <ul className="space-y-4">
        {filteredInvestors.map((investor, index) => (
          <li
            key={index}
            className="flex items-center justify-between border p-4 rounded-lg shadow-md bg-white hover:bg-gray-50 transition-all"
          >
            <div className="flex items-center space-x-4">
              <input
                type="checkbox"
                checked={selectedInvestors.includes(investor)}
                onChange={() => toggleCheckbox(investor)}
                className="w-5 h-5 text-blue-500 focus:ring focus:ring-blue-300"
              />
              <span className="text-gray-800 font-medium">{investor}</span>
            </div>
            <button
              className="bg-blue-600 text-white px-5 py-2 rounded-lg shadow hover:bg-blue-700 transition-all"
              onClick={() => handleSendNotification(investor)}
            >
              Send
            </button>
          </li>
        ))}
      </ul>

      {/* No Results */}
      {filteredInvestors.length === 0 && (
        <p className="text-center text-gray-500 mt-6">
          No investors match your search.
        </p>
      )}
    </div>
  );
};

export default Checklist;
