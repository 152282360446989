import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopMenu from "./topMenu";
import ComingSoon from "../../ComingSoon";
import MasterclassGrid from "./masterclasses";
import EventsGrid from "./events";
import RegistrationForm from "./visitors2";
import LaunchpadForm from "./launchpad";

export default function Registrations({ defaultSection = "masterclass" }) {
  const [activeSection, setActiveSection] = useState(defaultSection);
  const [contentKey, setContentKey] = useState(0);
  const navigate = useNavigate();

  // Update URL when section changes
  useEffect(() => {
    navigate(`/register/${activeSection}`, { replace: true });
  }, [activeSection, navigate]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setContentKey((prev) => prev + 1);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "masterclass":
        return <MasterclassGrid />;
      case "events":
        return <EventsGrid />;
      case "visitors":
        return <RegistrationForm />;
      case "launchpad":
        return <LaunchpadForm />;
      default:
        return <ComingSoon />;
    }
  };

  return (
    <div className="pt-3">
      <TopMenu
        onSectionChange={handleSectionChange}
        activeSection={activeSection}
      />
      <div className="p-4 md:p-8" key={contentKey}>
        {renderContent()}
      </div>
    </div>
  );
}
