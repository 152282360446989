import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase-config';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const UserLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [deviceId, setDeviceId] = useState('');

  useEffect(() => {
    const initAuth = async () => {
      try {
        // Sign in anonymously
        const auth = getAuth();
        await signInAnonymously(auth);

        // Generate unique device ID
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const generatedDeviceId = result.visitorId;
        setDeviceId(generatedDeviceId);

        // Check Firestore for existing user with this device ID
        const usersRef = collection(db, 'lpusers');
        const q = query(usersRef, where('deviceId', '==', generatedDeviceId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // User already exists, log them in
          const userData = querySnapshot.docs[0].data();
          localStorage.setItem('username', userData.username);
          localStorage.setItem('deviceId', generatedDeviceId);
          navigate('/voting');
          return;
        }

        // If no user is found, wait for the user to log in manually
      } catch (err) {
        console.error('Auth initialization error:', err);
        setError('Failed to initialize authentication');
      }
    };

    initAuth();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (!username.trim()) {
        throw new Error('Username is required');
      }

      // Check if the username is already in use
      const usersRef = collection(db, 'lpusers');
      const usernameQuery = query(usersRef, where('username', '==', username.trim()));
      const usernameSnapshot = await getDocs(usernameQuery);

      if (!usernameSnapshot.empty) {
        const existingUser = usernameSnapshot.docs[0].data();
        if (existingUser.deviceId && existingUser.deviceId !== deviceId) {
          throw new Error('This username is already registered on another device');
        }
      }

      // Create or update the user
      await addDoc(usersRef, {
        username: username.trim(),
        deviceId,
        createdAt: new Date().toISOString(),
        score: 0,
        guesses: []
      });

      localStorage.setItem('username', username.trim());
      localStorage.setItem('deviceId', deviceId);
      navigate('/voting');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div classname="flex justify-center items-center  ">
      <div className="max-w-md mx-auto p-8 bg-white rounded-xl shadow-xl ring-1 ring-gray-900/5">
        <h2 className="text-3xl font-bold text-center text-purple-600 mb-6 pt-24 ">Join Voting Session</h2>
        <form onSubmit={handleLogin} className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="username" className="block text-lg font-medium text-gray-700">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              className="w-full px-4 py-3 border text-black border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              required
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full bg-purple-600 text-white py-3 rounded-lg hover:bg-purple-700 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            {loading ? 'Loading...' : 'Start Voting'}
          </button>

          {error && <p className="text-red-600 text-sm text-center">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default UserLogin;
