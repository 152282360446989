import React from "react";

import StatsCard from "./StatsCard";
export default function Stats() {
  return (
    <div className="grid grid-cols-2 z-10 lg:grid-cols-4 w-full md:w-3/5 md:justify-between p-4 bg-[#070310] rounded-t-3xl">
      <StatsCard title={"Engaged Visitors"} stat={"15K+"} />
      <StatsCard title={"StartUps annually"} stat={"100+"} />
      <StatsCard title={"Onboard Investors"} stat={"120"} />
      <StatsCard title={"Total Capital infused"} stat={"16 Cr."} />
    </div>
  );
}
