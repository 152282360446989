import { useRef } from "react";
import { useInView } from "framer-motion";

export default function MasterclassCards({
  image_path,
  masterclass,
  speaker,
  bg,
}) {
  const words = masterclass.split(" "); // Split the masterclass string into words

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateX(+100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
      }}
      className="w-11/12 md:w-4/5 lg:w-3/5 h-24 md:h-32 lg:h-40 rounded-xl  ring-[1px] ring-psfviolet ring-opacity-50 bg-psfviolet bg-opacity-5 flex flex-row justify-between p-1"
    >
      <div
        className="masterclass_title flex-1 w-4/6 h-full flex flex-col items-stretch justify-center relative rounded-l-lg"
        style={{
          background: bg, // Apply the dynamic background gradient
        }}
      >
        <div className="flex flex-row gap-1 md:gap-2 justify-center z-20">
          <p className="font-masterclass font-[700] text-2xl md:text-4xl lg:text-6xl">
            {words[0] || ""}
          </p>
          <p className="font-masterclass font-[400] italic text-2xl md:text-4xl lg:text-6xl">
            {words[1] || ""}
          </p>
        </div>

        <div className="text-center lg:pl-32 text-lg  md:text-xl lg:text-3xl font-normal z-20">
          {speaker}
        </div>

        {/* aesthethic effffffect */}
        <div
          className="absolute inset-0 z-10"
          style={{
            background:
              "linear-gradient(90deg, rgba(13,6,26,1) 0%, rgba(13,6,26,0.25) 50%, rgba(13,6,26,1) 100%)",
          }}
        ></div>
      </div>

      <div className="master_image flex-1 relative">
        <div
          className="w-full h-full bg-cover bg-center rounded-lg overflow-hidden"
          style={{ backgroundImage: `url(${image_path})` }}
        ></div>

        {/* aesthethic effffffect */}
        <div
          className="absolute inset-0 z-10"
          style={{
            background:
              "linear-gradient(90deg, rgba(13,6,26,1) 0%, rgba(13,6,26,1) 0%, rgba(13,6,26,0) 50%, rgba(13,6,26,1) 100%)",
          }}
        ></div>
      </div>
    </div>
  );
}

export function MasterclassCardPrevious({ image_path, masterclass, speaker }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="flex flex-col  ring-psfviolet ring-[1px] ring-opacity-50 p-4 rounded-xl bg-[#110721] hover:bg-[#1f0d3a]"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(+20px)",
        opacity: isInView ? 1 : 0,
        transition: "all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
      }}
    >
      <img
        src={image_path}
        alt="masterclass speaker"
        className="h-32 lg:h-52 w-60 lg:w-96 rounded-lg"
      />
      <p className="font-psfsans font-semibold text-center text-2xl mt-2">
        <strong>{masterclass}</strong>
      </p>
      <p className="font-psfsans font-light text-center text-lg">({speaker})</p>
    </div>
  );
}
