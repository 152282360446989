import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { app } from '../../firebase-config';
import { getAuth, signInAnonymously } from 'firebase/auth';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const db = getFirestore(app);
const auth = getAuth(app);

const Launchpad = () => {
    const [startups, setStartups] = useState([]);
    const [userId, setUserId] = useState('');

    const authenticateUser = async () => {
        try {
            // Ensure user is signed in anonymously
            const result = await signInAnonymously(auth);
            const uid = result.user.uid;

            // Generate fingerprint for additional verification
            const fp = await FingerprintJS.load();
            const fingerprintResult = await fp.get();
            const fingerprint = fingerprintResult.visitorId;

            // Reference Firestore document based on fingerprint
            const userRef = doc(db, 'LaunchpadUsers', fingerprint);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                // Use the stored UID from Firestore if fingerprint exists
                setUserId(userDoc.data().uid);
            } else {
                // Store the UID associated with the fingerprint
                await setDoc(userRef, { uid });
                setUserId(uid);
            }
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    useEffect(() => {
        const init = async () => {
            await authenticateUser();
        };
        init();
    }, []);

    useEffect(() => {
        if (!userId) return;

        const fetchStartups = async () => {
            try {
                const categoriesSnapshot = await getDocs(collection(db, 'LaunchpadStartups'));
                const startupsData = [];

                for (const categoryDoc of categoriesSnapshot.docs) {
                    const category = categoryDoc.id;
                    const categoryRef = doc(db, 'LaunchpadStartups', category);
                    const categoryData = (await getDoc(categoryRef)).data();

                    if (categoryData) {
                        Object.keys(categoryData).forEach((startupID) => {
                            const startupDetails = categoryData[startupID];
                            startupsData.push({
                                category,
                                startupID,
                                name: startupDetails.name,
                                numVotes: startupDetails.numVotes,
                                voters: startupDetails.voters || [],
                            });
                        });
                    }
                }

                setStartups(startupsData);
            } catch (error) {
                console.error('Error fetching startups:', error);
            }
        };

        if (userId) fetchStartups();
    }, [userId]);

    const handleVote = async (category, startupID) => {
        try {
            if (!userId) return;

            const currentStartup = startups.find(
                (s) => s.category === category && s.startupID === startupID
            );

            if (currentStartup.voters.includes(userId)) return;

            const previousStartup = startups.find(
                (s) => s.category === category && s.voters.includes(userId)
            );

            const categoryRef = doc(db, 'LaunchpadStartups', category);

            if (previousStartup) {
                const prevStartupID = previousStartup.startupID;
                await updateDoc(categoryRef, {
                    [`${prevStartupID}.numVotes`]: previousStartup.numVotes - 1,
                    [`${prevStartupID}.voters`]: previousStartup.voters.filter((id) => id !== userId),
                });
            }

            await updateDoc(categoryRef, {
                [`${startupID}.numVotes`]: currentStartup.numVotes + 1,
                [`${startupID}.voters`]: [...currentStartup.voters, userId],
            });

            setStartups((prevStartups) =>
                prevStartups.map((s) => {
                    if (s.category === category) {
                        if (s.startupID === startupID) {
                            return { ...s, numVotes: s.numVotes + 1, voters: [...s.voters, userId] };
                        }
                        if (s.startupID === previousStartup?.startupID) {
                            return {
                                ...s,
                                numVotes: s.numVotes - 1,
                                voters: s.voters.filter((id) => id !== userId),
                            };
                        }
                    }
                    return s;
                })
            );
        } catch (error) {
            console.error('Error updating vote:', error);
        }
    };

    const categories = [...new Set(startups.map((s) => s.category))];

    return (
        <div>
            <div className="bg-psfblack min-h-screen p-6">
                <h1 className="text-3xl font-psfserif bg-gradient-to-l from-psfblue to-psfviolet mb-6 text-center rounded-md">
                    Startup Zone Voting
                </h1>
                {categories.map((category) => (
                    <div key={category} className="mb-8">
                        <h2 className="py-4 text-2xl font-psfserif text-psfwhite mb-4 inline rounded-full underline underline-offset-4">
                            {category}
                        </h2>
                        <div className="flex gap-8 flex-wrap my-4">
                            {startups
                                .filter((startup) => startup.category === category)
                                .map((startup) => (
                                    <div
                                        key={startup.startupID}
                                        className={`shadow-lg border-2 rounded-md p-4 w-full sm:w-1/4 lg:w-1/5 ${
                                            startup.voters.includes(userId)
                                                ? 'shadow-psfviolet border-psfviolet border-2'
                                                : 'border-psfgrey  hover:shadow-psfblue hover:border-psfblue'
                                        }`}
                                    >
                                        <h3
                                            className={`text-xl font-psfsans font-medium ${
                                                startup.voters.includes(userId)
                                                    ? 'text-psfviolet'
                                                    : 'text-psfwhite'
                                            }`}
                                        >
                                            {startup.name}
                                        </h3>
                                        <p className="text-psfwhite">Startup ID: {startup.startupID}</p>
                                        <p className="text-psfwhite mb-4">Votes: {startup.numVotes}</p>
                                        <button
                                            onClick={() => handleVote(startup.category, startup.startupID)}
                                            className={`text-psfwhite border-2 px-4 py-2 rounded-md ${
                                                startup.voters.includes(userId)
                                                    ? 'bg-psfviolet border-psfviolet border-2'
                                                    : 'border-psfgrey hover:border-psfblue hover:shadow-md hover:bg-psfblue hover:text-psfwhite transition duration-100'
                                            }`}
                                        >
                                            Vote
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Launchpad;
