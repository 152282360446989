import React from "react";
import { useRef } from "react";
import { useInView } from "framer-motion";

export default function TimelineCard({ data }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className="w-60 lg:w-[25vw] pb-12 lg:min-h-96  font-psfsans"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateX(+50px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
      }}
    >
      <ol className="px-4">
        <li className="list-disc text-lg font-medium lg:text-xl">
          {data.startup_count} Startups
        </li>
        <li className="list-disc text-lg font-medium lg:text-xl">
          {data.investors} Investors
        </li>
        <li className="list-disc text-lg font-medium lg:text-xl">
          {data.internship_opportunities} Internship Opportunities
        </li>
        <div className="text-wrap mt-4">
          <div className="flex flex-col gap-2">
            <p className="text-lg font-medium lg:text-xl">New Events :</p>
            <p className="-mt-2 font-normal text-slate-300 pl-4">
              {data.new_events.join(", ")}
            </p>
          </div>

          {data.removed_events.length > 0 && (
            <div className="flex flex-col gap-2 mt-4">
              <p className="text-lg font-medium lg:text-xl">
                Events Dissolved :
              </p>
              <p className="-mt-2 font-normal text-slate-300 pl-4">
                {data.removed_events.join(", ")}
              </p>
            </div>
          )}
        </div>
      </ol>
    </div>
  );
}

export function TimelineThemeCard({ year, theme, stmt }) {
  return (
    <div className="w-32 md:w-60 lg:w-[25vw] min-h-12 flex flex-col items-start lg:items-end text-right font-psfsans mb-4 ">
      <h2 className="font-bold text-5xl leading-none text-left lg:text-right font-playfair">
        {year}
      </h2>
      <h3 className="font-bold text-xl lg:text-3xl text-wrap text-left lg:text-right w-max leading-tight font-alumni text-white">
        {theme}
      </h3>
      <p className="font-normal text-base lg:text-xl w-full text-left lg:text-right text-slate-400">
        {stmt}
      </p>
    </div>
  );
}
