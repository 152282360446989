import React from "react";
import InternshipCard from "./InternshipCard";

const internships = [
    { name: "Hiren Sanghavi", StartupName: "SICA INDIA", DescriptionOfStartup: "Innovative Automation Equipments ", StartupLogo: "https://drive.google.com/open?id=1XMnrjUk49fD56GnHfxpe0X9S2SE92v1-", InternshipOpportunityURL: "http://www.sica.in", InternshipDomain: "Automation Mechanical Engineer ", TenureOfInternship: "1 Year", ModeOfInternship: "Offline", Location: "Pune, Mumbai, Vadodara, Ahmedabad, Bengaluru", Salary: "Rs. 10,000 - 20,000", Role: "Technical & Marketing ", email: "hiren@sica.in" },
    { name: "Ram Agarwal", StartupName: "Teknoforge Innovations LLP", DescriptionOfStartup: "Teknoforge Innovations is your Design, Engineering, and Prototyping partner with its office registered in Pune, India. At Teknoforge Innovations, we provide Reverse Engineering, New Product Design & Development, Industrial Design, CAD, 3D Modeling, Rendering, Animation, and 3D Printing. We are also set to launch our new line of products catering to industries and businesses with small-scale manufacturing needs.", StartupLogo: "https://drive.google.com/open?id=1CJeciuFtFWgB2K-HwdywAU_W6RFG1gp1", InternshipOpportunityURL: "https://www.linkedin.com/jobs/view/4114661194 ", InternshipDomain: "Engineering", TenureOfInternship: "6 Months", ModeOfInternship: "Offline", Location: "Pune", Salary: "Rs. 1000 - 10,000", Role: "Product Design and CAD Intern, R&D Intern, CAE Simulation Intern", email: "director@teknoforgeinnovations.com" },
    { name: "Swapnil Jadhav", StartupName: "Chocoelite ", DescriptionOfStartup: "Chocoelite is a startup of Converting fruit in to Chocolates without any artificial colour or flavour. So that customers can enjoy seasonal fruits in the form of chocolate throughout the year with benefits of fruits and joy of chocolate.", StartupLogo: "https://drive.google.com/open?id=1BXXmhQYEISsDfdqb0KLJQUVNf4ERGGoZ", InternshipOpportunityURL: "Working Offline", InternshipDomain: "No Domain as of now", TenureOfInternship: "Can Discuss Further", ModeOfInternship: "Offline", Location: "Shah industrial estate, Manikbaug, Sinhgad Road, Pune.", Salary: "Rs. 10,000 - 20,000", Role: "Digital marketing and Physical marketing. ", email: "yash_agrodairyfoodpark@rediffmail" },
    { name: "Om Surve", StartupName: "Ayu ", DescriptionOfStartup: "Ayu is an AI-powered healthcare app that transforms paper medical records into an organized digital timeline through QR codes, OCR, and voice commands in Indian languages, making medical history instantly accessible, intelligent & universal for both patients and healthcare providers.", StartupLogo: "https://drive.google.com/open?id=1rlV6Qu9UNk5RhHnhRAVcTcFa5wNeixFh", InternshipOpportunityURL: "https://docs.google.com/document/d/1m-k7PQY09OtDU1zA-BlCRWvFqg9O6Re163w5-XO5yYU/edit?usp=sharing", InternshipDomain: "React Native, AI, App & Web development ", ModeOfInternship: "Offline", TenureOfInternship: "3 months", Location: "New Panvel", Salary: "Rs. 10,000 - 20,000", Role: "App Developer React Native | AI Developer ", email: "om@ayuapp.com" },
];

const InternshipList = () => {
    return (
        <div className="bg-[#0F0F1F] min-h-screen py-12 px-6 text-white">
            <h1 className="text-4xl font-bold mb-8 text-center mt-24 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
                Internship Opportunities
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto ">
                {internships.map((internship, index) => (
                    <InternshipCard key={index} internship={internship} />
                ))}
            </div>
        </div>
    );
};

export default InternshipList;
