import CustomChips from "./CustomChips";
import Connect_bg from "../../assets/connect_bg.svg";

import ConnectCards from "./ConnectCards";
import { ConnectSec } from "./ConnectCards";

export default function Connect() {
  return (
    <div className="flex flex-col gap-6 w-full items-center p-6">
      <CustomChips text="Connect with Us" />

      <p className="text-3xl md:text-5xl font-semibold text-center">
        Feel Free to Reach Out
      </p>
      <div
        className="flex flex-col items-center gap-4 p-2 md:p-8 rounded-lg shadow-lg bg-contain bg-no-repeat bg-center"
        style={{
          backgroundImage: `url(${Connect_bg})`,
        }}
      >
        <p className="text-sm md:text-lg max-w-2xl text-center -mt-4">
          Our team is dedicated to providing you with the support and guidance
          you need. Whether you have questions about our services, feedback to
          share, or are looking for collaboration opportunities, we’re here to
          help.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 mt-4">
          <ConnectCards
            position={"Finance and Sponsorship Executive"}
            mail={"finance.psf25@gmail.com"}
          />
          <ConnectCards
            position={"Investor Relations Executive"}
            mail={"ir.psf@coeptech.ac.in"}
          />
          <ConnectCards
            position={"Public Relations"}
            mail={"publicrelations.biec@gmail.com"}
          />
          <ConnectCards
            position={"Events and Networking Executive"}
            mail={"events.bec@gmail.com"}
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <ConnectSec
            name={"Deep Ghadia"}
            position={"Secretary"}
            phone={"+91 9022476322"}
            mail={"secretary.psf@coeptech.ac.in"}
          />

          <ConnectSec
            name={"Piyush Ladda"}
            position={"Joint Secretary"}
            phone={"+91 8637782364"}
            mail={"bec@coeptech.ac.in"}
          />
        </div>
      </div>
    </div>
  );
}


