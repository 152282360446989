import React, { useState} from 'react';
import Masonry from 'react-masonry-css';
import './MasonryGrid.css'; // Create this CSS file as per the styles below
import { FaDownload } from 'react-icons/fa';

const imageData =  [
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09364.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/image%20(20).jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09404.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09411%20(2).JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09411.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09522.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09547.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0013.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP09096.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0026.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0035.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0040.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0044.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0046.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A0053.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9237.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9479.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9481.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9515.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9520.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9522.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9529.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/20240124_184321.jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9530.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9536.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9542.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9553.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9558.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9561.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9563.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9565.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9575.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9578.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9579.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9582.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9592.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9598.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9603.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9624.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9639.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9641.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9643.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9646.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/20240124_193512.jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9649.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9656.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9658%20(1).JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9658.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9659%20(1).JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9659.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9663%20(1).JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9663.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9666.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9673.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9674.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9680.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9689.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9690.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9692.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/20240124_190700.jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9727.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9768.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9771.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9786.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9837.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9898.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9913.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9918.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9938%20(1).JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9938.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9946.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9947.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9949.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9961.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9966%20(1).JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9966.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/328A9993.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/C0008T01.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG20240128112258.jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG20240128112318.jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_0657.jpeg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_0673.jpeg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_0689.jpeg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_0693.jpeg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_0717.jpeg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_0724.jpeg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_3164.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_3282.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4954.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4959.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4963.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4966.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4970.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4975.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/IMG_4981.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08758.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08766.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08773.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08776.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08781.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08808.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08809.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08815.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08859.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08866.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/20240124_184847.jpg',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08869.JPG',
  'https://raw.githubusercontent.com/psfwebtech/psf24_gallery/main/Images/MMP08884.JPG'
];

const MasonryGrid = () => {
  const [visibleImages, setVisibleImages] = useState(20); // Initially display first 20 images
  const slotSize = 20; // Number of images to load per slot

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const downloadImage = async (src) => {
    try {
      const response = await fetch(src, {
        mode: 'cors',
      });
      const blob = await response.blob();
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = 'image.jpg'; // You can customize the file name based on index or other parameters
      link.click();
      window.URL.revokeObjectURL(url); // Clean up the object URL after download
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const loadMoreImages = () => {
    // Increment visible images by slotSize, but cap it at the total length of imageData
    setVisibleImages((prevVisible) =>
      Math.min(prevVisible + slotSize, imageData.length)
    );
  };

  return (
    <div className="masonry-wrapper">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {imageData.slice(0, visibleImages).map((src, index) => (
          <div key={index} className="grid-item">
            <div className="image-container">
              <img src={src} alt={`Image ${index + 1}`} />
              <div className="download-icon" onClick={() => downloadImage(src)}>
                <FaDownload />
              </div>
            </div>
          </div>
        ))}
      </Masonry>

      {visibleImages < imageData.length && (
        <button className="load-more" onClick={loadMoreImages}>
          Load More
        </button>
      )}
    </div>
  );
};

export default MasonryGrid;