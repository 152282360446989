import React, { useEffect, useState } from "react";
import { db } from "../../firebase-config"; // Import Firestore db configuration
import { doc, setDoc, getDoc } from "firebase/firestore"; // Firestore methods for saving documents

const StartupRequest = () => {
  const [token, setToken] = useState("");

  // Function to get the token from local storage
  const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem("fcmToken");
    return token ? token : null;
  };

  // Function to store the token in Firestore collection
  const storeTokenInFirestore = async (token) => {
    if (!token) return;
  
    try {
      // Reference to the document using the token as the document ID
      const tokenRef = doc(db, "startupRequests", token);
      
      // Check if the token already exists in the Firestore collection
      const tokenDoc = await getDoc(tokenRef);
      
      if (tokenDoc.exists()) {
        console.log("Token already exists in Firestore.");
        return; // If the token exists, exit the function without adding it again
      }
  
      // If the token doesn't exist, store it in the collection
      await setDoc(tokenRef, {
        token: token,
        timestamp: new Date().toISOString(),
      });
  
      console.log("Token stored successfully in Firestore");
    } catch (error) {
      console.error("Error storing token in Firestore: ", error);
    }
  };

  // useEffect to retrieve token and store it in Firestore when the component mounts
  useEffect(() => {
    const tokenFromLocalStorage = getTokenFromLocalStorage();

    if (tokenFromLocalStorage) {
      setToken(tokenFromLocalStorage);
      storeTokenInFirestore(tokenFromLocalStorage);
    } else {
      console.error("No token found in local storage.");
    }
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-gray-800">Startup Request</h1>
      <p className="text-lg text-gray-700">
        Token {token ? "stored successfully" : "not found in local storage"}
      </p>
    </div>
  );
};

export default StartupRequest;