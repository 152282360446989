import Theme_bg from "../../assets/theme_mainpage.jpg";
import CustomChips from "./CustomChips";

import { useRef } from "react";
import { useInView } from "framer-motion";

export default function Theme() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const fest_theme = "Voyage of Visionaries";
  const description1 =
    'The "Voyage" is a journey that transcends the boundaries of ordinary exploration, charting a course through the realms of imagination, innovation and discovery';
  const description2 =
    "Each visionary's journey is marked by perseverance, as they often face skepticism, obstacles and the limits of existing knowledge or systems. Their contribution becomes a beacon, illuminating the path forward for generations to come";

  return (
    <div className="flex flex-col items-center gap-4 text-center w-full bg-[#070310]">
      <CustomChips
        text={"Theme"}
        textColor={"#dedede"}
        borderColor={"#313131"}
        bgColor={"#100a21"}
      />
      <img
        src={Theme_bg}
        alt="Cool"
        className="-mt-12 md:-mt-24 lg:-mt-24 -mb-20 md:-mb-24 lg:-mb-40"
      />
      <div className="flex flex-col w-full md:w-4/5 lg:w-3/5 gap-2">
        <p
          className="text-3xl md:text-5xl mb-4 font-black font-masterclass"
          ref={ref}
          style={{
            transform: isInView ? "none" : "scale(1.1)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          {fest_theme}
        </p>
        <p className="text-sm md:text-lg font-light">{description1}</p>
        <p className="text-sm md:text-lg font-light">{description2}</p>
      </div>
    </div>
  );
}
