import React, { useState } from "react";
import bullrun from "../../assets/registration/events/bull.webp";
import budgeit from "../../assets/registration/events/budgeit.webp";
import brandscan from "../../assets/registration/events/brandscan.webp";
import spectra from "../../assets/registration/events/spectra.webp";
import optifyLogo from "../../assets/registration/events/optify_logo.webp";
import bizWatch from "../../assets/registration/events/bizwatch.webp";
import bidWars from "../../assets/registration/events/bidwars.webp";
import optifyPhotography from "../../assets/registration/events/optify_photo.webp";
import ceoChallenge from "../../assets/registration/events/ceo.webp";
import optifyVideography from "../../assets/registration/events/optify_video.webp";
import courtroomClash from "../../assets/registration/events/courtroom_clash.webp";

const EventsGrid = () => {
  const [modalEvent, setModalEvent] = useState(null);

  const events = [
    {
      title: "SPECTRA",
      tagline: "DIVERSE IDEAS, INFINITE POTENTIAL",
      date: "Jan 1 - Jan 12, 2025",
      image: spectra,
      description:
        "A platform to unleash your entrepreneurial ideas, present to investors, and compete for funding and recognition.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/spectra-diverse-ideas-infinite-potential-coep-technological-university-pune-maharashtra-1286925?lb=vOs0McA&utm_medium=Share&utm_source=shortUrl",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹100 per team",
      prizePool: "₹2,00,000 + PWSOS courses for entrepreneurs",
      rulebook: [
        "Registration fee: ₹100 per team.",
        "Teams will undergo a scrutiny process after registration, and the finalists will be announced.",
        "Each team must consist of 1 to 5 members.",
        "PowerPoint presentations should cover the 11 key points provided in the guidelines.",
        "Each team will have a fixed time for their presentation followed by a Q&A session with the jury.",
        "Presentations will be in a closed room to maintain confidentiality.",
        "The event has multiple rounds, with only the top teams advancing to subsequent rounds.",
        "Final judgment and scores will be decided by the organizers.",
        "Prize pool: ₹2,00,000 (including ₹50,000 cash and PWSOS courses for entrepreneurs).",
      ],
      contact: {
        name: "Yash",
        email: "events.bec@gmail.com",
        phone: "+918263887119",
      },
      deadlines: {
        registration: "Jan 1, 2025, 12:00 PM IST",
        eventStart: "Jan 11, 2025, 12:00 PM IST",
      },
    },
    {
      title: "Bullrun",
      tagline: "The Ultimate Trading Competition",
      date: "Jan 6 - Jan 10, 2025",
      image: bullrun,
      description:
        "Master the Bull, Dominate the Markets! Show your trading skills and conquer the stock market in this thrilling competition.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/bullrun-coep-technological-university-pune-maharashtra-1310781?lb=vOs0McA",
      mode: "Online",
      platform: "StockGro",
      fee: "₹99",
      prizePool: "₹10,000",
      rulebook: [
        "Competition dates: January 6th to 10th, 2025.",
        "The event will be held online via the StockGro platform.",
        "A registration fee of ₹99 is required.",
        "Participants must follow all platform guidelines during the competition.",
        "The total prize pool is ₹10,000.",
        "Ensure timely participation and adherence to the schedule.",
      ],
    },
    {
      title: "Budge-It",
      tagline: "Budget Management Competition",
      date: "Jan 12, 2025",
      image: budgeit,
      description:
        "Think you can make the most from the least money? Test your budgeting skills and compete to maximize profits in this exciting challenge.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/budge-it-pune-startup-fest25-coep-technological-university-pune-maharashtra-1315519?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹49",
      prizePool: "₹7,000 + Goodies",
      rulebook: [
        "Eligibility: Open for all participants under the age of 25.",
        "Competition will be held on January 12th, 2025, at COEP Technological University, Pune.",
        "This is an individual entry competition.",
        "Participants will design a budget for a hypothetical event, within a maximum limit provided during the competition.",
        "The budget should not exceed the maximum value, or the participant will be disqualified.",
        "Results will be based on the anticipated profit calculated from the budget designed.",
        "The participant with the highest profit wins.",
        "Participants will have 40 minutes to design their budgets.",
        "Decisions by the organizers will be final and binding.",
      ],
      deadlines: {
        registration: "Jan 9, 2025, 11:00 PM IST",
        eventStart: "Jan 12, 2025, 10:30 AM IST",
      },
    },
    {
      title: "BrandScan",
      tagline: "Logo & Advertisement Quiz Competition",
      date: "Jan 11, 2025",
      image: brandscan,
      description:
        "Test your knowledge in business logos, business personalities, and marketing with this fun quiz competition. Great for students and professionals alike!",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/brandscan-pune-startup-fest25-coep-technological-university-pune-maharashtra-1315666?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹49",
      prizePool: "₹7,000 + Goodies",
      rulebook: [
        "Eligibility: OPEN FOR ALL (Age restriction 25 Years)",
        "Round 1: Pen-paper based quiz with verbal, image-based, and audio-based questions. Time limits will vary. Negative markings possible.",
        "Round 2: Buzzer-based quiz with verbal, image-based, and audio-based questions. Time limits will vary. Negative markings possible.",
        "Top 3 candidates with the highest points will be announced as winners during the valedictory ceremony.",
        "Team Size: Individual Entry Only.",
        "Registration Deadline: Jan 9, 2025, 11:00 PM IST.",
      ],
      deadlines: {
        registration: "Jan 9, 2025, 11:00 PM IST",
        eventStart: "Jan 11, 2025, 11:30 AM IST",
      },
      contact: {
        name: "Soham",
        email: "events.bec@gmail.com",
        phone: "+918484885601",
      },
    },
    {
      title: "OPTIFY",
      tagline: "Logo Design Competition",
      date: "Jan 10 - Jan 12, 2025",
      image: optifyLogo,
      description:
        "Showcase your creativity by designing an original logo in this exciting competition. Participate individually to compete for prizes and recognition.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/optify-pune-startup-fest25-coep-technological-university-pune-maharashtra-1330836?lb=vOs0McA",
      mode: "Online",
      platform: "COEP Technological University, Pune",
      fee: "Free",
      prizePool: "₹5,000 + Certificates",
      rulebook: [
        "This competition is open to individual participants only. Group submissions are not allowed.",
        "Designs must align with the provided problem statement.",
        "All submissions must be original. AI-generated or plagiarized designs will result in disqualification.",
        "Submission format: PNG or JPEG with at least 300 DPI resolution and file size not exceeding 200MB.",
        "File naming convention: LogoDesign_ParticipantName.",
        "Submissions must be uploaded to the provided Google Drive link with correct permissions.",
        "Judging criteria include creativity, relevance, aesthetic appeal, and usability across media.",
        "The decision of the judging panel is final and cannot be contested.",
        "Submission deadline: January 12th, 11:30 AM IST. Late submissions will not be accepted.",
        "Winners grant intellectual property rights of the logo to the organizers.",
      ],
      contact: {
        name: "Soumyajit Das",
        email: "events.bec@gmail.com",
        phone: "+918411900014",
      },
      deadlines: {
        registration: "Jan 8, 2025, 11:00 PM IST",
        eventStart: "Jan 10, 2025, 10:00 AM IST",
        submissionDeadline: "Jan 12, 2025, 11:30 AM IST",
      },
    },
    {
      title: "Optify - Videography",
      tagline: "Bring the Pune Startup Fest to Life in a Reel",
      date: "Jan 11 - Jan 12, 2025",
      image: optifyVideography,
      description:
        "A reels-making competition where participants craft a creative story about Pune Startup Fest in a short video.",
      status: { text: "Registration Open", color: "green" },
      registrationLink: "https://unstop.com/p/optify-pune-startup-fest25-coep-technological-university-pune-maharashtra-1330836?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "FREE",
      prizePool: "₹4,000 (Videography category)",
      rulebook: [
        "The competition will run on January 11 and 12, 2025.",
        "Participants must be present on the day of the shooting.",
        "Reels can be shot using any device available (Mobile phone, DSLR, or point-and-shoot).",
        "Plagiarism or inappropriate content will result in disqualification.",
        "Reel length: 30 - 90 seconds.",
        "Reels must be relevant to the Pune Startup Fest.",
        "Submission deadline: January 12, 2025, 12:30 PM.",
        "Video format: MP4, MOV.",
        "Reel must be named “Optify_ParticipantName”.",
        "Google Drive links must allow 'Anyone with the link' permissions.",
      ],
      contact: {
        name: "Soumyajit Das",
        email: "events.bec@gmail.com",
        phone: "+918411900014",
      },
      deadlines: {
        registration: "Jan 10, 2025, 11:00 PM IST",
        eventStart: "Jan 11, 2025, 10:00 AM IST",
        submission: "Jan 12, 2025, 12:30 PM IST",
      },
    },
    {
      title: "Optify - Photography",
      tagline: "Capture the Spirit of Pune Startup Fest Through Your Lens",
      date: "Jan 11 - Jan 12, 2025",
      image: optifyPhotography,
      description:
        "A photography competition for creatives to showcase the essence of Pune Startup Fest through stunning visuals.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/optify-photography-pune-startup-fest-coep-technological-university-pune-maharashtra-1286930",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "FREE",
      prizePool: "₹4,000 (Photography category)",
      rulebook: [
        "The competition will run on January 11 and 12, 2025.",
        "Participants must be present on the day of the shooting.",
        "Images can be shot using any device available (Mobile phone, DSLR, or point-and-shoot).",
        "Plagiarism or inappropriate content will result in disqualification.",
        "Submit no more than 3 photos per participant.",
        "Photos must be relevant to the Pune Startup Fest.",
        "Submission deadline: January 12, 2025, 12:30 PM.",
        "Image format: PDF, JPEG.",
        "Google Drive links must allow 'Anyone with the link' permissions.",
      ],
      contact: {
        name: "Soumyajit Das",
        email: "events.bec@gmail.com",
        phone: "+918411900014",
      },
      deadlines: {
        registration: "Jan 10, 2025, 11:00 PM IST",
        eventStart: "Jan 11, 2025, 10:00 AM IST",
        submission: "Jan 12, 2025, 12:30 PM IST",
      },
    },
    {
      title: "CEO Challenge",
      tagline: "Step Into the Shoes of a CEO!",
      date: "Jan 11, 2025",
      image: ceoChallenge,
      description:
        "Test your knowledge, analytical thinking, and decision-making skills in this thrilling two-round competition that challenges your business acumen.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/ceo-challenge-coep-technological-university-pune-maharashtra-1328560?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹79 per team",
      prizePool: "₹10,000",
      rulebook: [
        "Open for all (Age Restriction: 25 years).",
        "Team size: Minimum 1 participant and Maximum 3 participants.",
        "Round 1: Business Quiz (30 minutes).",
        "Round 2: Business Case Study (45 minutes).",
        "Judges' decisions are final.",
        "Entry fees are non-refundable unless fewer than 10 teams register.",
      ],
      contact: {
        name: "Arpita & Shahir",
        email: "events.bec@gmail.com",
        phone: "+917757021965 / +917400359757",
      },
      deadlines: {
        registration: "Jan 10, 2025, 11:59 PM IST",
        eventStart: "Jan 11, 2025, 1:00 PM IST",
      },
    },
    {
      title: "BizWatch",
      tagline: "Corporate Challenges with a Pop Culture Twist",
      date: "Jan 11, 2025",
      image: bizWatch,
      description:
        "Navigate business scenarios inspired by popular web series. Test your decision-making, negotiation, and management skills through five exciting rounds.",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/bizwatch-pune-startup-fest25-coep-technological-university-pune-maharashtra-1327962?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹49",
      prizePool: "₹12,000",
      rulebook: [
        "Individual entry only.",
        "Open for all (no age restrictions).",
        "Five pop culture-themed rounds.",
        "No prior knowledge of web series required.",
        "Judges' decisions are final.",
        "Entry fees are non-refundable unless fewer than 10 participants register.",
      ],
      contact: {
        name: "Kartik & Ameya",
        email: "events.bec@gmail.com",
        phone: "+918180010981 / +918591743313",
      },
      deadlines: {
        registration: "Jan 10, 2025, 11:59 PM IST",
        eventStart: "Jan 11, 2025, 4:30 PM IST",
      },
    },
    {
      title: "BidWars",
      tagline: "The Ultimate Cricket Auction Challenge",
      date: "Jan 11 - Jan 12, 2025",
      image: bidWars,
      description:
        "Put your analytical skills to the test in this auction-based cricket team creation challenge. Form the best team within budget and maximize points!",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/bidwars-pune-startup-fest25-coep-technological-university-pune-maharashtra-1322789?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹99 per team",
      prizePool: "₹10,000 + Goodies",
      rulebook: [
        "Team size: 2 participants.",
        "Open for all (Age Restriction: 25 years).",
        "Round 1 on Jan 11, 2025 (2:00 PM to 4:00 PM).",
        "Round 2 on Jan 12, 2025 (11:00 AM to 1:00 PM).",
        "Follow all auction rules (e.g., budget limits, team composition).",
        "Judges' decisions are final.",
        "Entry fees are non-refundable unless fewer than 10 teams register.",
      ],
      contact: {
        name: "Pranjal & Manas",
        email: "events.bec@gmail.com",
        phone: "+917020273256 / +918149078081",
      },
      deadlines: {
        registration: "Jan 10, 2025, 11:59 PM IST",
        eventStart: "Jan 11, 2025, 2:00 PM IST",
      },
    },
    {
      title: "Courtroom Clash",
      tagline: "The event is in collaboration with ILS Law College, Pune.",
      date: "Jan 11 - Jan 12, 2025",
      image: courtroomClash,
      description:
        "Courtroom clash is similar to the moot court. The competition will test participants' ability to argue and analyse a legal case based on Intellectual Property Rights (IPR)",
      status: { text: "Registration Open", color: "green" },
      registrationLink:
        "https://unstop.com/p/courtroom-clash-pune-startup-fest25-coep-technological-university-pune-maharashtra-1337555?lb=vOs0McA",
      mode: "Offline",
      platform: "COEP Technological University, Pune",
      fee: "₹49 per team",
      prizePool: "₹10,000 + Goodies",
      rulebook: [
        "Team size: 1-2 participants.",
        "Open for all (Age Restriction: 25 years).",
        "Preliminary round: Teams will argue their assigned sides on an IPR proposition in two courtrooms with 10 teams each.",
        "Final round: Top 4 teams will argue the same proposition to determine the winner.",
        "A reference book will be provided to the participants to prepare for the competition.",
        "Judges' decisions are final.",
        "Entry fees are non-refundable unless fewer than 10 teams register.",
      ],
      contact: {
        name: "Swapnil & Soham",
        email: "events.bec@gmail.com",
        phone: "+917666365513 /  +919370530324",
      },
      deadlines: {
        registration: "Jan 9, 2025, 11:00 PM IST",
        eventStart: "Jan 11, 2025, 2:00 PM IST",
      },
    },
  ];

  return (
    <div className="min-h-screen p-2 md:p-6 lg:p-8 bg-transparent">
      <h1 className="text-4xl font-bold text-center mb-12 text-white tracking-wider">
        Upcoming Events
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
        {events.map((event, index) => (
          <div
            key={index}
            className="group relative overflow-hidden rounded-2xl bg-gray-600/40 hover:bg-gray-800/60 transition-colors"
          >
            <div className="relative p-4 lg:p-6 border border-gray-500/50 rounded-2xl">
              <div className="flex flex-col min-h-[4rem] mb-4">
                <div className="flex flex-col justify-start items-start gap-2">
                  <h3 className="text-2xl font-bold text-white mt-6">
                    {event.title}
                  </h3>
                  <h4 className="text-lg text-purple-300">{event.tagline}</h4>
                </div>
              </div>

              {/* Date Tag on top */}
              <div className="absolute top-4 right-4 bg-purple-600 text-white px-3 py-1 rounded-full text-sm">
                {event.date}
              </div>

              <div className="h-48 mb-4 overflow-hidden rounded-lg">
                <img
                  src={event.image}
                  alt={event.title}
                  className="w-full h-full object-contain"
                />
              </div>
              <p className="text-gray-300 mb-2">
                <strong>Mode:</strong> {event.mode}
              </p>
              <p className="text-gray-300 mb-2">
                <strong>Platform:</strong> {event.platform}
              </p>
              <p className="text-gray-300 mb-2">
                <strong>Fee:</strong> {event.fee}
              </p>
              <p className="text-gray-300 mb-2">
                <strong>Prize Pool:</strong> {event.prizePool}
              </p>
              <p className="text-gray-300 mb-6">{event.description}</p>

              <div className="flex space-x-4">
                <button
                  className="flex-1 px-2 lg:px-4 py-2 text-sm lg:text-base bg-gradient-to-br from-[#B721FF]  to-[#2d21fd] text-white rounded-lg hover:bg-purple-700 transition-colors"
                  onClick={() => setModalEvent(event)}
                >
                  View Details
                </button>
                <a
                  href={event.registrationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-1 px-2 lg:px-4 py-2 text-sm lg:text-base bg-white text-purple-600 rounded-lg hover:bg-purple-50 transition-colors text-center"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {modalEvent && (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4 text-purple-600">
              {modalEvent.title}
            </h2>
            <p className="text-lg mb-4 text-gray-800">
              {modalEvent.description}
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-800">
              {modalEvent.rulebook.map((rule, idx) => (
                <li key={idx}>{rule}</li>
              ))}
            </ul>

            {/* Scrollable content */}
            <div className="overflow-y-auto max-h-80">
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                  onClick={() => setModalEvent(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsGrid;
