import React from "react";
import Chip from "@mui/material/Chip";

export default function CustomChips({
  text,
  textColor = "#dedede", // Default text color
  borderColor = "#313131", // Default border color
  bgColor = "#100a21", // Default background color
}) {
  return (
    <Chip
      label={text}
      variant="outlined"
      clickable
      sx={{
        color: textColor,
        borderColor: borderColor,
        backgroundColor: bgColor,
        fontSize: "1rem",
        padding: "4px",
        height: "auto",
        width: "fit-content",
        borderRadius: "50px", // Fully rounded corners
      }}
    />
  );
}
